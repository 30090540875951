import React, { useState, useEffect } from 'react';
import Footer from '../components/footer';
import Select from 'react-select'
import Moment from 'react-moment';
import { createGlobalStyle } from 'styled-components';
import { checkAutographPriceUpdating, getTXCheckZoneAutograph, myLucents, userTX } from '../../api/api';
import { ethers } from "ethers";
// import { navigate, useParams } from "@reach/router";
// import { Link } from "@reach/router";
import { useNavigate, useParams,Link } from 'react-router-dom';
import Pagination from "../components/Pagination";
import { useTimer } from 'use-timer';
import { ThreeBounce } from 'better-react-spinkit'
import useSignerContract from '../../hooks/useSignerContract';
import Swal from 'sweetalert2'
import '@sweetalert2/theme-dark/dark.scss';
// import '@sweetalert2/themes/dark/dark.scss';


import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
const GlobalStyles = createGlobalStyle`
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: block !important;
}
.navbar .mainside a{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
.item-dropdown{
  .dropdown{
    a{
      &:hover{
        background: #8364e2;
      }
    }
  }
}
.btn-main{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
p.lead{
  color: #a2a2a2;
}
.navbar .navbar-item .lines{
  border-bottom: 2px solid #8364e2;
}
.jumbotron.no-bg{
  height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
#tsparticles{
  top: 0;
}
.text-uppercase.color{
  color: #8364e2;
}
.de_count h3 {
  font-size: 36px;
  margin-bottom: 0px;
}
.de_count h5{
  font-size: 14px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
}
.box-url{
  text-align: center;
  h4{
    font-size: 16px;
  }
}
.de_countdown{
  border: solid 2px #8364e2;
}
.author_list_pp, .author_list_pp i, 
.nft_coll_pp i, .feature-box.style-3 i, 
footer.footer-light #form_subscribe #btn-subscribe i, 
#scroll-to-top div{
  background: #8364e2;
}
footer.footer-light .subfooter .social-icons span i{
  background: #403f83;
}
.author_list_pp:hover img{
  box-shadow: 0px 0px 0px 2px #8364e2;
}
.nft__item_action span{
  color: #8364e2;
}
.feature-box.style-3 i.wm{
  color: rgba(131,100,226, .1);
}
@media only screen and (max-width: 1199px) {
  .navbar{
    
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
  }
}
.profileBtn{
  width:150px;
  text-align: center;
  margin-top:5px;
}
.fsize32{
  font-size: 42px;
}
.text-left{
  text-align: left;
}
.exemplar_avatar {
  display: flex;
}
`;

const customStyles = {
	option: (base, state) => ({
		...base,
		background: "#212428",
		color: "#fff",
		borderRadius: state.isFocused ? "0" : 0,
		"&:hover": {
			background: "#16181b",
		}
	}),
	menu: base => ({
		...base,
		background: "#212428 !important",
		borderRadius: 0,
		marginTop: 0,
	}),
	menuList: base => ({
		...base,
		padding: 0
	}),
	control: (base, state) => ({
		...base,
		padding: 2
	})
};
const options1 = [
	{ value: '0', label: 'All' },
	{ value: '1', label: 'On Sale' },
]
const options2 = [
	{ value: '0', label: 'Recently Minted' },
	{ value: '1', label: 'Recently Listed' },
	{ value: '2', label: 'Price High To Low' },
	{ value: '3', label: 'Price Low To High' },
	{ value: '4', label: 'Sort By Autographs' },

]
const SignatureRequest = () => {
	const params = useParams();
	let navigate = useNavigate();
	// const provider = new ethers.providers.Web3Provider(window.ethereum)
	// const signer = provider.getSigner()
	// const signerContract = new ethers.Contract(contractAddressSigner, contractAddressSignerABI, signer);

	const [signerContract] = useSignerContract()

	const [AutographPrice, setAutographPrice] = useState("0.0");
	const [DefinedAutographPrice, setDefinedAutographPrice] = useState("0.0");
	const [MinAutographPriceFormatted, setMinAutographPriceFormatted] = useState("0.0");
	const [PriceEditMode, setPriceEditMode] = useState(false);
	const [AutographPriceUpdating, setAutographPriceUpdating] = useState(false);





	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);


	const onDefinedAutographPriceChange = async (e) => {
		setDefinedAutographPrice(e.target.value)
	}
	////
	const onPageChange = async (page) => {

		console.log("onPageChange: ", page)
		setCurrentPage(page)


	}
	const xsignOffer = async (tokenID, nftID) => {

		console.log("signOffer: ", tokenID);
		const ToastPender = Swal.mixin({
			toast: true,
			position: 'center-center',
			showConfirmButton: false,
			// timer: 3000,
			timerProgressBar: false,
			// didOpen: (toast) => {
			//   toast.addEventListener('mouseenter', Swal.stopTimer)
			//   toast.addEventListener('mouseleave', Swal.resumeTimer)
			// }
		  })
	  
		  ToastPender.fire({
			icon: 'info',
			title: 'Connecting To Wallet...'
		  })


		const zpRes = await getTXCheckZoneAutograph(nftID,params.id);
		if(zpRes && zpRes.status===1){
			if(zpRes.data.statusOnSale){
				ToastPender.close();
				Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: `Lucent is listed on the marketplace for sale. You can't request an autograph.`,
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				})
				checkNFTs_noloader();
				return;
			}
			if(zpRes.data.autographPrice!==ProfileData.autographPrice){
				ToastPender.close();
				Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: `Autograph price has changed. Please check it out.`,
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				})
				getProfileInfoX();
				checkNFTs_noloader();
				return;
			}
			if(zpRes.data.onSalePendingTX){
				ToastPender.close();
				Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: `Lucent is listed on the marketplace for sale. For requesting the autograph NFT shouldn't be listed on the marketplace for sale.`,
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				})
				return;
			}
			if(zpRes.data.autograpUpdatePendingTX){
				ToastPender.close();
				Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: `Autograph price is updating. Please check it again later.`,
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				})
				 
				return;
			}
			if(zpRes.data.signerPendingTX || zpRes.data.senderPendingTX){
				ToastPender.close();
				Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: `Status of the Lucent is updating. Please check it again later.`,
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				})
				 
				return;
			}
			
    
		}


		// const options = { value: ethers.utils.parseEther("1.0") }
		var v;
		var v1 = await signerContract.SignerPrice(params.id)
		// console.log("v1: ",ethers.utils.formatEther(v1)) 
		console.log("v1: ", v1.toString())
		if (v1.isZero()) {
			console.log("siger price is zero")
			v = await signerContract.minPrice()
			console.log("v: ", v)
		} else {
			v = v1
		}
		console.log("vx: ", v)
		try {
			const options = { value: v }
		const tx = await signerContract.signOffer(parseInt(tokenID), params.id, options);
		
		console.log("tx: ", tx.hash)
		ToastPender.close()
		await userTX({
			type: "SIGN_OFFER",
			txHash: tx.hash,
			tokenID,
			signer: params.id,
			nftID
		})
		const Toast = Swal.mixin({
			toast: true,
			position: 'center-center',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			//   toast.addEventListener('mouseenter', Swal.stopTimer)
			//   toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		  })
	  
		  Toast.fire({
			icon: 'success',
			title: 'Transaction Submitted!'
		  })
		checkNFTs_noloader();

		 
		} catch (error) {
			ToastPender.close()
			if (error.code === -32603) {
				if (error.data.message.trim() === 'evm error: OutOfFund') {
				  Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: 'Insufficient funds!',
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				  })
				}
		
			  }
		}
	}
	const withdrawSignOffer = async (offerID, nftID) => {
		console.log("withdrawSignOffer: ", offerID);
		const ToastPender = Swal.mixin({
			toast: true,
			position: 'center-center',
			showConfirmButton: false,
			// timer: 3000,
			timerProgressBar: false,
			// didOpen: (toast) => {
			//   toast.addEventListener('mouseenter', Swal.stopTimer)
			//   toast.addEventListener('mouseleave', Swal.resumeTimer)
			// }
		  })
	  
		  ToastPender.fire({
			icon: 'info',
			title: 'Connecting To Wallet...'
		  })
		const zpRes = await getTXCheckZoneAutograph(nftID,params.id);
		if(zpRes && zpRes.status===1){
			if(zpRes.data.statusOnSale){
				ToastPender.close();
				Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: `Lucent is listed on the marketplace for sale. You can't request an autograph.`,
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				})
				checkNFTs_noloader();
				return;
			}
			if(zpRes.data.autographPrice!==ProfileData.autographPrice){
				ToastPender.close();
				Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: `Autograph price has changed. Please check it out.`,
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				})
				getProfileInfoX();
				checkNFTs_noloader();
				return;
			}
			if(zpRes.data.onSalePendingTX){
				ToastPender.close();
				Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: `Lucent is listed on the marketplace for sale. For requesting the autograph NFT shouldn't be listed on the marketplace for sale.`,
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				})
				return;
			}
			if(zpRes.data.autograpUpdatePendingTX){
				ToastPender.close();
				Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: `Autograph price is updating. Please check it again later.`,
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				})
				 
				return;
			}
			if(zpRes.data.signerPendingTX || zpRes.data.senderPendingTX){
				ToastPender.close();
				Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: `Status of the Lucent is updating. Please check it again later.`,
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				})
				 
				return;
			}
			
    
		}
		try {
			const tx = await signerContract.withdrawSignOffer(offerID);
		console.log("tx: ", tx.hash)
		ToastPender.close();
		await userTX({
			type: "SIGN_OFFER_WITHDRAW",
			txHash: tx.hash,
			offerID,
			signer: params.id,
			nftID
		})
		const Toast = Swal.mixin({
			toast: true,
			position: 'center-center',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			//   toast.addEventListener('mouseenter', Swal.stopTimer)
			//   toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		  })
	  
		  Toast.fire({
			icon: 'success',
			title: 'Transaction Submitted!'
		  })
		checkNFTs_noloader();

		} catch (error) {
			ToastPender.close();
			if (error.code === -32603) {
				if (error.data.message.trim() === 'evm error: OutOfFund') {
				  Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: 'Insufficient funds!',
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				  })
				}
		
			  }
		}
	}



	const [userNFTs, setUserNFTs] = useState();

	const [openMenu, setOpenMenu] = React.useState(true);
	const [openMenu1, setOpenMenu1] = React.useState(false);
	const [openMenu2, setOpenMenu2] = React.useState(false);
	const [openMenu3, setOpenMenu3] = React.useState(false);
	const [openMenu4, setOpenMenu4] = React.useState(false);
	const handleBtnClick = () => {
		setOpenMenu(!openMenu);
		setOpenMenu1(false);
		setOpenMenu2(false);
		setOpenMenu3(false);
		setOpenMenu4(false);
		document.getElementById("follow").classList.remove("active");
		document.getElementById("sale").classList.remove("active");
		document.getElementById("offer").classList.remove("active");
		document.getElementById("like").classList.remove("active");
	};
	const handleBtnClick1 = () => {
		setOpenMenu1(!openMenu1);
		setOpenMenu2(false);
		setOpenMenu(false);
		setOpenMenu3(false);
		setOpenMenu4(false);
		document.getElementById("follow").classList.add("active");
		document.getElementById("sale").classList.remove("active");
		document.getElementById("offer").classList.remove("active");
		document.getElementById("like").classList.remove("active");
	};
	const handleBtnClick2 = () => {
		setOpenMenu2(!openMenu2);
		setOpenMenu(false);
		setOpenMenu1(false);
		setOpenMenu3(false);
		setOpenMenu4(false);
		document.getElementById("follow").classList.remove("active");
		document.getElementById("sale").classList.add("active");
		document.getElementById("offer").classList.remove("active");
		document.getElementById("like").classList.remove("active");
	};
	const handleBtnClick3 = () => {
		setOpenMenu3(!openMenu3);
		setOpenMenu(false);
		setOpenMenu1(false);
		setOpenMenu2(false);
		setOpenMenu4(false);
		document.getElementById("follow").classList.remove("active");
		document.getElementById("sale").classList.remove("active");
		document.getElementById("offer").classList.remove("active");
		document.getElementById("like").classList.add("active");
	};
	const handleBtnClick4 = () => {
		setOpenMenu4(!openMenu4);
		setOpenMenu(false);
		setOpenMenu1(false);
		setOpenMenu3(false);
		setOpenMenu2(false);
		document.getElementById("follow").classList.remove("active");
		document.getElementById("sale").classList.remove("active");
		document.getElementById("offer").classList.add("active");
		document.getElementById("like").classList.remove("active");
	};

	const [ProfileData, setProfileData] = useState();
	const getProfileInfoX = async (wp) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				authorization: localStorage.getItem('token')
			},

		};
		const req = await fetch(`https://lucentapi.lucentnfts.com/user/profile/` + params.id, requestOptions);
		const newData = await req.json();

		console.log("me: ", newData.data.wp)
		setProfileData(newData.data)
	};
	useEffect(() => {
		getProfileInfoX();
		// checkAutographPriceUpdatingX();

	}, [])

	useEffect(() => {
		if (ProfileData) {
			setAutographPrice(ethers.utils.formatEther(ProfileData.autographPrice))
			setMinAutographPriceFormatted(ethers.utils.formatEther(ProfileData.minAutographPrice))
		}

	}, [ProfileData])








	const checkNFTs_noloader = async () => {
		console.log("checkNFTs_noloader")
		let nfts = await myLucents(params.id, currentPage);
		setUserNFTs(nfts.data)
		setTotalRecords(nfts.totalRecords)
	}
	const checkNFTs = async () => {
		console.log("checkNFTs")
		setLoading(true)
		let nfts = await myLucents(params.id, currentPage);
		setUserNFTs(nfts.data)
		setTotalRecords(nfts.totalRecords)
		setLoading(false)
	}
	useEffect(() => {
		checkNFTs();
		// let iID = setInterval(() => {
		//   checkNFTs();
		// }, 3000);
		// return () => {
		//   clearInterval(iID)
		// }
	}, [currentPage])

	const { time, start, pause, reset, status } = useTimer();
	useEffect(() => {
		start()
		return () => {
			reset()
		}
	}, [])
	useEffect(() => {
		if (time % 10 === 9) {

			checkNFTs_noloader()
			if (AutographPriceUpdating) {
				checkAutographPriceUpdatingX();
			}
		}

	}, [time])

	const checkAutographPriceUpdatingX = async () => {
		const r = await checkAutographPriceUpdating();
		if (!r.data.isUpdating) {
			setAutographPriceUpdating(false);
			getProfileInfoX();
		} else {
			setAutographPriceUpdating(true);
		}
	}

	const defineAutographPrice = async () => {
		console.log("defineAutographPrice")
		setPriceEditMode(true)

	}
	const defineAutographPriceCancel = async () => {
		console.log("defineAutographPriceCancel")
		setPriceEditMode(false);

	}
	const defineAutographPriceConfirm = async () => {
		console.log("defineAutographPriceConfirm")
		const x1 = ethers.BigNumber.from(ProfileData.minAutographPrice)
		const x2 = ethers.utils.parseEther(DefinedAutographPrice);
		if (x2.lt(x1)) {
			Swal.fire({
				// title: 'Please Connect A Wallet!',
				text: `Minimum price to set for the autograph price ${ethers.utils.formatEther(ProfileData.minAutographPrice)} GLMR`,
				// icon: 'error',
				icon: 'info',
				confirmButtonText: 'OK'
			})
			return;
		}
		const ToastPender = Swal.mixin({
			toast: true,
			position: 'center-center',
			showConfirmButton: false,
			// timer: 3000,
			timerProgressBar: false,
			// didOpen: (toast) => {
			//   toast.addEventListener('mouseenter', Swal.stopTimer)
			//   toast.addEventListener('mouseleave', Swal.resumeTimer)
			// }
		  })
	  
		  ToastPender.fire({
			icon: 'info',
			title: 'Connecting To Wallet...'
		  })
		try {
			var tx = await signerContract.setSignerPrice(ethers.utils.parseEther(DefinedAutographPrice))
		setAutographPriceUpdating(true)
		setPriceEditMode(false)
		ToastPender.close();
		await userTX({
			type: "AUTOGRAPH_PRICE_CHANGE",
			txHash: tx.hash,
		})
		const Toast = Swal.mixin({
			toast: true,
			position: 'center-center',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			//   toast.addEventListener('mouseenter', Swal.stopTimer)
			//   toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		  })
	  
		  Toast.fire({
			icon: 'success',
			title: 'Transaction Submitted!'
		  })
		} catch (error) {
			ToastPender.close();
			if (error.code === -32603) {
				if (error.data.message.trim() === 'evm error: OutOfFund') {
				  Swal.fire({
					// title: 'Please Connect A Wallet!',
					text: 'Insufficient funds!',
					// icon: 'error',
					icon: 'info',
					confirmButtonText: 'OK'
				  })
				}
		
			  }
		}
	}
	const gotoProfile = (wp) => {
		navigate("/profile/" + wp)
	}
	// const requestSignature = async(tokenID) => {
	//   console.log("requestSignature: ",tokenID)

	// }
	//----------------------------------------------------------------
	//1 statusRequest,statusRequesting,statusRequested,statusCanceling,statusAccepted
	//2 statusRequested,statusAccepting,statusAccepted
	return (
		<div>
			<GlobalStyles />

			<section className=' breadcumb no-bg no-bottom'>
				<div className='mainbreadcumb'>
					<div className="container">

		<table>
			<tr align="center">
				<td>
				<div className="zh1 fontsize14"> 
						<Link className="axnoline2" to={"/profile/" + params.id}><span className="color">{ProfileData && ProfileData.fullName}</span></Link> &gt; Autograph &gt; 
						</div>
				</td>
				<td>
				{ProfileData && ProfileData.autograph && <div className="marginleft10 zimg"><Zoom overlayBgColorEnd="rgba(0, 0, 0, 0.55)"><img className=" " src={ProfileData.autograph} height="32" align="middle" /></Zoom>
						
						</div>}
				</td>
				<td className='paddingbottom5'>
				{ProfileData && ProfileData.autographMetaIPFS && <>&nbsp;&nbsp;<a className='bolder color fontsize14' href={('https://lucentnfts.mypinata.cloud/ipfs/') + (ProfileData.autographMetaIPFS)} target="_blank">IPFS <span className="fa fa-link "></span></a></>}
				</td>
				<td>

				</td>
			</tr>
		</table>
						<div>
						
						</div>
						<span className='fontsize12'>AUTOGRAPH PRICE <span className='bolder'>{AutographPrice} GLMR</span></span>&nbsp;

					
						{ProfileData && ProfileData.lastAutographDate && <span className='fontsize12'>	<span className="fontsize12">Last Autograph Date: </span>  <Moment format="MMMM DD, YYYY">
							{ProfileData.lastAutographDate}
						</Moment></span>}
						<div className="marginTop10">
							{!AutographPriceUpdating && <div>
								{ProfileData && ProfileData.isMy && !PriceEditMode && <span onClick={defineAutographPrice} className="btn-main2 "> <span className="">Set Price</span></span>}
								{PriceEditMode && <div className="row">
									<div className="fontsize12">Price in GLMR, minimum price to set for the autograph {MinAutographPriceFormatted} GLMR</div>
									<div className="col-md-4">
										<input type="number" name="price" id="price" className="form-control" placeholder="" onChange={(e) => onDefinedAutographPriceChange(e)} />
									</div>
									<div className="col-md-8">
										<span onClick={defineAutographPriceCancel} className="btn-main2   inline lead "> <span className="">Cancel</span></span>
										<span onClick={defineAutographPriceConfirm} className="btn-main   inline lead "> <span className="">Confirm</span></span>
									</div>
								</div>}
							</div>}
							{AutographPriceUpdating && <div className=""><ThreeBounce className="marginright5" size={10} color='#6f42c1' /><span className="fontsize12">Autograph price is updating...</span> </div>}
						</div>
					</div>

				</div>
			</section>

			<div className="spacer-10"></div>
			<section className='container no-top no-bottom'>
				{/* <div className='row '>

					<div className='col-lg-6 items_filter'>
						<div className='dropdownSelect two'><Select className='select1 text-left' styles={customStyles} defaultValue={options1[0]} options={options1} /></div>
						<div className='dropdownSelect three'><Select className='select1 text-left' styles={customStyles} defaultValue={options2[0]} options={options2} /></div>
					</div>
				</div> */}
				<div className="spacer-single"></div>
				<div className='row'>

					<div className="col-md-12">
						<div className='spacer-single'></div>
						{loading && <center><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt"></span></center>}
						{!loading && <ul className="activity-list">
							{userNFTs && userNFTs.map((nft, index) => {
								return (

									<div className="mpos0" key={index}>

										<div className="author_list_pp mpos">
											<span onClick={() => gotoProfile(nft.signOffer.signerWP)}>
												<img className="lazy" src={nft.signOffer?.signerAvatar} alt="" />
												{ProfileData && ProfileData.isOfficial && <i className="fa fa-check"></i>}
											</span>
										</div>
										<li className="act_sale " key={nft.id}>
											<img className="" src={nft.img} alt="" />
											<div className="act_list_text  margin-bottom10">
												<div className="padding-left20">
													<h4 >{nft.title}</h4>

													<div className="margin-bottom10 fontsize12 bolder">LUCENT NFT <span className="color"><Link className=' color axnoline' to={"/lucent/" + nft.lucentNFT}>#{nft.lucentNFT}</Link></span></div>
													{!nft.onSale && nft.signOffer.statusRequest && <span><span onClick={() => xsignOffer(nft.tokenID, nft.id)} className="btn-main2 fontsize12"> <span className="">REQUEST</span></span><span className="bolder fontsize12 padding-left10">AUTOGRAPH {AutographPrice} GLMR</span></span>}
													{!nft.onSale && nft.signOffer?.statusRequested && <span><span onClick={() => withdrawSignOffer(nft.signOffer?.id, nft.id)} className="btn-main2 fontsize12"> <span className="">Withdraw</span></span><span className="bolder fontsize12 padding-left10">AUTOGRAPH {AutographPrice} GLMR</span></span>}

													{nft.signOffer.statusRequesting && <div className=""><ThreeBounce className="marginright5 " size={10} color='#6f42c1' /><span className='fontsize12'>Requesting, please wait...</span></div>}
													{nft.signOffer.statusCanceling && <div className=""><ThreeBounce className="marginright5 fontsize12" size={10} color='#6f42c1' /><span className='fontsize12'>Canceling, please wait...</span></div>}
													{nft.signOffer.statusAccepted && <div className="statusLabel">AUTOGRAPH</div>}
													{nft.onSale && <div className="statusLabel-red">LUCENT IS ON SALE</div>}
												</div>
											</div>

										</li>
									</div>

								)
							})}

						</ul>}






					</div>



				</div>
				{
					userNFTs && userNFTs.length === 0 && <div className="text-center">
						No results found.
					</div>
				}
				<div className="spacer-single"></div>

				<Pagination onPageChange={onPageChange} _currentPage={currentPage} recordsPerPage={5} pageNumberLimit={5} totalRecords={totalRecords} />

			</section>
			<div className="spacer-double"></div>


			<Footer />
		</div>

	);
}

export default SignatureRequest;