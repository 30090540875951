import React, { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Clock from "./Clock";
// import { navigate } from "@reach/router";
import { useNavigate, useParams, Link } from 'react-router-dom';
const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;



class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

const TopC = ({ topCollectors }) => {

  let navigate = useNavigate();

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: 300,
    responsive: [
      // {
      //   breakpoint: 1900,
      //   settings: {
      //     slidesToShow: 4,
      //     slidesToScroll: 1,
      //     infinite: true
      //   }
      // },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      // {
      //   breakpoint: 768,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     initialSlide: 2
      //   }
      // },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  const gotoRankingCollectors= ()=>{
    navigate(`/ranking/collectors`);
  }
  const gotoProfile = (wp) => {
    navigate(`/profile/${wp}`);
  }
  return (
    <div className='nft'>
      <Slider {...settings}>

        {topCollectors && topCollectors.map((item, index) => {
          return <CustomSlide className='itm' key={index + 1} index={index + 1}>
            <div className="d-item">
              <div className="nft__item3 marginer5">

                <div className="author_list_ppx " key={(index)}>
                  <span onClick={() => gotoProfile(item.wp)}>
                    <img className="lazy" src={item.avatar} alt="" />
                    {item.isOfficial && <i className="fa fa-check"></i>}
                  </span>
                </div>
                <div className="author_list_info paddingleft50">
                  <span onClick={() => gotoProfile(item.wp)} className="col-white fontsize12 bolder xpointer">{item.fullName}</span>
                  <div className=" fontsize12">Lucents Minted:  <span className="bolder"> {item.lucentsMinted}</span></div>
                </div>
                <div className="spacer-10"></div>
              </div>
            </div>
          </CustomSlide>
        })}

      </Slider>
      <div className="spacer-single"></div>
      <div className='col-lg-12 xparent'>
        <span onClick={gotoRankingCollectors} className="btn-main2 lead m-auto">See All</span>
      </div>
    </div>
  );

}
export default TopC;
