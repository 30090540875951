import React, { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Clock from "./Clock";
// import { navigate } from "@reach/router";
import { useNavigate, useParams,Link } from 'react-router-dom';
const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;



class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

const XTopNFTs = ({topNFTs})=> {

  let navigate = useNavigate();
 
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: 300,
      responsive: [
        // {
        //   breakpoint: 1900,
        //   settings: {
        //     slidesToShow: 4,
        //     slidesToScroll: 1,
        //     infinite: true
        //   }
        // },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        // {
        //   breakpoint: 768,
        //   settings: {
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     initialSlide: 2
        //   }
        // },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    };
   
    const gotoRankingNFTs= ()=>{
      navigate(`/ranking/nfts`);
    }
    const gotoExemplar= (chain,eContract,eTokenID)=>{
      navigate(`/reference/${chain}/${eContract}/${eTokenID}`);
    }
    return (
        <div className='nft'>
          <Slider {...settings}>
          
           { topNFTs && topNFTs.map((item, index) => { 
           return <CustomSlide className='itm' key={index+1} index={index+1}>
            <div className="d-item">
              <div className="nft__item3 marginer5">
                  {/* <div className="de_countdown">
                  <Clock deadline={this.state.deadline} />
                  </div> */}
                  {/* <div className="author_list_pp">
                      <span onClick={()=> window.open("/home1", "_self")}>                                    
                          <img className="lazy" src="./img/author/author-1.jpg" alt=""/>
                          <i className="fa fa-check"></i>
                      </span>
                  </div> */}
                  <div className="nft__item_wrap cursorPointer">
                    <Outer>
                      <span onClick={()=> gotoExemplar(item.chainF,item.eContract,item.eTokenID)}>
                          <img src={item.lucentimg} className="lazy nft__item_preview" alt=""/>
                      </span>
                    </Outer>
                  </div>
                  <div className="nft__item_info ">
                      <span onClick={()=> gotoExemplar(item.chainF,item.eContract,item.eTokenID)}>
                          <h4 className="cursorPointer">{item.title}</h4>
                      </span>
                      {/* <div className="nft__item_price">
                          0.08 ETH<span>1/20</span>
                      </div> */}
                      <div className="nft__item_action ">
                          <span onClick={()=> gotoExemplar(item.chainF,item.eContract,item.eTokenID)} className="color-grey fontsize11">LUCENTS: <span className="bolder fontsize11 col-white">{item.lucentsCount}</span></span>
                      </div>
                      <div className="spacer-single"></div>
                      {/* <div className="nft__item_like">
                          <i className="fa fa-heart"></i><span>50</span>
                      </div>                             */}
                  </div> 
              </div>
            </div>
            </CustomSlide>
            })}

          </Slider>
                <div className="spacer-single"></div>
          <div className='col-lg-12 xparent'>
                <span onClick={gotoRankingNFTs} className="btn-main2 lead m-auto">See All</span>
            </div>
        </div>
    );
  
}
export default XTopNFTs;
