import React, { useContext, useEffect, useState } from 'react';

import Particle from '../components/Particle';
import SliderMainParticle from '../components/SliderMainParticle';
import FeatureBox from '../components/FeatureBox';
import CarouselCollection from '../components/CarouselCollection';
import CarouselNew from '../components/CarouselNew';
import AuthorList from '../components/authorList';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import SliderCarousel from '../components/SliderCarousel';
import XNotableDrops from '../components/NotableDrops';
import { AuthContext } from '../../context/AuthContext';
import { getHomeSlides } from '../../api/api';
import XTopProfiles from '../components/TopProfiles';
import XTopNFTs from '../components/TopNFTs';
import {
  ChasingDots
} from 'better-react-spinkit'
import TopC from '../components/TopC';
const GlobalStyles = createGlobalStyle`
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .navbar .mainside a{
    background: #8364e2;
    &:hover{
      box-shadow: 2px 2px 20px 0px #8364e2;
    }
  }
  .item-dropdown{
    .dropdown{
      a{
        &:hover{
          background: #8364e2;
        }
      }
    }
  }
  .btn-main{
    background: #8364e2;
    &:hover{
      box-shadow: 2px 2px 20px 0px #8364e2;
    }
  }
  p.lead{
    color: #a2a2a2;
  }
  .navbar .navbar-item .lines{
    border-bottom: 2px solid #8364e2;
  }
  .jumbotron.no-bg{
    height: 100vh;
    overflow: hidden;
    background-repeat: repeat;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  #tsparticles{
    top: 0;
  }
  .text-uppercase.color{
    color: #8364e2;
  }
  .de_count h3 {
    font-size: 36px;
    margin-bottom: 0px;
  }
  .de_count h5{
    font-size: 14px;
    font-weight: 500;
  }
  h2 {
    font-size: 30px;
  }
  .box-url{
    text-align: center;
    h4{
      font-size: 16px;
    }
  }
  .de_countdown{
    border: solid 2px #8364e2;
  }
  .author_list_pp, .author_list_pp i, 
  .nft_coll_pp i, .feature-box.style-3 i, 
  footer.footer-light #form_subscribe #btn-subscribe i, 
  #scroll-to-top div{
    background: #8364e2;
  }
  footer.footer-light .subfooter .social-icons span i{
    background: #403f83;
  }
  .author_list_pp:hover img{
    box-shadow: 0px 0px 0px 2px #8364e2;
  }
  .nft__item_action span{
    color: #8364e2;
  }
  .feature-box.style-3 i.wm{
    color: rgba(131,100,226, .1);
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;


const Homeone = () => {

  const [HomeSlides, setHomeSlides] = useState();
  const [NotableDrops, setNotableDrops] = useState();
  const [TopProfiles, setTopProfiles] = useState();
  const [TopNFTs, setTopNFTs] = useState();
  const [TopCollectors, setTopCollectors] = useState();


  const loadHomeSlides = async () => {
    let res = await getHomeSlides();
    setHomeSlides(res.data.homeSlides);
    setNotableDrops(res.data.notableDrops)
    setTopProfiles(res.data.rankingProfiles)
    setTopNFTs(res.data.rankingNFTs)
    setTopCollectors(res.data.rankingCollectors)
    console.log("res.data.notableDrops: ", res.data.notableDrops)

  }

  useEffect(() => {
    loadHomeSlides()
  }, [])

  return (
    <div>
      <GlobalStyles />
      <section className="jumbotron no-bg" style={{ backgroundImage: `url(${'./img/background/8.jpg'})` }}>
        {<div className='z0'> <Particle /></div>}
        {HomeSlides && <div ><SliderMainParticle homeSlides={HomeSlides} /></div>}
        {/* 6f42c1 */}
        {!HomeSlides && <center className="paddingTopH opa"><ChasingDots size={45} color='#fff' /></center>}
      </section>

      {/* <section className='container no-bottom'>
        <div className="row">
            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/1.png" alt="" className="mb20"/>
                    <h4>Metamask</h4>
                </span>
            </div>

            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/2.png" alt="" className="mb20"/>
                    <h4>Bitski</h4>
                </span>
            </div>       

            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/3.png" alt="" className="mb20"/>
                    <h4>Fortmatic</h4>
                </span>
            </div>    

            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/4.png" alt="" className="mb20"/>
                    <h4>WalletConnect</h4>
                </span>
            </div>

            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/5.png" alt="" className="mb20"/>
                    <h4>Coinbase Wallet</h4>
                </span>
            </div>

            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/6.png" alt="" className="mb20"/>
                    <h4>Arkane</h4>
                </span>
            </div>                                       
        </div>
      </section> */}
      <section className='container no-top  no-bottom'>
        <div className='row'>
          <div className="spacer-single"></div>
          <div className='col-lg-12 mb-2'>
            <h2 className='text-center'>Notable Drops </h2>
          </div>
        </div>
      </section>
      <section className="container no-bg no-top no-bottom">
        <div className='container-fluid'>
          <div className='row'>
            <div className="spacer-single"></div>
            {NotableDrops && <XNotableDrops notableDrops={NotableDrops} />}
          </div>
        </div>
      </section>

      <section className='container no-top no-bottom'>
        <div className='row'>
          <div className="spacer-single"></div>
          <div className='col-lg-12 mb-2'>
            <h2 className='text-center'>Top Profiles</h2>
          </div>
        </div>
        <div className="spacer-10"></div>
        {/* <div className='col-lg-12'> */}
        <XTopProfiles topProfiles={TopProfiles} />
        {/* </div> */}

      </section>
      <div className="spacer-double"></div>
      <section className='container no-top no-bottom'>
        <div className='row'>
          <div className='col-lg-12 mb-2'>
            <h2 className='text-center'>Top NFTs</h2>
          </div>
        </div>
        <div className="spacer-10"></div>
        {TopNFTs && <XTopNFTs topNFTs={TopNFTs} />}

      </section>


      <div className="spacer-double"></div>
      <section className='container no-top no-bottom'>
        <div className='row'>
          {/* <div className="spacer-single"></div> */}
          <div className='col-lg-12'>
            <h2 className='text-center'>Top Collectors</h2>
          </div>
          <div className="spacer-single"></div>
          <div className='col-lg-12'>
            {/* {TopCollectors && <AuthorList topCollectors={TopCollectors}/>} */}
            {TopCollectors && <TopC topCollectors={TopCollectors} />}
          </div>
        </div>
      </section>


      <div className="spacer-double"></div>
      <section className='container no-top text-center'>
      <div className='col-lg-12'>
            <h2 className='text-center'>How to use?</h2>
          </div>
          <div className="spacer-single"></div>
      <div className="video-responsive">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/_EjePYlDkuo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      </section>
      <section className='container no-top'>
        <div className='row'>
          {/* <div className="spacer-double"></div> */}
          {/* <div className='col-lg-12 mb-3'>
              <h2>Create and Sell Now</h2>
            </div> */}
          <FeatureBox />
          <div className="spacer-single"></div>
        </div>
      </section>

      <Footer />

    </div>
  )
};
export default Homeone;