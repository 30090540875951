import { useContext, useEffect, useState } from "react";
import Swal from 'sweetalert2'
import '@sweetalert2/theme-dark/dark.scss';
import detectEthereumProvider from '@metamask/detect-provider'
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { checkWP } from "../api/api";

const useMoonbeam = () => {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const [xprovider, setProvider] = useState(null);

  const [signRequest, setSignRequest] = useState(false);
  const [checkMetamask, setCheckMetamask] = useState(false);

  const configureMoonbeam = async () => {
    if (checkMetamask) {
      // alert("Please check Metamask Wallet!")
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Please check Metamask Wallet!',
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
      return
    }
    const provider = await detectEthereumProvider({ mustBeMetaMask: true })
    setProvider(provider)
    if (provider) {
      console.log('Ethereum successfully detected!')
      try {
        const r = await provider.request({ method: "eth_requestAccounts" })
        console.log("eth_requestAccounts: ",r)
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            // {
            //   chainId: '0x89',
            //   chainName: 'Polygon Mainnet',
            //   rpcUrls: ['https://polygon-rpc.com/'] ,
            //   nativeCurrency: {
            //       // name: 'Matic',
            //       symbol: 'MATIC',
            //       decimals: 18,
            //   },
            //   blockExplorerUrls: ['https://polygonscan.com'],
            // },
            {
                  chainId: '0x504',
                  chainName: 'Moonbeam',
                  rpcUrls: ['https://rpc.api.moonbeam.network'],
                  nativeCurrency: {
                    symbol: 'GLMR',
                    decimals: 18,
                  },
                  blockExplorerUrls: ['https://moonbeam.moonscan.io'],
                }
            // {
            //   chainId: '0x507',
            //   chainName: 'Moonbase Alpha',
            //   rpcUrls: ['https://rpc.api.moonbase.moonbeam.network'],
            //   nativeCurrency: {
            //     // name: 'Matic',
            //     symbol: 'DEV',
            //     decimals: 18,
            //   },
            //   blockExplorerUrls: ['https://moonbase.moonscan.io/'],
            // },
          ],
        });
        //--

        if (userInfo && userInfo.isAuth && userInfo.isAuth) {
          console.log("userInfo.wp: ", userInfo.wp)
          const Toast = Swal.mixin({
            toast: true,
            position: 'center-center',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: false,
            didOpen: (toast) => {
              //   toast.addEventListener('mouseenter', Swal.stopTimer)
              //   toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

          Toast.fire({
            icon: 'success',
            title: 'Wallet Connected!'
          })
          setTimeout(() => {
            navigate(`/profile/${userInfo.wp}`);
          }, 1000);
          return;
        } else if (provider.selectedAddress) {
          const resp = await checkWP(provider.selectedAddress)
          if (resp.data.userExists) {
            console.log("User Exists")

            await auth(provider);


          } else {
            console.log("User Not Exists")
            const sr = await Swal.fire({
              // title: 'Please Connect A Wallet!',
              // text: 'Welcome To LucentNFTs',
              html: `<b>Welcome To LucentNFTs</b><br/>
              <br/>
              <img src="./img/lucent_logo.png" style="border-radius: 50%;height:100px;"/><br/><br/>
              <span style="font-size:10pt;"><b>By connecting your wallet and using Lucent NFTs, you agree to<br/>
our <a style="color: #685dc3" href="https://lucentnfts.com/terms_and_conditions.html" target="_blank">Terms of Service</a> and <a style="color: #685dc3" href="https://lucentnfts.com/privacy_policy.html" target="_blank">Privacy Policy</a>.</b></span><br/>
              `,
              // icon: 'error',
              // icon: 'info',
              // imageUrl: 'https://unsplash.it/400/200',
              // imageWidth: 400,
              // imageHeight: 200,
              cancelButtonText: 'CANCEL',
              confirmButtonText: 'ACCEPT',
              showCancelButton: true,
            })
            console.log("sr: ", sr.isConfirmed)
            if (sr.isConfirmed) {
              await auth(provider);
            }
            return
          }
        } else {

        }









      } catch (error) {
        if (error.code === -32002) {
          // alert("Please check Metamask Wallet!")
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Please check Metamask Wallet!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }
      }
      // await ethereum.request({
      //   method: 'wallet_switchEthereumChain',
      //   params: [{ chainId: '0x507' }],//Moonbase Alpha
      // });

    } else {
      console.log('Please install MetaMask!')
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Please install MetaMask!',
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    }

  }
  const auth = async (provider) => {
    console.log("z: ", provider.selectedAddress)
    const req = await fetch(`https://lucentapi.lucentnfts.com/auth/challenge/request?public_key=${provider.selectedAddress}`);
    const newData = await req.json();
    return await personalSign(provider, newData)
  };
  const personalSign = async (provider, xmsg) => {

    console.log("personalSign: ", xmsg)
    const from = provider.selectedAddress;
    console.log("from: ", from)
    const exampleMessage = xmsg.data;
    console.log("exampleMessage: ", exampleMessage)
    const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
    console.log("msg: ", msg)
    const ToastPender = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      // timer: 3000,
      timerProgressBar: false,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
      })
    try {
      setCheckMetamask(true)
      console.log("signRequest: ", signRequest)
      if (signRequest) return false

      setSignRequest(true)
    
      
        ToastPender.fire({
        icon: 'info',
        title: 'Connecting To Wallet...'
        })
      const sign = await provider.request({
        method: 'personal_sign',
        params: [msg, from, "ok"],
      });
      ToastPender.close()
      setSignRequest(false)

      console.log("sign: ", sign)
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          signedData: sign,
          sessionID: xmsg.sessionID
        })
      };
      const response = await fetch('https://lucentapi.lucentnfts.com/auth/challenge/signedRegister', requestOptions)
      const jsonData = await response.json();

      console.log("jsonData: ", jsonData)
      console.log("response s: ", jsonData.status)
      localStorage.setItem("token", jsonData.jwt)
      localStorage.setItem("wp", jsonData.wp)
      setUserInfo({
        token: jsonData.jwt,
        wp: jsonData.wp,
        isAuth: true
      });

    } catch (error) {
      console.log("s error: ", error)
      setCheckMetamask(false)
      setSignRequest(false)
      ToastPender.close()
      return false
    }
    setCheckMetamask(false)
    const Toast = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: false,
      didOpen: (toast) => {
        //   toast.addEventListener('mouseenter', Swal.stopTimer)
        //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: 'Wallet Connected!'
    })
    setTimeout(() => {
      navigate(`/profile/${provider.selectedAddress}`);
    }, 1000);
    return true

  };

  useEffect(() => {
    if (xprovider) {
      console.log("x0---------------")
      xprovider.on('accountsChanged', (accounts) => {
        if (accounts.length === 0) {
          // MetaMask is locked or the user doesn't have any
          // connected accounts
          console.log('Please connect to MetaMask.')
          localStorage.removeItem('token')
          localStorage.removeItem('wp')
          setUserInfo({});
          navigate("/wallet")
          return;
        }
        console.log("provider.selectedAddress: ", xprovider.selectedAddress)
        //for any case
        localStorage.removeItem('token')
        localStorage.removeItem('wp')
        setUserInfo({});
        navigate("/wallet")

      })
      xprovider.on("chainChanged", () => {
        // MetaMask recommends reloading the page unless you 
        // have good reason not to
        // window.location.reload()
        console.log("chainChanged")
        if (!isNetwork()) {
          //for any case
          localStorage.removeItem('token')
          localStorage.removeItem('wp')
          setUserInfo({});
          navigate("/wallet")
        }

      })
    }

  }, [xprovider]);
  const isNetwork = () => {
    let { ethereum } = window
    return Boolean(ethereum && (ethereum.chainId === '0x504'))
  }
  // useEffect(() => {
  //   if (userInfo && userInfo.isAuth) {
  //     console.log("a userInfo: ", userInfo.isAuth)
  //     console.log("x userInfo: ", userInfo.wp)
  //   }
  // }, [userInfo]);
  const initialise = async () => {
    const provider = await detectEthereumProvider({ mustBeMetaMask: true })
    setProvider(provider)
  }
  useEffect(() => {

    initialise();

  }, [])
  return { xprovider, configureMoonbeam };
};

export default useMoonbeam;
