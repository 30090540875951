import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { Link } from "@reach/router";
import { useNavigate, useParams,Link } from 'react-router-dom';

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );


  }
}

const XSlider = ({ homeSlides,imageOnLoadHandler }) => {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: 300,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };
  return (
    <div className='nft-big'>
      {!homeSlides &&<div className="square"></div>}
      <Slider {...settings}>
        <CustomSlide className='itm' index={1}>
        {homeSlides && homeSlides[0] &&<Link className="axnoline" to={`/reference/${homeSlides[0].chainF}/${homeSlides[0].eContract}/${ homeSlides[0].eTokenID}`}>
          <div className="nft_pic">
            <span>
              <span className="nft_pic_info">
                <span className="nft_pic_title">{homeSlides[0].title}</span>
                <span className="nft_pic_by">{ homeSlides[0].author}</span>
              </span>
            </span>
            
            <div className="nft_pic_wrap">
              
                <img src={homeSlides[0].slide} onLoad={imageOnLoadHandler} className="lazy img-fluid" alt="" />
              
            </div>
          </div>
          </Link>}
        </CustomSlide>



      </Slider>
    </div>
  )
}
export default XSlider;
