import { useState, useEffect } from "react";
import { marketplaceABI, marketplaceAddress } from "../contracts/marketplaceABI";
import { ethers } from "ethers";
const useMarketplaceContract = () => {
  let { ethereum } = window;
  const [marketplaceContract, setMarketplaceContract] = useState(null);
 
  useEffect(() => {
    if(!ethereum)return;
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    setMarketplaceContract(new ethers.Contract(marketplaceAddress, marketplaceABI, signer));
    console.log(">>x")
}, [ethereum]);

  return [marketplaceContract];
};

export default useMarketplaceContract;
