const API_URL = "https://lucentapi.lucentnfts.com";
export const userTX = async (data) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
        body: JSON.stringify(data)
    };
    const response = await fetch('https://lucentapi.lucentnfts.com/user/tx', requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const checkNFTOwner = async (data) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
        body: JSON.stringify(data)
    };
    const response = await fetch('https://lucentapi.lucentnfts.com/user/checkNFTOwner', requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
//return all nfts minted by a user
export const getUserNFTs = async (page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/user/nfts?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getUserWithdraws = async (page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/user/withdraws?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getUserTransactions = async (page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/user/transactions?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getUserNFTsWhitelist = async (page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`http://localhost:3000/user/nftswhitelist?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getNFTsRanking = async (page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/ranking/nfts?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getProfilesRanking = async (page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/ranking/profiles?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getCollectorsRanking = async (page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/ranking/collectors?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getUserNotifications = async (page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/user/notifications?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getExemplarInfo = async (contract, token) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/getExemplarInfo?contract=${contract}&token=${token}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getExemplarNFTs = async (contract, token, page, S1, S2) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/exemplar/nfts?contract=${contract}&token=${token}&page=${page}&s1=${S1}&s2=${S2}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getHomeSlides = async () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/home/slides`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getProfileNFTs = async (wp, page, ct) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/profile/nfts?wp=${wp}&page=${page}&ct=${ct}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const searchLucentNFTs = async (page, k, S1, S2) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/lucents?page=${page}&k=${k}&s1=${S1}&s2=${S2}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const searchProfiles = async (page, k) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/profiles?page=${page}&k=${k}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getLucentNFT = async (id) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/lucent/${id}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getAutographs = async (id, page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/lucent/${id}/autographs?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const myLucents = async (wp, page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/mylucents?wp=${wp}&page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const mySignOffers = async (page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/mySignOffers?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}

export const checkAutographPriceUpdating = async () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/user/checkAutographPriceUpdating`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const checkAutographMinting = async () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/user/checkAutographMinting`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const autographRequests = async (page) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/autographRequests?page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getApproveTx = async (id) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/getApproveTx?nftID=${id}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getWithdrawStatuses = async () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/user/tx/withdraws`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const checkNewNoty = async () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/user/checknewnoty`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getLucentMintingPrice = async () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/mintingprice`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getSig = async (chain, contract, token) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`https://lucentapi.lucentnfts.com/sig?chain=${chain}&contract=${contract}&token=${token}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const checkNewNotyX = async () => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
        body: JSON.stringify({})
    };
    const response = await fetch('https://lucentapi.lucentnfts.com/user/checknoty', requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const xemail = async (data) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };
    const response = await fetch('https://lucentapi.lucentnfts.com/email', requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getTXCheckZoneMarketplace = async (nftID,lastMarketplaceTX) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`${API_URL}/txcheckzone/marketplace?nftID=${nftID}&lastMarketplaceTX=${lastMarketplaceTX}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const getTXCheckZoneAutograph = async (nftID,signerWP) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
    };
    const response = await fetch(`${API_URL}/txcheckzone/autograph?nftID=${nftID}&signerWP=${signerWP}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const checkWP = async (wp) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const response = await fetch(`${API_URL}/user/account?wp=${wp}`, requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
export const addLinkToWhitelist = async (data) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
        },
        body: JSON.stringify(data)
    };
    const response = await fetch('http://localhost:3000/user/nftswhitelist', requestOptions);
    const jsonData = await response.json();
    return jsonData;
}
 