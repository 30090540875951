import React, { useState, useMemo } from 'react';
// import { Router, Location, Redirect } from '@reach/router';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/header';
// import Home from './pages/home';
// import Home1 from './pages/home1';
import Home2 from './pages/home2';
import Explore from './pages/explore';
import Explore2 from './pages/explore2';
import SignOffers from './pages/signoffers';
import MySignOffers from './pages/mysignoffers';
import SignatureRequest from './pages/signaturerequest';
import Transactions from './pages/transactions';
import Notifications from './pages/notifications';
import Exemplar from './pages/exemplar';
import Helpcenter from './pages/helpcenter';
import Ranking from './pages/ranking';
import Colection from './pages/colection';
import ItemDetail from './pages/ItemDetail';
import Author from './pages/Author';
import Wallet from './pages/wallet';
import Login from './pages/login';
import LoginTwo from './pages/loginTwo';
import Register from './pages/register';
import Price from './pages/price';
import Works from './pages/works';
import News from './pages/news';
import Create from './pages/create';
import Auction from './pages/Auction';
import Activity from './pages/activity';
import Contact from './pages/contact';
import ElegantIcons from './pages/elegantIcons';
import EtlineIcons from './pages/etlineIcons';
import FontAwesomeIcons from './pages/fontAwesomeIcons';
import Accordion from './pages/accordion';
import Alerts from './pages/alerts';
import Progressbar from './pages/progressbar';
import Tabs from './pages/tabs';

import { createGlobalStyle } from 'styled-components';
import { UserContext } from '../context/UserContext';
import { AuthContext } from '../context/AuthContext';
import Royalty from './pages/royalty';
import CoverEdit from './pages/CoverEdit';
import Fullname from './pages/Fullname';
import Description from './pages/Description';
import Social from './pages/Social';
import Profiles from './pages/profiles';
import RankingProfiles from './pages/rankingprofiles';
import RankingNFTs from './pages/rankingnfts';
import RankingCollectors from './pages/rankingcollectors';
import ProfileAutograph from './pages/ProfileAutograph';
import '@sweetalert2/theme-dark/dark.scss';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import NFTWhitelist from './pages/nftwhitelist';
const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

// const PosedRouter = ({ children }) => (
//   <Location>
//     {({ location }) => (
//       <div id='routerhang'>
//         <div key={location.key}>
//           <Router location={location}>
//             {children}
//           </Router>
//         </div>
//       </div>
//     )}
//   </Location>
// );


const App = () => {

  const [userName, setUserName] = useState('John Smith');
  const m_userName = useMemo(
    () => ({ userName, setUserName }),
    [userName]
  );

  const [userInfo, setUserInfo] = useState();
  const m_userInfo = useMemo(
    () => ({ userInfo, setUserInfo }),
    [userInfo]
  );

  return (

    <div className="wraper">
      <ReactNotifications types={[{
            htmlClasses: ["bbx"],
            name: "awesome"
          }]} />
      <UserContext.Provider value={m_userName}>
        <AuthContext.Provider value={m_userInfo}>
          <GlobalStyles />

          {/* <PosedRouter> */}

            <ScrollTop path="/">
              <Header />
              <Routes>
                {/* <Route path="/"  element={<App />} /> */}
                <Route path='*' element={<Home2 />} />
                <Route path="/" element={<Home2 />} />

                <Route path="/explore" element={<Explore />} />
                <Route path="/profiles" element={<Profiles />} />
                <Route path="/autograph/requests" element={<SignOffers />} />
                <Route path="/autograph/statuses" element={<MySignOffers />} />
                <Route path="/request/autograph/:id" element={<SignatureRequest />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/nftswhitelist" element={<NFTWhitelist />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/royalty" element={<Royalty />} />
                <Route path="/reference/:chain/:contract/:token" element={<Exemplar />} />
                <Route path="ranking">
                  <Route path="profiles" element={<RankingProfiles />} />
                  <Route path="nfts" element={<RankingNFTs />} />
                  <Route path="collectors" element={<RankingCollectors />} />
                </Route>
                <Route path="/lucent/:id" element={<ItemDetail />} />
                <Route path="/profile/:id" element={<Author />} />
                <Route path="/settings/profile/avatar" element={<LoginTwo />} />
                <Route path="/settings/profile/autograph" element={<ProfileAutograph />} />
                <Route path="/settings/profile/cover" element={<CoverEdit />} />
                <Route path="/settings/profile/social" element={<Social />} />
                <Route path="/settings/profile/name" element={<Fullname />} />
                <Route path="/settings/profile/description" element={<Description />} />
                <Route path="/create" element={<Create />} />
                <Route path="/wallet" element={<Wallet />} />
              </Routes>
            </ScrollTop>
          {/* <Home2 exact path="/">
            <Redirect to="/home2" />
          </Home2>
          <Home2 path="/home2" />
          <Explore path="/explore" />
          <Profiles path="/profiles" />
          <Explore2 path="/explore2" />
          <SignOffers path="/signoffers" />
          <MySignOffers path="/mysignoffers" />
          <SignatureRequest path="/signaturerequest/:id" />
          <Transactions path="/transactions" />
          <Notifications path="/notifications" />
          <Royalty path="/royalty" />
          <Exemplar path="/exemplar/:contract/:token" />
          <Helpcenter path="/helpcenter" />
          <Ranking path="/ranking" />
          <RankingProfiles path="ranking/profiles" />
          <RankingNFTs path="/ranking/nfts" />
          <RankingCollectors path="/ranking/collectors" />
          <Colection path="/colection" />
          <ItemDetail path="/lucent/:id" />
          <Author path="/profile/:id" />
          <Wallet path="/wallet" />
          <Login path="/login" />
          <LoginTwo path="/loginTwo" />
          <ProfileAutograph path="/profile/autograph" />
          <CoverEdit path="/coveredit" />
          <Social path="/profile/social" />
          <Fullname path="/fullname" />
          <Description path="/description" />
          <Register path="/register" />
          <Price path="/price" />
          <Works path="/works" />
          <News path="/news" />
          <Create path="/create" />
          <Auction path="/Auction" />
          <Activity path="/activity" />
          <Contact path="/contact" />
          <ElegantIcons path="/elegantIcons" />
          <EtlineIcons path="/etlineIcons" />
          <FontAwesomeIcons path="/fontAwesomeIcons" />
          <Accordion path="/accordion" />
          <Alerts path="/alerts" />
          <Progressbar path="/progressbar" />
          <Tabs path="/tabs" /> */}

          {/* </PosedRouter> */}
          {/* <ScrollToTopBtn /> */}
        </AuthContext.Provider>
      </UserContext.Provider>
    </div>
  )
};
export default App;