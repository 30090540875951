import { useState, useEffect } from "react";
import { signerABI, signerAddress } from "../contracts/signerABI";
import { ethers } from "ethers";
const useSignerContract = () => {
  let { ethereum } = window;
  const [signerContract, setSignerContract] = useState(null);
 
  useEffect(() => {
    if(!ethereum)return;
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    setSignerContract(new ethers.Contract(signerAddress, signerABI, signer));
    console.log(">>x")
}, [ethereum]);

  return [signerContract];
};

export default useSignerContract;
