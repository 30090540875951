import React, { useState, useRef, useCallback } from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Cropper from 'react-easy-crop'
// import { navigate } from "@reach/router";
import { useNavigate, useParams,Link } from 'react-router-dom';
import {
  ThreeBounce
} from 'better-react-spinkit'
const GlobalStyles = createGlobalStyle`
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: block !important;
}
.navbar .mainside a{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
.item-dropdown{
  .dropdown{
    a{
      &:hover{
        background: #8364e2;
      }
    }
  }
}
.btn-main{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
  
}
p.lead{
  color: #a2a2a2;
}
.navbar .navbar-item .lines{
  border-bottom: 2px solid #8364e2;
}
.jumbotron.no-bg{
  height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
#tsparticles{
  top: 0;
}
.text-uppercase.color{
  color: #8364e2;
}
.de_count h3 {
  font-size: 36px;
  margin-bottom: 0px;
}
.de_count h5{
  font-size: 14px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
}
.box-url{
  text-align: center;
  h4{
    font-size: 16px;
  }
}
.de_countdown{
  border: solid 2px #8364e2;
}
.author_list_pp, .author_list_pp i, 
.nft_coll_pp i, .feature-box.style-3 i, 
footer.footer-light #form_subscribe #btn-subscribe i, 
#scroll-to-top div{
  background: #8364e2;
}
footer.footer-light .subfooter .social-icons span i{
  background: #403f83;
}
.author_list_pp:hover img{
  box-shadow: 0px 0px 0px 2px #8364e2;
}
.nft__item_action span{
  color: #8364e2;
}
.feature-box.style-3 i.wm{
  color: rgba(131,100,226, .1);
}
@media only screen and (max-width: 1199px) {
  .navbar{
    
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
  }
}
.profileBtn{
  width:150px;
  text-align: center;
  margin-top:5px;
}
.fsize32{
  font-size: 42px;
}
.hhx1{
  position: relative;
margin: 10px auto;
width: 400px;
height: 400px;
background:black;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: block;
  width: max-content;
  text-align: center;
  color: #fff !important;
  border-radius: 30px;
  letter-spacing: normal;
  outline: 0;
  // font-weight: 800;
  text-decoration: none;
  padding: 5px 30px;
  margin:0px 10px;
  font-size: 14px;
  border: none;
  cursor: pointer;
    box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
    transition: all 0.3s ease;
    border: 2px solid #8364e2;
    &:hover{
      box-shadow: 2px 2px 20px 0px #8364e2;
    }
 
}
 
`;

const Logintwo = () => {
  const [Uploading, setUploading] = useState(false)
  const [ImageSelected, setImageSelected] = useState(false)
  
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [cropM, setCropM] = useState({})
  const [zoom, setZoom] = useState(1)
  const [initialCroppedAreaPixels, setInitialCroppedAreaPixels] = useState({ x: 0, y: 0, width: 400, height: 400 })


  const [selectedFile, setSelectedFile] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFileStr, setSelectedFileStr] = useState("")

  const changeHandler = (event) => {
    console.log("event.target.files[0]: ", event.target.files[0])
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const image = reader.result;
        setSelectedFile(image);
        // setImageToCrop(image);
        setImageSelected(true)
      });

      reader.readAsDataURL(event.target.files[0]);
      setSelectedFileStr(event.target.files[0])
    }


    // setIsSelected(true);
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
    setCropM(croppedAreaPixels)
  }, [])

  // let formData = new FormData();    //formdata object

  // formData.append('name', 'ABC');   //append the values with key, value pair
  // formData.append('age', 20);

  // const config = {     
  //     headers: { 'content-type': 'multipart/form-data' }
  // }

  // axios.post(url, formData, config)
  //     .then(response => {
  //         console.log(response);
  //     })
  //     .catch(error => {
  //         console.log(error);
  //     });
  let navigate = useNavigate();
  const handleSubmission = () => {
    const formData = new FormData();
    // formData.append('file', selectedFile);
    formData.append('file', selectedFileStr);
    formData.append('x', cropM.x);
    formData.append('y', cropM.y);
    formData.append('width', cropM.width);
    formData.append('height', cropM.height);

    console.log("localStorage.getItem('token'): ", localStorage.getItem('token'))
    setUploading(true);
    fetch(
      'https://lucentapi.lucentnfts.com/user/uploadAvatar',
      {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('token'),
        },
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        navigate("/profile/"+localStorage.getItem('wp'))
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


const goBack = ()=>{
  navigate("/profile/"+localStorage.getItem('wp'))
}


  return (
    <>
      <GlobalStyles />
      <section className=' breadcumb no-bg '>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>Avatar</h1>
                <div className='text-center fontsize12'>Please upload 1024 x 1024 or higher resolution JPG image.</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="spacer-double"></div>
      <div className="hhx1">

        {selectedFile && <Cropper
          image={selectedFile}
          crop={crop}
          zoom={zoom}
          // initialCroppedAreaPixels = {initialCroppedAreaPixels}
          aspect={1 / 1}
          cropShape="square"
          // objectFit="contain"
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />}
      </div>
      {/* <div className="container">
      <div className="row ">
      <div className='col-lg-6 px-0 '>
      <label for="file-upload" class="custom-file-upload">
        <i class="fa fa-cloud-upload"></i> Custom Upload
      </label>
      <input type="file" id="file-upload" name="file-upload" onChange={changeHandler} />
      <span onClick={() => handleSubmission()} className="btn-main fontsize12"> <span className="">Accept</span></span>
      </div>
      </div>
      </div> */}
      <div className="spacer-double"></div>
      <section className='breadcumb2   '>
        <div className=''>
          <div className=''>


          </div>

        </div>
        <div class="container   ">
          {/* <div className="col-lg-12 ">
            
            <ul className="activity-filter ">
              <li id='sale' className="filter_by_sales"  ><i className="fa fa-star"></i>Lucent NFTs</li>
              <li id='like' className="filter_by_likes"  ><i className="fa fa-tag"></i>Owned NFTs</li>
              <li id='like' className="filter_by_likes"  ><i className="fa fa-heart"></i>Created NFTs</li>
              <li id='like' className="filter_by_likes"  ><i className="fa fa-pencil"></i>Signed NFTs</li>
              
            </ul>
          </div> */}
          <div className='row '>
            <div className='col-md-8 offset-2'>
              <div className=" ">
                {/* <div className='spacer-single'></div> */}
                <ul className="de_nav2 ">
                  <li id=' ' onClick={goBack} className=" col-white">
                    <span className="btn-main2 fontsize12"> <span className=""><i class="fa fa-arrow-left"></i> Profile</span></span>



                  </li>
                  {/* <li id='Mainbtn' className="active"><span onClick={handleBtnClick} className="profileBtn">Lucent NFTs</span></li>
                    <li id='Mainbtn2' className=""><span onClick={handleBtnClick2} className="profileBtn">Owned NFTs</span></li>
                    <li id='Mainbtn4' className=""><span onClick={handleBtnClick2} className="profileBtn">Created NFTs</span></li> */}

                  <li id=' '  className=" col-white">
                    <label for="file-upload" class="custom-file-upload">
                      <i class="fa fa-cloud-upload"></i> Upload
                    </label>
                    <input type="file" id="file-upload" name="file-upload" onChange={changeHandler} />
                  </li>

                  {(Uploading) && <><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt">Please wait...</span></>}
                  {!Uploading && ImageSelected && <li id=' ' onClick={() => { console.log("btn3") }} className=" col-white">
                    <span onClick={() => handleSubmission()} className="btn-main fontsize12"> <span className="">Confirm</span></span>
                  </li>}

                </ul>
              </div>
            </div>
          </div>
          {/* <div className='spacer-10'></div> */}
          <div className='spacer-single'></div>
        </div>
      </section>
      <Footer />
    </>
  )
}
export default Logintwo;