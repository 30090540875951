import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import ColumnNew from '../components/ColumnNew';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { searchLucentNFTs, searchProfiles } from '../../api/api';
import Pagination from "../components/Pagination";
// import { navigate } from '@reach/router';
import { useNavigate, useParams,Link } from 'react-router-dom';

import {
  ThreeBounce
} from 'better-react-spinkit'
const GlobalStyles = createGlobalStyle`
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: block !important;
}
.navbar .mainside a{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
.item-dropdown{
  .dropdown{
    a{
      &:hover{
        background: #8364e2;
      }
    }
  }
}
.btn-main{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
p.lead{
  color: #a2a2a2;
}
.navbar .navbar-item .lines{
  border-bottom: 2px solid #8364e2;
}
.jumbotron.no-bg{
  height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
#tsparticles{
  top: 0;
}
.text-uppercase.color{
  color: #8364e2;
}
.de_count h3 {
  font-size: 36px;
  margin-bottom: 0px;
}
.de_count h5{
  font-size: 14px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
}
.box-url{
  text-align: center;
  h4{
    font-size: 16px;
  }
}
.de_countdown{
  border: solid 2px #8364e2;
}
.author_list_pp, .author_list_pp i, 
.nft_coll_pp i, .feature-box.style-3 i, 
footer.footer-light #form_subscribe #btn-subscribe i, 
#scroll-to-top div{
  background: #8364e2;
}
footer.footer-light .subfooter .social-icons span i{
  background: #403f83;
}
.author_list_pp:hover img{
  box-shadow: 0px 0px 0px 2px #8364e2;
}
.nft__item_action span{
  color: #8364e2;
}
.feature-box.style-3 i.wm{
  color: rgba(131,100,226, .1);
}
@media only screen and (max-width: 1199px) {
  .navbar{
    
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
  }
}
.profileBtn{
  width:150px;
  text-align: center;
  margin-top:5px;
}
.fsize32{
  font-size: 42px;
}
.text-left{
  text-align: left;
}
`;
const customStyles = {
  option: (base, state) => ({
    ...base,
    background: "#212428",
    color: "#fff",
    borderRadius: state.isFocused ? "0" : 0,
    "&:hover": {
      background: "#16181b",
    }
  }),
  menu: base => ({
    ...base,
    background: "#212428 !important",
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    padding: 0
  }),
  control: (base, state) => ({
    ...base,
    padding: 2
  })
};


const options = [
  { value: '0', label: 'All categories' },
  { value: '1', label: 'Art' },

]
const options1 = [
  { value: 'Buy Now', label: 'Buy Now' },
  { value: 'On Auction', label: 'On Auction' },
  { value: 'Has Offers', label: 'Has Offers' }
]
const options2 = [
  { value: 'All Items', label: 'All Items' },
  { value: 'Single Items', label: 'Single Items' },
  { value: 'Bundles', label: 'Bundles' }
]


const Profiles = () => {
  const [loading, setLoading] = useState(false);
  const [Keyword, setKeyword] = useState("");
  const [SearchKeyword, setSearchKeyword] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [items, setItems] = useState([]);
  const getProfiles = async () => {
    setLoading(true)
    let res = await searchProfiles(currentPage, SearchKeyword);
    setItems(res.data);
    setTotalRecords(res.totalRecords)
    setLoading(false)
  }

  useEffect(() => {
    getProfiles();

  }, []);
  useEffect(() => {
    getProfiles();

  }, [currentPage, SearchKeyword])
  const onPageChange = async (page) => {

    // console.log("onPageChange: ", page)
    setCurrentPage(page)


  }
  let navigate = useNavigate();
  const gotoProfile = (wp) => {
    navigate("/profile/" + wp)
  }
  let zID;
  const handleKeywordChange = async (e) => {
    clearTimeout(zID)
    zID = setTimeout(() => {
      setKeyword(e.target.value)
      setSearchKeyword(e.target.value);
      setCurrentPage(1)


    }, 200);
  }
   

  return (<div>
    <GlobalStyles />
    <section className=' breadcumb no-bg'>
      <div className='mainbreadcumb'>
        <div className='container'>
          <div className='row m-10-hor'>
            <div className='col-12 text-center'>
              <h1 className='text-center'>Profiles</h1>
              <div className="spacer-single"></div>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className="items_filter">
                    <form className="row form-dark" id="form_quick_searchx" name="form_quick_searchx">
                      <div className="col">
                        <input className="form-control" id="name_1" name="name_1" placeholder="Search..." type="text" onChange={handleKeywordChange} />
                        {/* <button id="btn-submit" onClick={(e)=>onSearchClick(e)}><i className="fa fa-search bg-color-secondary"></i></button> */}
                        <div className="clearfix"></div>
                      </div>
                    </form>
                    {/* <div className='dropdownSelect one '><Select className='select1 text-left' styles={customStyles} menuContainerStyle={{ 'zIndex': 999 }} defaultValue={options[0]} options={options} /></div> */}
                    {/* <div className='dropdownSelect two'><Select className='select1 text-left' styles={customStyles} defaultValue={options1[0]} options={options1} /></div>
                    <div className='dropdownSelect three'><Select className='select1 text-left' styles={customStyles} defaultValue={options2[0]} options={options2} /></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section  >

      {/* <ColumnNew /> */}
      {/* <ColumnLucents dx={items||[]}/>
      <div className="spacer-single"></div>
      <Pagination onPageChange={onPageChange} _currentPage={currentPage} recordsPerPage={20} pageNumberLimit={5} totalRecords={totalRecords} /> */}
      <div className="container ">
        <div className="row">
          {!loading && <>
            {items && items.map((e) => {
              return (<div className='col-lg-3 col-md-6 col-sm-6 col-xs-12' key={e.id}>
                <div className="nft_coll">
                  <div className="nft_wrap">
                    <span><img src={e.cover} className="lazy img-fluid" alt="" /></span>
                  </div>
                  <div className="nft_coll_pp">
                    <span onClick={() => gotoProfile(e.wp)}><img className="lazy" src={e.avatar} alt="" /></span>
                    {e.isOfficial && <i className="fa fa-check"></i>}
                  </div>
                  <div className="nft_coll_info">
                    <h4 onClick={() => gotoProfile(e.wp)}>{e.fullName} </h4>
                    <div><span className="fontsize10 bolder">AUTOGRAPHS</span> <span className="bolder fontsize12 col-white">{e.autographsCount}</span></div>
                    <div className="spacer-single"></div>
                  </div>
                </div>
              </div>)
            })}
          </>}
          {loading && <center><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt"></span></center>}
          {
            !loading && items && items.length === 0 && <div className="text-center">
              No results found.
            </div>
          }
          <div className="spacer-double"></div>
          <Pagination onPageChange={onPageChange} _currentPage={currentPage} recordsPerPage={20} pageNumberLimit={5} totalRecords={totalRecords} />
        </div>
      </div>
    </section>


    <Footer />
  </div>);
};
export default Profiles;