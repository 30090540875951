import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { navigate } from "@reach/router";
import { useNavigate, useParams,Link } from 'react-router-dom';

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

const XTopProfiles = ({topProfiles})=> {
  let navigate = useNavigate();
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: 300,
      // dots: true,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true
          }
        },
        // {
        //   breakpoint: 600,
        //   settings: {
        //     slidesToShow: 2,
        //     slidesToScroll: 1,
        //     initialSlide: 2
        //   }
        // },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    };
   
    const gotoRankingProfiles= ()=>{
      navigate(`/ranking/profiles`);
    }
    const gotoProfile = (wp)=>{
      navigate("/profile/"+wp)
    }
    return (
      <div className='nft'>
        <Slider {...settings}>
          {topProfiles && topProfiles.map((item, index) => {
            return <CustomSlide className='itm' key={index+1} index={index+1}>
              <div className="nft_coll">
                <div className="nft_wrap">
                  <span><img src={item.cover} className="lazy img-fluid" alt="" /></span>
                </div>
                <div className="nft_coll_pp">
                  <span onClick={() => gotoProfile(item.wp)}><img className="lazy" src={item.avatar} alt="" /></span>
                  {item.isOfficial && <i className="fa fa-check"></i>}
                </div>
                <div className="nft_coll_info">
                  <h4 onClick={() => gotoProfile()}>{item.name}</h4>
                  <span className="fontsize10">AUTOGRAPHS: <span className="fontsize10 col-white bolder">{item.autographsCount}</span></span>
                  <div className="spacer-single"></div>
                  {/* <span>ERC-192</span> */}
                </div>
              </div>
            </CustomSlide>

          })}
        </Slider>
                <div className="spacer-single"></div>
        <div className='col-lg-12 xparent'>
                <span onClick={gotoRankingProfiles} className="btn-main2 lead m-auto">See All</span>
            </div>
      </div>
    );
  
}
export default XTopProfiles;
