import React, { useContext, useEffect, useState } from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Pagination from "../components/Pagination";
import {
  ThreeBounce
} from 'better-react-spinkit'
import { AuthContext } from '../../context/AuthContext';
import useMarketplaceContract from '../../hooks/useMarketplaceContract';
import useSignerContract from '../../hooks/useSignerContract';
import { ethers } from "ethers";
import useDepositorContract from '../../hooks/useDepositorContract';
import { getUserWithdraws, getWithdrawStatuses, userTX } from '../../api/api';
import { useTimer } from 'use-timer';
import useRoyalty from '../../hooks/useRoyalty';
import Moment from 'react-moment';
import Swal from 'sweetalert2'
import '@sweetalert2/theme-dark/dark.scss';
const GlobalStyles = createGlobalStyle`
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: block !important;
}
.navbar .mainside a{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
.item-dropdown{
  .dropdown{
    a{
      &:hover{
        background: #8364e2;
      }
    }
  }
}
.btn-main{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
p.lead{
  color: #a2a2a2;
}
.navbar .navbar-item .lines{
  border-bottom: 2px solid #8364e2;
}
.jumbotron.no-bg{
  height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
#tsparticles{
  top: 0;
}
.text-uppercase.color{
  color: #8364e2;
}
.de_count h3 {
  font-size: 36px;
  margin-bottom: 0px;
}
.de_count h5{
  font-size: 14px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
}
.box-url{
  text-align: center;
  h4{
    font-size: 16px;
  }
}
.de_countdown{
  border: solid 2px #8364e2;
}
.author_list_pp, .author_list_pp i, 
.nft_coll_pp i, .feature-box.style-3 i, 
footer.footer-light #form_subscribe #btn-subscribe i, 
#scroll-to-top div{
  background: #8364e2;
}
footer.footer-light .subfooter .social-icons span i{
  background: #403f83;
}
.author_list_pp:hover img{
  box-shadow: 0px 0px 0px 2px #8364e2;
}
.nft__item_action span{
  color: #8364e2;
}
.feature-box.style-3 i.wm{
  color: rgba(131,100,226, .1);
}
@media only screen and (max-width: 1199px) {
  .navbar{
    
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
  }
}
.profileBtn{
  width:150px;
  text-align: center;
  margin-top:5px;
}
.fsize32{
  font-size: 42px;
}
.text-left{
  text-align: left;
}
.exemplar_avatar {
  display: flex;
}
`;

const Royalty = function () {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const { time, start, pause, reset, status } = useTimer();

  const [GoPending1, setGoPending1] = useState(false);
  const [GoPending2, setGoPending2] = useState(false);
  const [GoPending3, setGoPending3] = useState(false);


  const [Clicker1, setClicker1] = useState(false);
  const [Clicker2, setClicker2] = useState(false);
  const [Clicker3, setClicker3] = useState(false);


  useEffect(() => {
    start();
    return () => {
      reset()
    }
  }, [])

  const [marketplaceContract] = useMarketplaceContract()
  const [signerContract] = useSignerContract()
  const [depositorContract] = useDepositorContract()

  const [royalty1, setRoyalty1] = useState("0.0");
  const [royalty2, setRoyalty2] = useState("0.0");
  const [royalty3, setRoyalty3] = useState("0.0");




  const { NewRoyalty, N1NewRoyalty, N2NewRoyalty, N3NewRoyalty,TotalNewRoyalty } = useRoyalty(5);
  const [NewRoyaltyFormatted, setNewRoyaltyFormatted] = useState("0.0");
  const [TotalNewRoyaltyFormatted, setTotalNewRoyaltyFormatted] = useState("0.0");
  useEffect(() => {
    const nrf = ethers.utils.formatEther(NewRoyalty)
    console.log("NewRoyalty: ", nrf)
    setNewRoyaltyFormatted(nrf)
  }, [NewRoyalty])
  useEffect(() => {
    const nrf = ethers.utils.formatEther(TotalNewRoyalty)
    console.log("TotalNewRoyalty: ", nrf)
    setTotalNewRoyaltyFormatted(nrf)
  }, [TotalNewRoyalty])

  useEffect(() => {
    const nrf = ethers.utils.formatEther(N1NewRoyalty)
    setRoyalty1(nrf)
    if(nrf==='0.0'){
      setClicker1(false);
    }else{
      if(!PendingsDepositor)
      setClicker1(true);
    }
  }, [N1NewRoyalty])
  useEffect(() => {
    const nrf = ethers.utils.formatEther(N2NewRoyalty)
    setRoyalty2(nrf);
    if(nrf==='0.0'){
      setClicker2(false);
    }else{
      if(!PendingsCentral)
      setClicker2(true);
    }
  }, [N2NewRoyalty])
  useEffect(() => {
    const nrf = ethers.utils.formatEther(N3NewRoyalty)
    setRoyalty3(nrf)
    if(nrf==='0.0'){
      setClicker3(false);
    }else{
      if(!PendingsSigner)
      setClicker3(true);
    }
  }, [N3NewRoyalty])





  useEffect(() => {
    getWithdrawStatusesX();
  }, [])


  const withdrawDepositor = async () => {
    const ToastPender = Swal.mixin({
			toast: true,
			position: 'center-center',
			showConfirmButton: false,
			// timer: 3000,
			timerProgressBar: false,
			// didOpen: (toast) => {
			//   toast.addEventListener('mouseenter', Swal.stopTimer)
			//   toast.addEventListener('mouseleave', Swal.resumeTimer)
			// }
		  })
	  
		  ToastPender.fire({
			icon: 'info',
			title: 'Connecting To Wallet...'
		  })
    try {
      let tx = await depositorContract.withdraw();
    console.log("tx: ", tx)
    ToastPender.close();
    await userTX({
      type: "WITHDRAW_FUNDS_DEPOSITOR",
      txHash: tx.hash,
      amount: N1NewRoyalty.toString()
    })
    const Toast = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        // toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: 'Transaction Submitted!'
    })
    setClicker1(false);
    setPendingsDepositor(true)
    getWithdrawStatusesX();
    } catch (error) {
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }
  };
  const withdrawCentral = async () => {
    const ToastPender = Swal.mixin({
			toast: true,
			position: 'center-center',
			showConfirmButton: false,
			// timer: 3000,
			timerProgressBar: false,
			// didOpen: (toast) => {
			//   toast.addEventListener('mouseenter', Swal.stopTimer)
			//   toast.addEventListener('mouseleave', Swal.resumeTimer)
			// }
		  })
	  
		  ToastPender.fire({
			icon: 'info',
			title: 'Connecting To Wallet...'
		  })
    try {
      let tx = await marketplaceContract.withdraw();
    console.log("tx: ", tx)
    ToastPender.close();
    await userTX({
      type: "WITHDRAW_FUNDS_CENTRAL",
      txHash: tx.hash,
      amount: N2NewRoyalty.toString()
    })
    setClicker2(false);
    setPendingsCentral(true)
    getWithdrawStatusesX();
    } catch (error) {
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }
  };
  const withdrawSigner = async () => {
    const ToastPender = Swal.mixin({
			toast: true,
			position: 'center-center',
			showConfirmButton: false,
			// timer: 3000,
			timerProgressBar: false,
			// didOpen: (toast) => {
			//   toast.addEventListener('mouseenter', Swal.stopTimer)
			//   toast.addEventListener('mouseleave', Swal.resumeTimer)
			// }
		  })
	  
		  ToastPender.fire({
			icon: 'info',
			title: 'Connecting To Wallet...'
		  })
   try {
    let tx = await signerContract.withdraw();
    console.log("tx: ", tx)
    ToastPender.close();
    await userTX({
      type: "WITHDRAW_FUNDS_SIGNER",
      txHash: tx.hash,
      amount: N3NewRoyalty.toString()
    })
    const Toast = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        // toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: 'Transaction Submitted!'
    })
    setClicker3(false);
    setPendingsSigner(true)
    getWithdrawStatusesX();
   } catch (error) {
    ToastPender.close();
    if (error.code === -32603) {
      if (error.data.message.trim() === 'evm error: OutOfFund') {
        Swal.fire({
          // title: 'Please Connect A Wallet!',
          text: 'Insufficient funds!',
          // icon: 'error',
          icon: 'info',
          confirmButtonText: 'OK'
        })
      }

    }
   }
  };

  const [PendingsDepositor, setPendingsDepositor] = useState(false);
  const [PendingsCentral, setPendingsCentral] = useState(false);
  const [PendingsSigner, setPendingsSigner] = useState(false);
  const getWithdrawStatusesX = async () => {

    let res = await getWithdrawStatuses();
    console.log("getWithdrawStatusesX: ", res)
    if (res.data?.pendings?.length > 0) {
      if (res.data?.pendings?.some(item => 'WITHDRAW_FUNDS_DEPOSITOR' === item)) {

        setGoPending1(true);
      } else {

        setGoPending1(false);
      }
      if (res.data?.pendings?.some(item => 'WITHDRAW_FUNDS_CENTRAL' === item)) {

        setGoPending2(true);
      } else {

        setGoPending2(false);
      }
      if (res.data?.pendings?.some(item => 'WITHDRAW_FUNDS_SIGNER' === item)) {

        setGoPending3(true);
      } else {

        setGoPending3(false);
      }
    } else {


      setGoPending1(false);
      setGoPending2(false);
      setGoPending3(false);

    }

  };




  useEffect(() => {
    if (time % 10 === 9) {
      console.log("timer status: ", status)
      getWithdrawStatusesX();
      getItems();
    }
  }, [time])

  useEffect(() => {
    setTimeout(() => {

      setPendingsDepositor(GoPending1)
    }, 1000);
  }, [GoPending1])
  useEffect(() => {
    setTimeout(() => {

      setPendingsCentral(GoPending2)
    }, 1000);
  }, [GoPending2])
  useEffect(() => {
    setTimeout(() => {

      setPendingsSigner(GoPending3)
    }, 1000);
  }, [GoPending3])







  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenu4, setOpenMenu4] = React.useState(false);
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    setOpenMenu2(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
    setOpenMenu2(false);
    setOpenMenu(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.add("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.remove("active");
  };
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.add("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.remove("active");
  };
  const handleBtnClick3 = () => {
    setOpenMenu3(!openMenu3);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu2(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.add("active");
  };
  const handleBtnClick4 = () => {
    setOpenMenu4(!openMenu4);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    setOpenMenu2(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.add("active");
    document.getElementById("like").classList.remove("active");
  };
  ///////////////
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [items, setItems] = useState([]);
  const getItems = async () => {
    let res = await getUserWithdraws(currentPage);
    setItems(res.data);
    setTotalRecords(res.totalRecords)
  }


  useEffect(() => {
    getItems();

  }, [currentPage])
  const onPageChange = async (page) => {
    setCurrentPage(page)
  }
  ///////////////
  const shortener = (txt) => {
    return txt.toString().substring(0, 8) + "..." + txt.toString().substring(txt.toString().length - 6)
  }
  const amountFormatter = (amount) => {
    if (!amount) {
      return "0.0"
    }
    return ethers.utils.formatEther(amount)
  }
  const clusterLabel = (i) => {
    switch (i) {
      case 1: return "A";
      case 2: return "B"
      case 3: return "C"
    }
  }

  return (
    <div>
      <GlobalStyles />

      <section className='breadcumb  no-bg no-bottom'>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>Royalty</h1>
                <div className="spacer-half"></div>
                <div className="text-center">
                  <span className="bolder">DESTINATION</span>&nbsp;&nbsp;&nbsp;
                  {userInfo && userInfo.wp && <span id="wallet" className=" bolder   col-white">{userInfo.wp && (userInfo.wp.toString().substring(0, 6) + "..." + userInfo.wp.toString().substring(userInfo.wp.toString().length - 4))}</span>}
                  <button id="btn_copy" title="Copy" className="margin-left color-transparent"><i className="fa fa-copy "></i></button>
                </div>
                <div className='row'>
                  <div className="col-md-6 text-right">
                    <span className="bolder fontsize18">NEW </span><span className='text-center color bolder fontsize18'> {NewRoyaltyFormatted !== "0.0" && "+"} {NewRoyaltyFormatted} GLMR</span>
                  </div>
                  <div className="col-md-6 text-left">
                    <span className="bolder fontsize18">TOTAL </span><span className='text-center color bolder fontsize18'> {TotalNewRoyaltyFormatted} GLMR</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>

          <div className="col-md-4 text-center">
            <div className="feature-box f-boxed style-3 x-margin-left">
              <span className="  loadertxt">STAR CLUSTER A <span className="bolder col-purple">{royalty1 !== "0.0" && "+"}{royalty1} GLMR</span></span><br />
              <div className="spacer-10"></div>
              {(PendingsDepositor) && <><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt">Withdrawing...</span></>}
              {!PendingsDepositor && royalty1 !== "0.0" && Clicker1 && <span onClick={withdrawDepositor} className="btn-main2 go-center"><span className="">WITHDRAW</span></span>}
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="feature-box f-boxed style-3">
              <span className="  loadertxt">STAR CLUSTER B <span className="bolder col-purple">{royalty2 !== "0.0" && "+"}{royalty2} GLMR</span></span><br />
              <div className="spacer-10"></div>
              {(PendingsCentral) && <><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt">Withdrawing...</span></>}
              {!PendingsCentral && royalty2 !== "0.0" && Clicker2 &&<span onClick={withdrawCentral} className="btn-main2 go-center"><span className="">WITHDRAW</span></span>}
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="feature-box f-boxed style-3 x-margin-right">
              <span className="  loadertxt">STAR CLUSTER C <span className="bolder col-purple">{royalty3 !== "0.0" && "+"}{royalty3} GLMR</span></span><br />
              <div className="spacer-10"></div>
              {(PendingsSigner) && <><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt">Withdrawing...</span></>}
              {!PendingsSigner && royalty3 !== "0.0" && Clicker3 && <span onClick={withdrawSigner} className="btn-main2 go-center"><span className="">WITHDRAW</span></span>}
            </div>
          </div>

        </div>
        <div className="spacer-double"></div>
      </section>


      <div className='container'>
        <div className="spacer-double"></div>
        <table className="table de-table table-rank">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">By</th>
              <th scope="col">Royalty</th>
              <th scope="col">TxHash</th>
              <th scope="col">Status</th>
            </tr>
            <tr></tr>
          </thead>
          <tbody>
            {items && items.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <span className="color-grey fontsize14">
                      {(item.createdAt || item.updatedAt) && <Moment format="HH:mm DD MMMM YYYY">
                        {item.updatedAt || item.createdAt}
                      </Moment>}
                    </span>
                  </td>
                  <td className="fontsize14">STAR CLUSTER {clusterLabel(item.clusterType)}</td>
                  <td className="d-plus bolder fontsize14">+{amountFormatter(item.amount)} GLMR</td>
                  <td>{item.txHash && <><a className=' color' href={('https://moonbeam.moonscan.io/tx/') + (item.txHash)} target="_blank"><span >{shortener(item.txHash)}  <span className="fa fa-link "></span></span></a>

                  </>}</td>

                  <td>
                    {item && item.status === 0 && <div className="statusLabel-yellow">PENDING</div>}
                    {item && item.status === 1 && <div className="statusLabel-green">SUCCESS</div>}
                    {item && item.status === 2 && <div className="statusLabel-red">FAILED</div>}
                  </td>
                </tr>
              )
            })}


          </tbody>
        </table>
        <div className='spacer-double'></div>
        <div ><Pagination onPageChange={onPageChange} _currentPage={currentPage} recordsPerPage={10} pageNumberLimit={5} totalRecords={totalRecords} /></div>
        <div className='spacer-double'></div>
      </div>

      <Footer />
    </div>

  );
}

export default Royalty;