import { useState, useEffect } from "react";
import { minterABI, minterAddress } from "../contracts/minterABI";
import { ethers } from "ethers";
const useMinterContract = () => {
  let { ethereum } = window;
  const [minterContract, setMinterContract] = useState(null);
 
  useEffect(() => {
    if(!ethereum)return;
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    setMinterContract(new ethers.Contract(minterAddress, minterABI, signer));
    console.log(">>x")
}, [ethereum]);

  return [minterContract];
};

export default useMinterContract;
