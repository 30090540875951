import React, { useContext, useEffect, useState } from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import SliderCarousel from './SliderCarouselsingle';
// import {
//   Router,
//   Link,
//   navigate
// } from "@reach/router"
import { useNavigate, useParams, Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import '@sweetalert2/theme-dark/dark.scss';
import { AuthContext } from '../../context/AuthContext';
import XSlider from './SliderCarouselsingle';
import Particle from './Particle';
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

const Slidermainparticle = ({ homeSlides }) => {
  let navigate = useNavigate();
  const explore = () => {
    navigate(`/explore`)
  }
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const notify = () => {
    if (userInfo && userInfo.isAuth) {
      navigate(`/create`)
    } else {
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Please Connect A Wallet!',
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    }

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        // toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    // Toast.fire({
    //   icon: 'success',
    //   title: 'Signed in successfully'
    // })
  };
  const [ShowR, setShowR] = useState(false);
const imageOnLoadHandler = ()=>{
  setShowR(true)
}
  // useEffect(() => {
  //   setTimeout(() => {
      
  //   }, 500);
  // }, [])
  return <div className="container">
   
    <div className="row align-items-center">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        {ShowR && <div className="paddingbottom20 ">
          <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
            <h1 className="col-white ">
              Discover, collect, and sell original <span className="col-purple">NFT Autographs</span>
            </h1>
          </Reveal>
          <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={900} triggerOnce>
            <p className=" col-white bolder fontsize21 ">
              Lucent NFTs is the world's first and largest NFT autographs marketplace
            </p>
          </Reveal>
          <div className="spacer-10"></div>
          <Reveal className='onStep d-inline' keyframes={inline} delay={800} duration={900} triggerOnce>
            <span onClick={explore} className="btn-main inline lead z1">Explore</span>
            <span onClick={notify} className="btn-main2 inline lead z1">Create</span>
            {/* <div className="mb-sm-30"></div> */}
            
          </Reveal>
        </div>}

      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  xs-hide ">

        <XSlider homeSlides={homeSlides} imageOnLoadHandler={imageOnLoadHandler}/>

      </div>
    </div>

  </div>
};
export default Slidermainparticle;