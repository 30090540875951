import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { Link } from "@reach/router";
import { useNavigate, useParams,Link } from 'react-router-dom';

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );


  }
}

const XNotableDrops = ({ notableDrops }) => {


  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: 300,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  console.log("x nd: ", notableDrops)

  return (
    <div className='nft-big'>
      <Slider {...settings}>

        {notableDrops && notableDrops.map((item, index) => {
          return (<CustomSlide className='itm' key={"nd" + index}>
            <Link className="axnoline" to={`/reference/${item.chainF}/${item.eContract}/${item.eTokenID}`}>
              <div className="nft_pic">
                <span>
                  <span className="nft_pic_info">
                    <span className="nft_pic_title fontsize21">{item.title}</span>
                    <span className="nft_pic_by">{item.author}</span>
                  </span>
                </span>
                <div className="nft_pic_wrap">
                  <img src={item.slide} className="lazy img-fluid" alt="" />
                </div>
              </div>
            </Link>
          </CustomSlide>)
        })}










      </Slider>
    </div>
  );

}
export default XNotableDrops;
