import React, { useContext, useEffect, useState } from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Pagination from "../components/Pagination";
import { ethers } from "ethers";
import Moment from 'react-moment';
import { getProfilesRanking } from '../../api/api';
// import { Link, navigate } from '@reach/router';
import { useNavigate, useParams,Link } from 'react-router-dom';

import {
  ThreeBounce
} from 'better-react-spinkit'
const GlobalStyles = createGlobalStyle`
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: block !important;
}
.navbar .mainside a{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
.item-dropdown{
  .dropdown{
    a{
      &:hover{
        background: #8364e2;
      }
    }
  }
}
.btn-main{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
p.lead{
  color: #a2a2a2;
}
.navbar .navbar-item .lines{
  border-bottom: 2px solid #8364e2;
}
.jumbotron.no-bg{
  height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
#tsparticles{
  top: 0;
}
.text-uppercase.color{
  color: #8364e2;
}
.de_count h3 {
  font-size: 36px;
  margin-bottom: 0px;
}
.de_count h5{
  font-size: 14px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
}
.box-url{
  text-align: center;
  h4{
    font-size: 16px;
  }
}
.de_countdown{
  border: solid 2px #8364e2;
}
.author_list_pp, .author_list_pp i, 
.nft_coll_pp i, .feature-box.style-3 i, 
footer.footer-light #form_subscribe #btn-subscribe i, 
#scroll-to-top div{
  background: #8364e2;
}
footer.footer-light .subfooter .social-icons span i{
  background: #403f83;
}
.author_list_pp:hover img{
  box-shadow: 0px 0px 0px 2px #8364e2;
}
.nft__item_action span{
  color: #8364e2;
}
.feature-box.style-3 i.wm{
  color: rgba(131,100,226, .1);
}
@media only screen and (max-width: 1199px) {
  .navbar{
    
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
  }
}
.profileBtn{
  width:150px;
  text-align: center;
  margin-top:5px;
}
.fsize32{
  font-size: 42px;
}
.text-left{
  text-align: left;
}
.exemplar_avatar {
  display: flex;
}
`;

const RankingProfiles = function () {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenu4, setOpenMenu4] = React.useState(false);
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    setOpenMenu2(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
    setOpenMenu2(false);
    setOpenMenu(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.add("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.remove("active");
  };
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.add("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.remove("active");
  };
  const handleBtnClick3 = () => {
    setOpenMenu3(!openMenu3);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu2(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.add("active");
  };
  const handleBtnClick4 = () => {
    setOpenMenu4(!openMenu4);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    setOpenMenu2(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.add("active");
    document.getElementById("like").classList.remove("active");
  };
  ///////////////
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [items, setItems] = useState([]);
  const getItems = async () => {
    setLoading(true)
    let res = await getProfilesRanking(currentPage);
    setItems(res.data);
    setTotalRecords(res.totalRecords)
    setLoading(false)
  }


  useEffect(() => {
    getItems();

  }, [currentPage])
  const onPageChange = async (page) => {
    setCurrentPage(page)
  }
  ///////////////
  const shortener = (txt) => {
    return txt.toString().substring(0, 8) + "..." + txt.toString().substring(txt.toString().length - 6)
  }
  const amountFormatter = (amount) => {
    if (!amount) {
      return "0.0"
    }
    return ethers.utils.formatEther(amount)
  }
  const gotoProfile = (wp)=>{
		navigate("/profile/"+wp)
	  }
  return (
    <div>
      <GlobalStyles />

      <section className=' breadcumb no-bg'>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>Top Profiles</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container no-top'>

        <div className='container'>
          <div className="spacer-double"></div>
          {!loading &&<table className="table de-table table-rank">
            <thead>
              <tr>
                <th scope="col">RANK</th>
                <th scope="col">PROFILE</th>
                <th scope="col">NAME</th>
                <th scope="col">AUTOGRAPHS TOTAL</th>
                <th scope="col">AUTOGRAPHS REQUESTERS</th>
                <th scope="col">AUTOGRAPHS VOLUME</th>
              </tr>
              <tr></tr>
            </thead>
            <tbody>
            {items && items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="fontsize18 bolder color-grey paddingTop" >
                        #{(currentPage-1) * 10 + (index+1)}
                    </td>
                    <td>
                    <div className="author_list_ppx ">
											<span onClick={() => gotoProfile(item.wp)}>
												<img className="lazy" src={item.avatar} alt="" />
												{item.isOfficial && <i className="fa fa-check"></i>}
											</span>
										</div>
                    </td>
                    <td className="ffontsize16 bolder color-grey paddingTop">
                    <Link className="color axnoline" to={"/profile/" + item.wp }>
                                                <span className="bolder color fontsize14">{item.fullName}</span>
                                            </Link>
                        </td>
                    <td className="fontsize16 bolder color-grey paddingTop">{item.autographsCount}</td>
                    <td className="fontsize16 bolder color-grey paddingTop">{item.autographsOwners}</td>
                    <td className="fontsize16 bolder color-grey paddingTop">{item.autographsVolume} GLMR</td>
                  </tr>
                )
              })}


            </tbody>
          </table>}

        </div>
        {loading && <center><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt"></span></center>}
        {
          !loading && items && items.length === 0 && <div className="text-center">
            No results found.
          </div>
        }
      </section>
      <section className="container no-top">
        <div ><Pagination onPageChange={onPageChange} _currentPage={currentPage} recordsPerPage={10} pageNumberLimit={5} totalRecords={totalRecords} /></div>
      </section>
      <Footer />
    </div>

  );
}

export default RankingProfiles;