import React, { useState,useEffect } from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Pagination = ({onPageChange,totalRecords,recordsPerPage,pageNumberLimit,_currentPage}) => {
 
  const [currentPage, setCurrentPage] = useState(_currentPage);
  useEffect(() => {
    console.log("currentPage: ",currentPage)
    onPageChange(currentPage)
  },[currentPage])

  const [maxPageLimit, setMaxPageLimit] = useState(pageNumberLimit);
  const [minPageLimit, setMinPageLimit] = useState(0);

  const totalPages = Math.ceil (totalRecords / recordsPerPage ) ;
  const handlePrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage(prev => prev - 1);
    // props.onPrevClick();
  }
  const handleNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage(prev => prev + 1);
    // props.onNextClick();
  }
  const handlePageClick = (page) => {
    setCurrentPage(page);
      
  }
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }
  const pageNumbers = pages.map(page => {
    if (page <= maxPageLimit && page > minPageLimit) {
      return (
        <li key={page} id={page} onClick={()=>handlePageClick(page)}
          className={currentPage === page ? 'active ' : null}>
          <span className={currentPage === page ? 'fa bolder no-cursor mwidth' : 'fa bolder mwidth'}>{page}</span>
        </li>
      );
    } else {
      return null;
    }
  }

  );
  // page ellipses
  let pageIncrementEllipses = null;
  if (pages.length > maxPageLimit) {
    pageIncrementEllipses = <li onClick={handleNextClick}>&hellip;</li>
  }
  let pageDecremenEllipses = null;
  if (minPageLimit >= 1) {
    pageDecremenEllipses = <li onClick={handlePrevClick}>&hellip;</li>
  }

  return (
    <div>
      <ul className="pagination">
        {/* <li className={currentPage === pages[0] ? 'xvisibleNo' : 'xvisibleYes'}>
          <span className='a fa fa-arrow-left' onClick={handlePrevClick} ></span>
        </li> */}
        {(currentPage !== pages[0]) && <li >
          <span className='fa fa-arrow-left' onClick={handlePrevClick} ></span>
        </li>}
        {(currentPage === pages[0]) && <li className='color-grey'>
          <span className=' fa fa-arrow-left  no-cursor mwidth redi'  >&nbsp;</span>
        </li>}
        {/* {pageDecremenEllipses} */}
        {pageNumbers}
        {/* {pageIncrementEllipses} */}
        {/* <li className={currentPage === pages[pages.length - 1] ? 'xvisibleNo' : 'xvisibleYes'}>
          <span className='a fa fa-arrow-right' onClick={handleNextClick} ></span>
        </li> */}
        {(currentPage !== pages[pages.length - 1])&&<li >
          <span className='fa fa-arrow-right mwidth' onClick={handleNextClick} ></span>
        </li>}
        {(currentPage === pages[pages.length - 1])&&<li className='color-grey'>
          <span className='fa fa-arrow-right  no-cursor mwidth redi'  >&nbsp;</span>
        </li>}
      </ul>
    </div>
  )
}
//   <ul className="pagination">
//   <li><span className='a'>Prev</span></li>
//   <li className="active"><span className='a'>{currentPage}</span></li>
//   <li><span className='a'>2</span></li>
//   <li><span className='a'>3</span></li>
//   <li><span className='a'>4</span></li>
//   <li><span className='a'>5</span></li>
//   <li><span className='a' onClick={()=>onPage(1)}>Next</span></li>
// </ul>

export default Pagination;