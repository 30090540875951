import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import ColumnNew from '../components/ColumnNew';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { searchLucentNFTs } from '../../api/api';
import ColumnLucents from '../components/ColumnLucents';
import Pagination from "../components/Pagination";
import {
  ThreeBounce
} from 'better-react-spinkit'
const GlobalStyles = createGlobalStyle`
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: block !important;
}
.navbar .mainside a{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
.item-dropdown{
  .dropdown{
    a{
      &:hover{
        background: #8364e2;
      }
    }
  }
}
.btn-main{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
p.lead{
  color: #a2a2a2;
}
.navbar .navbar-item .lines{
  border-bottom: 2px solid #8364e2;
}
.jumbotron.no-bg{
  height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
#tsparticles{
  top: 0;
}
.text-uppercase.color{
  color: #8364e2;
}
.de_count h3 {
  font-size: 36px;
  margin-bottom: 0px;
}
.de_count h5{
  font-size: 14px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
}
.box-url{
  text-align: center;
  h4{
    font-size: 16px;
  }
}
.de_countdown{
  border: solid 2px #8364e2;
}
.author_list_pp, .author_list_pp i, 
.nft_coll_pp i, .feature-box.style-3 i, 
footer.footer-light #form_subscribe #btn-subscribe i, 
#scroll-to-top div{
  background: #8364e2;
}
footer.footer-light .subfooter .social-icons span i{
  background: #403f83;
}
.author_list_pp:hover img{
  box-shadow: 0px 0px 0px 2px #8364e2;
}
.nft__item_action span{
  color: #8364e2;
}
.feature-box.style-3 i.wm{
  color: rgba(131,100,226, .1);
}
@media only screen and (max-width: 1199px) {
  .navbar{
    
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
  }
}
.profileBtn{
  width:150px;
  text-align: center;
  margin-top:5px;
}
.fsize32{
  font-size: 42px;
}
.text-left{
  text-align: left;
}
`;
const customStyles = {
  option: (base, state) => ({
    ...base,
    background: "#212428",
    color: "#fff",
    // fontSize:"12px",
    borderRadius: state.isFocused ? "0" : 0,
    "&:hover": {
      background: "#16181b",
    }
  }),
  menu: base => ({
    ...base,
    background: "#212428 !important",

    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    padding: 0
  }),
  control: (base, state) => ({
    ...base,
    padding: 2
  })
};


const options = [
  { value: 'All categories', label: 'All categories' },
  { value: 'Art', label: 'Art' },
  { value: 'Music', label: 'Music' },
  { value: 'Domain Names', label: 'Domain Names' }
]
const options1 = [
  { value: '0', label: 'All Lucents' },
  { value: '1', label: 'On Sale' },
  // { value: 'Has Offers', label: 'Has Offers' }
]
const options2 = [
  { value: '0', label: 'Sort By Mint Date' },
  { value: '1', label: 'Sort By On Sale Date' },
  { value: '2', label: 'Sort By Autographs' },
  // { value: '2', label: 'Price High To Low' },
  // { value: '3', label: 'Price Low To High' },
]


const Explore = () => {
  const [loading, setLoading] = useState(false);
  const [Keyword, setKeyword] = useState("");
  const [SearchKeyword, setSearchKeyword] = useState("");
  const [S1, setS1] = useState("0");
  const [S2, setS2] = useState("0");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [items, setItems] = useState([]);
  const getLucents = async () => {
    setLoading(true)
    let res = await searchLucentNFTs(currentPage,SearchKeyword,S1,S2);
    setItems(res.data);
    setTotalRecords(res.totalRecords)
    setLoading(false)
    // console.log("res.data: ",JSON.stringify(res))
  }

  useEffect(() => {
    getLucents();

  }, []);
  useEffect(() => {
    getLucents();
  }, [currentPage,SearchKeyword,S1,S2])
  const onPageChange = async (page) => {
    // console.log("onPageChange: ", page)
    setCurrentPage(page)
  }
  let zID;
  const handleKeywordChange = async (e) => {
    clearTimeout(zID)
    zID = setTimeout(() => {
      setKeyword(e.target.value)
      setSearchKeyword(e.target.value);
      setCurrentPage(1)


    }, 200);
  }
  const handleS1Change = (e) => {
    console.log("handleS1Change: ", e.value)
    setS1(e.value)
    setCurrentPage(1)
  };
  const handleS2Change = (e) => {
    console.log("handleS2Change: ", e.value)
    setS2(e.value)
    setCurrentPage(1)
  };
  return (<div>
    <GlobalStyles />
    <section className=' breadcumb no-bg '>
      <div className='mainbreadcumb'>
        <div className='container'>
          <div className='row m-10-hor'>
            <div className='col-12 text-center'>
              <h1 className='text-center'>Explore</h1>
              <div className="spacer-single"></div>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className="items_filter">
                    <form className="row form-dark" id="form_quick_searchx" name="form_quick_searchx">
                      <div className="col">
                        <input className="form-control" id="name_1" name="name_1" placeholder="Search..." type="text" onChange={handleKeywordChange} />
                        {/* <button id="btn-submit"><i className="fa fa-search bg-color-secondary"></i></button> */}
                        <div className="clearfix"></div>
                      </div>
                    </form>
                    {/* <div className='dropdownSelect one '><Select className='select1 text-left' styles={customStyles} menuContainerStyle={{ 'zIndex': 999 }} defaultValue={options[0]} options={options} /></div> */}
                    <div className='dropdownSelect two'><Select className='select1 text-left' styles={customStyles} defaultValue={options1[0]} options={options1} onChange={handleS1Change}/></div>
                    <div className='dropdownSelect three'><Select className='select1 text-left' styles={customStyles} defaultValue={options2[0]} options={options2} onChange={handleS2Change}/></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='no-top '>
    <div className="spacer-double"></div>
      <div className='container '>
        {/* <ColumnNew /> */}
        {!loading && <ColumnLucents dx={items || []} />
        }
        {loading && <center><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt"></span></center>}
        {
          !loading && items && items.length === 0 && <div className="text-center">
            No results found.
          </div>
        }





        <div className="spacer-single"></div>
        <Pagination onPageChange={onPageChange} _currentPage={currentPage} recordsPerPage={20} pageNumberLimit={5} totalRecords={totalRecords} />
      </div>
    </section>


    <Footer />
  </div>);
};
export default Explore;