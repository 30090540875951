import { useState, useEffect } from "react";
import { useTimer } from 'use-timer';
import { checkNewNoty, checkNewNotyX } from "../api/api";
 
const useNoty = (xinterval) => {
  const [NotyCount, setNotyCount] = useState(0);
  const { time, start, pause, reset, status } = useTimer();
 
  
  useEffect(() => {
    start();
    return () => {
      reset()
    }
  }, [])
 
  const checkNotyH = async () => {
     const res = await checkNewNoty();
     setNotyCount(res.notyCount);
     if(res.NotyCount>0 && window.location.test('notifications')){
      setNotyCount(0);
      checkNewNotyX();
     }
  }
  
  useEffect(() => {
    if(time%xinterval===0){
      console.log("checkNoty timer hook status: ", status)
      let wp = localStorage.getItem('wp')
      if(wp){
        checkNotyH();
      }
    }
  }, [time])

  return {NotyCount,checkNotyH};
};

export default useNoty;
