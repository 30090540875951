import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useTimer } from 'use-timer';
import useMarketplaceContract from "./useMarketplaceContract";
import useSignerContract from "./useSignerContract";
import useDepositorContract from "./useDepositorContract";
const useRoyalty = (xinterval) => {
  const { time, start, pause, reset, status } = useTimer();
  const [depositorContract] = useDepositorContract()
  const [marketplaceContract] = useMarketplaceContract()
  const [signerContract] = useSignerContract()

  const [N1NewRoyalty, setN1NewRoyalty] = useState(ethers.BigNumber.from(0));
  const [N2NewRoyalty, setN2NewRoyalty] = useState(ethers.BigNumber.from(0));
  const [N3NewRoyalty, setN3NewRoyalty] = useState(ethers.BigNumber.from(0));
  const [NewRoyalty, setNewRoyalty] = useState(ethers.BigNumber.from(0));


  const [TotalN1NewRoyalty, setTotalN1NewRoyalty] = useState(ethers.BigNumber.from(0));
  const [TotalN2NewRoyalty, setTotalN2NewRoyalty] = useState(ethers.BigNumber.from(0));
  const [TotalN3NewRoyalty, setTotalN3NewRoyalty] = useState(ethers.BigNumber.from(0));
  const [TotalNewRoyalty, setTotalNewRoyalty] = useState(ethers.BigNumber.from(0));

  useEffect(() => {
    start();
    return () => {
      reset()
    }
  }, [])
  const checkBalances1 = async () => {
    let wp = localStorage.getItem('wp')
    if (depositorContract && wp) {
      let m = await depositorContract.pendingWithdrawals(wp);
      console.log("pendingWithdrawals1: ", m)
      let t = await depositorContract.totalWithdrawals(wp);
      console.log("totalWithdrawals 1: ", t)
      setN1NewRoyalty(m) 
      setTotalN1NewRoyalty(t)
    }
  }
  const checkBalances2 = async () => {
    let wp = localStorage.getItem('wp')
    if (marketplaceContract && wp) {
      let m = await marketplaceContract.pendingWithdrawals(wp);
      console.log("pendingWithdrawals2: ", m)
      let t = await marketplaceContract.totalWithdrawals(wp);
      console.log("totalWithdrawals 2: ", t)
      setN2NewRoyalty(m)
      setTotalN2NewRoyalty(t)
       
    }
  }
  const checkBalances3 = async () => {
    let wp = localStorage.getItem('wp')
    if (signerContract && wp) {
      let m = await signerContract.pendingWithdrawals(wp);
      console.log("pendingWithdrawals3: ", m)
      let t = await signerContract.totalWithdrawals(wp);
      console.log("totalWithdrawals 3: ", t)
      setN3NewRoyalty(m)
      setTotalN3NewRoyalty(t)
       
    }
  }
  useEffect(() => {
    checkBalances1();
  }, [depositorContract])
  useEffect(() => {
    checkBalances2();
  }, [marketplaceContract])
  useEffect(() => {
    checkBalances3();
  }, [signerContract])

  useEffect(() => {
    const nr = N1NewRoyalty.add(N2NewRoyalty).add(N3NewRoyalty);
    setNewRoyalty(nr);
  }, [N1NewRoyalty, N2NewRoyalty, N3NewRoyalty])
  useEffect(() => {
    const nr = TotalN1NewRoyalty.add(TotalN2NewRoyalty).add(TotalN3NewRoyalty);
    setTotalNewRoyalty(nr);
  }, [TotalN1NewRoyalty, TotalN2NewRoyalty, TotalN3NewRoyalty])

  useEffect(() => {
    if(time%xinterval===0){
      console.log("checkBalances timer hook status: ", status)
      checkBalances1();
      checkBalances2();
      checkBalances3();
    }
  }, [time])

  return {NewRoyalty,N1NewRoyalty, N2NewRoyalty, N3NewRoyalty,TotalNewRoyalty};
};

export default useRoyalty;
