import React, { useContext, useEffect, useState } from "react";
import ColumnLucents from '../components/ColumnLucents';
import ColumnZero from '../components/ColumnZero';
import ColumnZeroTwo from '../components/ColumnZeroTwo';
import ColumnZeroThree from '../components/ColumnZeroThree';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import SliderCarousel from '../components/SliderCarouselsingleProfile';
import FeatureBox from '../components/FeatureBox';
import CarouselCollection from '../components/CarouselCollection';
import ColumnNew from '../components/ColumnNew';
import AuthorList from '../components/authorList';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { getProfileNFTs } from "../../api/api";
// import { navigate, useParams } from "@reach/router";
// import { Link } from "@reach/router";
import { useNavigate, useParams, Link } from 'react-router-dom';
import Pagination from "../components/Pagination";
import Swal from 'sweetalert2'
import '@sweetalert2/theme-dark/dark.scss';
// import '@sweetalert2/themes/dark/dark.scss';
import { AuthContext } from "../../context/AuthContext";
import ColumnExemplars from "../components/ColumnExemplars";
import { Store } from 'react-notifications-component';
import {
  ThreeBounce
} from 'better-react-spinkit'
import {
  TelegramShareButton,
  TwitterShareButton,
  TwitterIcon,
  TelegramIcon
} from "react-share";
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const GlobalStyles = createGlobalStyle`
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: block !important;
}
.navbar .mainside a{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
.item-dropdown{
  .dropdown{
    a{
      &:hover{
        background: #8364e2;
      }
    }
  }
}
.btn-main{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
p.lead{
  color: #a2a2a2;
}
.navbar .navbar-item .lines{
  border-bottom: 2px solid #8364e2;
}
.jumbotron.no-bg{
  height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
#tsparticles{
  top: 0;
}
.text-uppercase.color{
  color: #8364e2;
}
.de_count h3 {
  font-size: 36px;
  margin-bottom: 0px;
}
.de_count h5{
  font-size: 14px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
}
.box-url{
  text-align: center;
  h4{
    font-size: 16px;
  }
}
.de_countdown{
  border: solid 2px #8364e2;
}
.author_list_pp, .author_list_pp i, 
.nft_coll_pp i, .feature-box.style-3 i, 
footer.footer-light #form_subscribe #btn-subscribe i, 
#scroll-to-top div{
  background: #8364e2;
}
footer.footer-light .subfooter .social-icons span i{
  background: #403f83;
}
.author_list_pp:hover img{
  box-shadow: 0px 0px 0px 2px #8364e2;
}
.nft__item_action span{
  color: #8364e2;
}
.feature-box.style-3 i.wm{
  color: rgba(131,100,226, .1);
}
@media only screen and (max-width: 1199px) {
  .navbar{
    
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
  }
}
.profileBtn{
  width:150px;
  text-align: center;
  margin-top:5px;
}
.fsize32{
  font-size: 42px;
}
`;

const Colection = function () {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const params = useParams();
  let navigate = useNavigate();

  const [CurrentTab, setCurrentTab] = useState(1);
  const [loading, setLoading] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    setOpenMenu2(false);
    document.getElementById("Mainbtn").classList.add("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
    setOpenMenu2(false);
    setOpenMenu(false);
    document.getElementById("Mainbtn1").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
  };
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2);
    setOpenMenu(false);
    setOpenMenu1(false);
    document.getElementById("Mainbtn2").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
  };

  const [wp, setWP] = useState(false);
  const [dx, setDX] = useState([]);

  const [ProfileData, setProfileData] = useState();

  const checkNFTs = async () => {
    setLoading(true)
    let profileNFTs = await getProfileNFTs(params.id, currentPage, CurrentTab);
    setDX(profileNFTs.data);
    setTotalRecords(profileNFTs.totalRecords)
    setLoading(false)
  }
  const me = async (wp) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token')
      },

    };
    const req = await fetch(`https://lucentapi.lucentnfts.com/user/profile/` + params.id, requestOptions);
    const newData = await req.json();

    console.log("me: ", newData.data.wp)
    setWP(newData.data.wp)
    setProfileData(newData.data)
    // checkNFTs();
  };
  useEffect(() => {
    me();

  }, [])
  useEffect(() => {
    checkNFTs()

  }, [currentPage, CurrentTab])
  const onPageChange = async (page) => {

    // console.log("onPageChange: ", page)
    setCurrentPage(page)


  }



  const onAutograph = () => {
    if (userInfo && userInfo.isAuth) {
      navigate("/request/autograph/" + params.id)
    } else {
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Please Connect A Wallet!',
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    }

  };

  const goC = (i) => {
    setCurrentPage(1)

    const xbtn1 = document.getElementById('xbtn1');
    const xbtn2 = document.getElementById('xbtn2');
    const xbtn3 = document.getElementById('xbtn3');
    const xbtn4 = document.getElementById('xbtn4');

    xbtn1.classList.remove("color-black_more");
    xbtn2.classList.remove("color-black_more");
    xbtn3.classList.remove("color-black_more");
    xbtn4.classList.remove("color-black_more");

    xbtn1.classList.remove("xbtn");
    xbtn2.classList.remove("xbtn");
    xbtn3.classList.remove("xbtn");
    xbtn4.classList.remove("xbtn");
    switch (i) {
      case 1:
        xbtn1.classList.add("color-black_more");

        xbtn2.classList.add("xbtn");
        xbtn3.classList.add("xbtn");
        xbtn4.classList.add("xbtn");

        setCurrentTab(1)
        break;
      case 2:
        xbtn2.classList.add("color-black_more");
        xbtn1.classList.add("xbtn");

        xbtn3.classList.add("xbtn");
        xbtn4.classList.add("xbtn");

        setCurrentTab(2)
        break;
      case 3:
        xbtn3.classList.add("color-black_more");
        xbtn1.classList.add("xbtn");
        xbtn2.classList.add("xbtn");

        xbtn4.classList.add("xbtn");

        setCurrentTab(3)
        break;
      case 4:
        xbtn4.classList.add("color-black_more");
        xbtn1.classList.add("xbtn");
        xbtn2.classList.add("xbtn");
        xbtn3.classList.add("xbtn");

        setCurrentTab(4)
        break;
    }
  }
  function MyNotification() {
    return (
      <div style={{
        display: 'flex',
        backgroundColor: '#0f2f26',
        borderRadius: 5,
      }}>
        {/* <AlligatorAvatar/> */}
        <div>
          <h4>Alligator.io</h4>
          <p>Has joined the chat</p>
        </div>
      </div>
    )
  }
  const xcopy = () => {
    console.log("xcopy")
    navigator.clipboard.writeText(ProfileData.wp)
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        // toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: 'Address Copied!'
    })

    // Swal.fire({
    //   position: 'center',
    //   icon: 'success',
    //   title: 'Address Copied!',
    //   showConfirmButton: false,
    //   timer: 1500
    // })

    // Store.addNotification({
    //   // content: (
    //   //   <div className="notification-custom-success">
    //   //     <div className="notification-custom-icon">
    //   //       <i className="fa fa-check" />
    //   //     </div>
    //   //     <div className="notification-custom-content">
    //   //       <p className="notification-message">GitHub is awesome!</p>
    //   //     </div>
    //   //   </div>
    //   // ),
    //   title: "Wonderful!",
    //   message: "teodosii@react-notifications-component",
    //   type: "awesome",
    //   insert: "top",
    //   container: "top-right",
    //   // animationIn: ["animate__animated", "animate__fadeIn"],
    //   // animationOut: ["animate__animated", "animate__fadeOut"],
    //   animationIn: ["animated", "fadeIn"],  
    //   animationOut: ["animated", "fadeOut"],
    //   dismiss: {
    //     duration: 5000,
    //     // onScreen: true
    //   }
    // });
  }
const share_url =`https://link.lucentnfts.com/share/profile/${params.id}`
const share_title = `Check out this profile on LucentNFTs!`;
  return (
    <div>
      <GlobalStyles />
      <section className="xbg no-bg" style={
        {
          // backgroundImage:  `url(${'../img/background/bg1.avif'})`,
          backgroundImage: ProfileData?.cover ? `url(${ProfileData?.cover})` : `url(${''})`,
        }
        // ../img/background/def.webp
        // ../img/background/8.jpg
      }>

        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 px-0 '>
              {ProfileData?.avatar && <SliderCarousel img={(ProfileData?.avatar)} />}
              {!ProfileData?.avatar && <div className="square" ></div>}

              {/* || "../img/carousel/crs-4.jpg" */}
              {/* <div className="spacer-10"></div> */}
                <ul id="share_menu">
                  <li>
                    <TwitterShareButton url={share_url} title={share_title} via="LucentNFTs" hashtags={["NFTCommunity", "LucentNFTs"]}><TwitterIcon size={32} round={true} bgStyle={{ fill: '#685dc3' }} /></TwitterShareButton>
                  </li>
                  <li>
                    <TelegramShareButton url={share_url} title="" ><TelegramIcon size={32} round={true} bgStyle={{ fill: '#685dc3' }} /></TelegramShareButton>
                  </li>
                </ul>
            </div>
            <div className='col-lg-6'>
              {/* <div className="spacer-single"></div> */}
              {/* <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
                <h6 className=""><span className="text-uppercase color">Gigaland Market</span></h6>
                </Reveal> */}
              <div className="spacer-10"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
                <h1 className="bluran">{(ProfileData && ProfileData.fullName && ProfileData.fullName)}</h1>
              </Reveal>

              <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
                <>
                  {ProfileData && ProfileData.description && <p className="fontsize18  col-white bluran">
                    {ProfileData.description}

                  </p>}
                </>

                {/* <div>
                  <span id="wallet" className=" bolder   col-white">@lindaa</span>
                  <button id="btn_copy" title="Copy" className="margin-left"><i className="fa fa-share-alt "></i></button>
                </div> */}
                <div>
                  <span id="wallet" className=" bolder   col-white bluran">{wp && ("" + wp.toString().substring(0, 6) + "..." + wp.toString().substring(wp.toString().length - 4))}</span>
                  <button id="btn_copy" title="Copy" className="margin-left color-transparent bluran" onClick={xcopy}><i className="fa fa-copy "></i></button>
                </div>

              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
                <>
                  {
                    (ProfileData?.social?.Link1 ||
                      ProfileData?.social?.Link2 ||
                      ProfileData?.social?.Link3 ||
                      ProfileData?.social?.Link4
                    ) &&

                    <div className="bluran">
                      {ProfileData?.social?.Link1 && <button className="btn-main3 "><i className="fa fa-twitter "></i> <a className="axnoline col-white" target="_black" href={ProfileData?.social?.Link1}>Twitter</a></button>}
                      {ProfileData?.social?.Link2 && <button className="btn-main3 "><i className="fa fa-instagram "></i> <a className="axnoline col-white" target="_black" href={ProfileData?.social?.Link2}>Instagram</a></button>}
                      {ProfileData?.social?.Link3 && <button className="btn-main3 "><i className="fa fa-youtube "></i> <a className="axnoline col-white" target="_black" href={ProfileData?.social?.Link3}>Youtube</a></button>}
                      {ProfileData?.social?.Link4 && <button className="btn-main3 "><i className="fa fa-globe "></i> <a className="axnoline col-white" target="_black" href={ProfileData?.social?.Link4}>Web</a></button>}

                    </div>}
                </>
              </Reveal>

              <div className="spacer-single"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={1000} duration={900} triggerOnce>
                <span onClick={onAutograph} className="btn-main marginTop20 "> <span className="bolder fontsize12"></span>&nbsp;<span className="">Autograph</span></span>
                {/* <div className="mb-sm-30"></div> */}
               
                <div className="mb-sm-30"></div>
              </Reveal>
            </div>



          </div>

        </div>

      </section>

      {/* <div className="spacer-signle"></div> */}
      <section className='breadcumb2   '>
        <div className=''>
          <div className=''>


          </div>

        </div>
        <div >
          {/* <div className="col-lg-12 ">
            
            <ul className="activity-filter ">
              <li id='sale' className="filter_by_sales"  ><i className="fa fa-star"></i>Lucent NFTs</li>
              <li id='like' className="filter_by_likes"  ><i className="fa fa-tag"></i>Owned NFTs</li>
              <li id='like' className="filter_by_likes"  ><i className="fa fa-heart"></i>Created NFTs</li>
              <li id='like' className="filter_by_likes"  ><i className="fa fa-pencil"></i>Signed NFTs</li>
              
            </ul>
          </div> */}
          {/* offset-2 */}
          <div className='row '>
            <div className='col-md-12 '>
              <div className=" activity-filter">
                <div className='spacer-single'></div>


                <ul className="de_nav ">
                  {/* <li id='Mainbtn' className="active"><span onClick={handleBtnClick} className="profileBtn">Lucent NFTs</span></li>
                    <li id='Mainbtn2' className=""><span onClick={handleBtnClick2} className="profileBtn">Owned NFTs</span></li>
                    <li id='Mainbtn4' className=""><span onClick={handleBtnClick2} className="profileBtn">Created NFTs</span></li> */}
                  <li id='xbtn1' onClick={() => { goC(1) }} className="color-black_more">
                    {/* <i className="fa fa-star "></i> */}
                    Lucent NFTs</li>
                  <li id='xbtn2' onClick={() => { goC(2) }} className="xbtn">
                    {/* <i className="fa fa-tag"></i> */}
                    Owned NFTs</li>
                  <li id='xbtn3' onClick={() => { goC(3) }} className="xbtn">
                    {/* <i className="fa fa-heart"></i> */}
                    Created NFTs</li>
                  <li id='xbtn4' onClick={() => { goC(4) }} className="xbtn">
                    {/* <i className="fa fa-pencil"></i> */}
                    Signed NFTs</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className='spacer-10'></div> */}
          <div className='spacer-single'></div>

        </div>
        {ProfileData && ProfileData.isOfficial && <div className="text-center color-grey fontsize10 bolder divion borderTop">
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE
          OFFICIAL PROFILE



        </div>}

      </section>
      <section className='container no-top '>

        <div className='spacer-single'></div>
        {loading && <center><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt"></span></center>}
        {!loading && <div>
          {(CurrentTab === 1 || CurrentTab === 4) && (
            <div id='zero1' className='onStep fadeIn'>
              <ColumnLucents dx={dx || []} />
            </div>
          )}
          {(CurrentTab === 2 || CurrentTab === 3) && (
            <div id='zero1' className='onStep fadeIn'>
              <ColumnExemplars dx={dx || []} />
            </div>
          )}

          {
            !loading && dx && dx.length === 0 && <div className="text-center">
              No results found.
            </div>
          }






          <div className='spacer-single'></div>
          <Pagination onPageChange={onPageChange} _currentPage={currentPage} recordsPerPage={20} pageNumberLimit={5} totalRecords={totalRecords} />
        </div>}


      </section>


      <Footer />
    </div>
  );
}
export default Colection;