import { useState, useEffect } from "react";
import { AutographABI, AutographAddress } from "../contracts/autographABI";
import { ethers } from "ethers";
const useAutographContract = () => {
  let { ethereum } = window;
  const [AutographContract, setAutographContract] = useState(null);
 
  useEffect(() => {
    if(!ethereum)return;
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    setAutographContract(new ethers.Contract(AutographAddress, AutographABI, signer));
}, [ethereum]);

  return [AutographContract];
};

export default useAutographContract;
