import React, { useState, useEffect, useRef } from "react";
import Clock from "../components/Clock";
import Select from 'react-select'
import Footer from '../components/footer';
import Moment from 'react-moment';
import { createGlobalStyle } from 'styled-components';
// import { Link } from "@reach/router";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ethers } from "ethers";
import { userTX, getUserNFTs, getLucentMintingPrice, getSig } from "../../api/api";
import useDepositorContract from "../../hooks/useDepositorContract";
import { useTimer } from 'use-timer';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  ThreeBounce
} from 'better-react-spinkit'
import Pagination from "../components/Pagination";
import useRoyalty from "../../hooks/useRoyalty";
import Swal from 'sweetalert2'
import '@sweetalert2/theme-dark/dark.scss';
// import '@sweetalert2/themes/dark/dark.scss';
const customStyles = {
  option: (base, state) => ({
    ...base,
    background: "#212428",
    color: "#fff",
    borderRadius: state.isFocused ? "0" : 0,
    "&:hover": {
      background: "#16181b",
    }
  }),
  menu: base => ({
    ...base,
    background: "#212428 !important",
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    padding: 0
  }),
  control: (base, state) => ({
    ...base,
    padding: 2
  })
};

const GlobalStyles = createGlobalStyle`
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: block !important;
}
.navbar .mainside a{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
.item-dropdown{
  .dropdown{
    a{
      &:hover{
        background: #8364e2;
      }
    }
  }
}
.btn-main{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
p.lead{
  color: #a2a2a2;
}
.navbar .navbar-item .lines{
  border-bottom: 2px solid #8364e2;
}
.jumbotron.no-bg{
  height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
#tsparticles{
  top: 0;
}
.text-uppercase.color{
  color: #8364e2;
}
.de_count h3 {
  font-size: 36px;
  margin-bottom: 0px;
}
.de_count h5{
  font-size: 14px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
}
.box-url{
  text-align: center;
  h4{
    font-size: 16px;
  }
}
.de_countdown{
  border: solid 2px #8364e2;
}
.author_list_pp, .author_list_pp i, 
.nft_coll_pp i, .feature-box.style-3 i, 
footer.footer-light #form_subscribe #btn-subscribe i, 
#scroll-to-top div{
  background: #8364e2;
}
footer.footer-light .subfooter .social-icons span i{
  background: #403f83;
}
.author_list_pp:hover img{
  box-shadow: 0px 0px 0px 2px #8364e2;
}
.nft__item_action span{
  color: #8364e2;
}
.feature-box.style-3 i.wm{
  color: rgba(131,100,226, .1);
}
@media only screen and (max-width: 1199px) {
  .navbar{
    
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
  }
}
.profileBtn{
  width:150px;
  text-align: center;
  margin-top:5px;
}
.fsize32{
  font-size: 42px;
}
`;
const options1 = [
  { value: 'Ethereum', label: 'Ethereum' },
  { value: 'Polygon', label: 'Polygon' },
]

const Createpage = () => {
  const [chain, setChain] = useState('');
  const [contract, setContract] = useState('');
  const [token, setToken] = useState('');

  const [image_source, setImgSource] = useState('');
  const [title, setTitle] = useState('');

  const [ErrorMSG, setErrorMSG] = useState('');

  const [userNFTs, setUserNFTs] = useState([]);

  const [isChecking, setIsChecking] = useState(false);
  const [showTXMSG, setShowTXMSG] = useState(false);

  const [LucentMintingPrice, setLucentMintingPrice] = useState('0');
  const [LucentMintingPriceFormatted, setLucentMintingPriceFormatted] = useState('');


  // const { NewRoyalty, N1NewRoyalty, N2NewRoyalty, N3NewRoyalty } =  useRoyalty();
  // useEffect(() => {
  //   console.log("NewRoyalty2: ",NewRoyalty)
  // },[N1NewRoyalty])


  ////const pageNumberLimit = 5;
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  ////



  const [depositorContract] = useDepositorContract()

  const handleChainChange = (e) => {
    console.log("handleChainChange: ", e.value)
    setChain(e.value);
  };

  const handleContractChange = (e) => {
    // this.setState({email: e.target.value});
    console.log("handleContractChange: ", e.target.value)
    setContract(e.target.value)
  }
  const handleTokenChange = (e) => {
    // this.setState({email: e.target.value});
    console.log("handleTokenChange: ", e.target.value)
    setToken(e.target.value)
  }

  const depositorTransfer = async () => {
    console.log("depositorTransfer");
    const ToastPender = Swal.mixin({
			toast: true,
			position: 'center-center',
			showConfirmButton: false,
			// timer: 3000,
			timerProgressBar: false,
			// didOpen: (toast) => {
			//   toast.addEventListener('mouseenter', Swal.stopTimer)
			//   toast.addEventListener('mouseleave', Swal.resumeTimer)
			// }
		  })
	  
		  ToastPender.fire({
			icon: 'info',
			title: 'Connecting To Wallet...'
		  })
    setShowTXMSG(false)

    // const options = { value: ethers.utils.parseEther("0.01") }
    const options = { value: await depositorContract.minDeposit() }
    console.log("chain: ", chain)
    console.log("contract: ", contract)
    console.log("token: ", token)

    let chainID = 1;
    if (chain === 'Polygon') {
      chainID = 2;
    }
    let xr = await getSig(chain, contract, token);
    let sig = xr.data.sig;



    try {
      const tx = await depositorContract.deposit(chainID, contract, token, sig, options);
      ToastPender.close();
      // console.log("tx: ", tx)
      console.log("tx: ", tx.hash)
      const resp = await userTX({
        txHash: tx.hash,
        type: "DEPOSIT_FOR_MINT",
        title,
        img: image_source
      })
      console.log("resp: ", resp)

      const Toast = Swal.mixin({
        toast: true,
        position: 'center-center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          // toast.addEventListener('mouseenter', Swal.stopTimer)
          // toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
  
      Toast.fire({
        icon: 'success',
        title: 'Transaction Submitted!'
      })
      setShowTXMSG(true)
      setCurrentPage(1)
    } catch (error) {
      ToastPender.close();
      // console.log(JSON.stringify(error))
      // console.log('e error.code: ', error.code)
      // console.log('error.data.message: ', error.data.message)
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }

  }
  const check = async () => {
    console.log("chain: ", chain)
    console.log("contract: ", contract)
    console.log("token: ", token)

    if (chain === "" || contract.trim() === "" || token.trim() === "") {
      //todo alert
      return;
    }



    setIsChecking(true)
    setShowTXMSG(false)
    setTitle('');
    setErrorMSG('')
    //     const requestOptions = {
    //     headers: { 
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer my-token',
    //         'My-Custom-Header': 'foobar'
    //     } 
    // };
    // fetch("https://lucentapi.lucentnfts.com/test?a=1",requestOptions)
    //         .then(res => res.json())
    //         .then(
    //             (data) => {
    //               console.log(data)
    //                 // setIsLoaded(true);
    //                 // setUsers(data);
    //             },
    //             (error) => {
    //                 // setIsLoaded(true);
    //                 // setError(error);
    //             }
    //         );

    ///----------------------------------------------------------------
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
      body: JSON.stringify({
        chain: chain,
        contract: contract,
        token: token
      })
    };
    fetch('https://lucentapi.lucentnfts.com/nft/check', requestOptions)
      .then(response => response.json())
      .then(response => {
        console.log("response: ", response)
        console.log("response s: ", response.status)
        setIsChecking(false)
        if (response.status === 0 && response.code === 0) {
          //todo alert
          console.log("Authorization require.");
          return;
        }

        if (response.status === 1) {
          console.log("response d: ", response.data)
          setTitle(response.data.title)
          setImgSource(response.data.image)
        } else {
          if (response.code === 10) {
            setErrorMSG("Can't obtain NFT data")
          }
          if (response.code === 11) {
            setErrorMSG("Image format of the NFT should be 'PNG', 'JPEG', 'GIF', 'SVG' ")
          }
          else {
            setErrorMSG("Something went wrong, please try again later.")
          }
        }
      });
  }

  const shortener = (txt) => {
    return txt.toString().substring(0, 8) + "..." + txt.toString().substring(txt.toString().length - 6)
  }

  const txStatus = (subStatus) => {
    //todo tx canceled
    switch (subStatus) {
      case 0:
        return "Transaction Sent"
      case 1:
        return "Deposit Received"
      case 2:
        return "Minting"
      case 3:
        return "LUCENT NFT"
    }
  }

  const onPageChange = async (page) => {

    console.log("onPageChange: ", page)
    setCurrentPage(page)


  }
  // useEffect(() => {
  //   window.localStorage.setItem('currentPage', currentPage);
  //   checkNFTs2();
  // },[currentPage])
  const checkNFTs_noloader = async () => {

    let nfts = await getUserNFTs(currentPage);//localStorage.getItem('currentPage')
    setUserNFTs(nfts.data)
    setTotalRecords(nfts.totalRecords)

  }
  const checkNFTs = async () => {
    setLoading(true);
    let nfts = await getUserNFTs(currentPage);//localStorage.getItem('currentPage')
    console.log("currentPage: ", localStorage.getItem('currentPage'))
    setUserNFTs(nfts.data)
    setTotalRecords(nfts.totalRecords)
    setLoading(false);
  }
  useEffect(() => {
    checkNFTs();
    // let iID = setInterval(() => {
    //   checkNFTs2();
    // }, 3000);
    // return () => {
    //   clearInterval(iID)
    // }
  }, [currentPage])

  const getLucentMintingPriceX = async () => {
    const r = await getLucentMintingPrice();
    setLucentMintingPrice(r.data.price)
    setLucentMintingPriceFormatted(ethers.utils.formatEther(r.data.price))
  }

  const { time, start, pause, reset, status } = useTimer();
  useEffect(() => {
    getLucentMintingPriceX()
    start()
    return () => {
      reset()
    }
  }, [])
  useEffect(() => {
    if (time % 5 === 0) {

      checkNFTs_noloader();
    }

  }, [time])

  return (
    <div>
      <GlobalStyles />
      <section className=' breadcumb no-bg'>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>NFT</h1>
                <div className='text-center'>Please fill the fields below for referencing NFTs.<br/>The referenced NFT should be already sold at any NFT marketplace.</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='breadcumb  no-bg'>
        <div className="container">

          <div className="row">
            {/* <div className="spacer-double"></div> */}
            <div className="spacer-single"></div>
            {/* offset-lg-1 */}
            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12  ">
              <form id="form-create-item" className="form-border" action="#">
                <div className="field-set">
                  {/* <h5>Upload file</h5> */}

                  {/* <div className="d-create-file">
                          <p id="file_name">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
                          {this.state.files.map(x => 
                          <p key="{index}">PNG, JPG, GIF, WEBP or MP4. Max 200mb.{x.name}</p>
                          )}
                          <div className='browse'>
                            <input type="button" id="get_file" className="btn-main" value="Browse"/>
                            <input id='upload_file' type="file" multiple onChange={this.onChange} />
                          </div>
                          
                      </div> */}

                  {/* <div className="spacer-double"></div> */}
                  <h5>Blockchain</h5>
                  <div className='dropdownSelect two'>
                    <Select className='select1' styles={customStyles} options={options1} onChange={handleChainChange} />
                  </div>
                  <div className="spacer-single"></div>

                  <h5>Contract Address</h5>
                  <input type="text" name="contract_address" id="contract_address" className="form-control" placeholder="e.g. '0x2953399124f0cbb46d2cbacd8a89cf0599974963'" onChange={(e) => handleContractChange(e)} />
                  <div className="spacer-10"></div>

                  <h5>Token ID</h5>
                  <input type="text" name="token_id" id="token_id" className="form-control" placeholder="e.g. '10771'" onChange={(e) => handleTokenChange(e)} />
                  <div className="spacer-10"></div>
                  {/* <div className="spacer-10"></div> */}

                  {/* <h5>Description</h5>
                      <textarea data-autoresize name="item_desc" id="item_desc" className="form-control" placeholder="e.g. 'This is very limited item'"></textarea> */}

                  {/* <div className="spacer-10"></div> */}

                  {/* <h5>Price</h5>
                      <input type="text" name="item_price" id="item_price" className="form-control" placeholder="enter price for one item (ETH)" />

                      <div className="spacer-10"></div>

                      <h5>Royalties</h5>
                      <input type="text" name="item_royalties" id="item_royalties" className="form-control" placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%" />

                      <div className="spacer-10"></div> */}
                  <div className="flexer">
                    {isChecking && <><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt">Please wait...</span></>}
                  </div>
                  {ErrorMSG !== '' && <div className="fontsize12">{ErrorMSG}
                    <div className="spacer-10"></div>
                  </div>}
                  {!isChecking && <><span id="submit" className="btn-main2 " onClick={check} ><span >CHECK</span></span></>}

                  {/* onClick={()=> {console.log('check')}} */}
                </div>
              </form>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div className="spacer-single"></div>
              {/* <h5>Preview item</h5> */}
              <div className="nft__item2 m-0">
                {/* <div className="de_countdown">
                        <Clock deadline="December, 30, 2021" />
                      </div> */}
                {/* <div className="author_list_pp">
                          <span>                                    
                              <img className="lazy" src="./img/author/author-1.jpg" alt=""/>
                              <i className="fa fa-check"></i>
                          </span>
                      </div> */}
                <div className="nft__item_wrap2">
                  {title && <span>
                    <img src={image_source} key="img_nft" id="get_file_2" className="lazy nft__item_preview" alt="" />
                  </span>}
                </div>
                <div className="nft__item_info">
                  {title && <div className="spacer-single"></div>}
                  <h5 className="text-center ">{title}</h5>
                  {/* {description && <div className="">
                    {description}
                  </div>} */}

                  {title && <div className="text-center">
                    <div className="spacer-10"></div>
                    <span onClick={depositorTransfer} className="btn-main inline lead fontsize12">New Lucent</span>
                    <span className="fontsize12 "> <span >FEE <span className="bolder">{LucentMintingPriceFormatted} GLMR</span> </span></span>

                    {showTXMSG && <div className="fontsize12 textleft">
                      <div className="spacer-single"></div>
                      <span className="fa fa-check"></span><span className="marginlefter5 bolder">Transaction has been submitted successfully! Your Lucent will be minted within 1 minute. The minting progress is shown below.</span>
                    </div>}

                    <div className="spacer-double"></div>
                  </div>
                  }
                  {!title &&
                    <div className="text-center">
                      <div className="spacer-double"></div>
                      <div className="spacer-single"></div>
                      <div className="spacer-10"></div>
                      <div className="fontsize12 ">NFT IMAGE PREVIEW</div>
                      <div className="spacer-double"></div>
                      <div className="spacer-single"></div>
                      <div className="spacer-10"></div>

                      <div className="spacer-10"></div>
                      {/* <div className="spacer-10"></div> */}
                      {/* <div className="spacer-10"></div> */}
                    </div>
                  }

                  {/* <div className="nft__item_price">
                              0.08 ETH<span>1/20</span>
                          </div> */}
                  {/* <div className="nft__item_action">
                              <span>Place a bid</span>
                          </div> */}
                  {/* <div className="nft__item_like">
                              <i className="fa fa-heart"></i><span>50</span>
                          </div>                             */}
                </div>
              </div>
              {/* <div className="spacer-double"></div> */}
            </div>
          </div>
          <div className="spacer-double"></div>
        </div>

      </section>
      <section className='container no-top'>
        <div className="spacer-double"></div>
        {/* <Skeleton baseColor="#202020" highlightColor="#444" /> */}
        <div className='spacer-single'></div>
        {loading && <center><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt"></span></center>}
        {!loading && <ul className="activity-list">
          {userNFTs && userNFTs.map(e => {
            return (
              <li className="act_sale" key={e.txHash}>
                <div className="fh" style={
                  {
                    backgroundImage: e.img ? `url(${e.img})` : `url(${''})`,
                    backgroundSize: "cover"
                  }

                }>
                  {/* <img className="lazy" src={e.img} alt="" /> */}
                </div>
                <div className="act_list_text">
                  <h4>{e.title}</h4>
                  {e.subStatus < 3 && <ThreeBounce className="marginright5" size={10} color='#6f42c1' />}
                  <span className="fontsize12 bolder">{txStatus(e.subStatus)}</span> {e.subStatus === 3 && e.lucentNFT &&
                    <span className="color"><Link className=' color axnoline fontsize12 bolder' to={"/lucent/" + e.lucentNFT}>#{e.lucentNFT}</Link></span>}<br />




                  {(e.tx || e.txHash) && <>Tx: <a className=' color' href={('https://moonbeam.moonscan.io/tx/') + (e.tx ? (e.tx) : (e.txHash))} target="_blank"><span >{e.tx ? shortener(e.tx) : shortener(e.txHash)}</span> <span className="fa fa-link "></span></a>
                  </>}
                  {/* <span className="act_list_date">
                {e.createdAt && <Moment format="HH:mm DD/MM/YYYY">
                {e.createdAt}
               </Moment>}
              </span> */}
                </div>
              </li>);
          })}

        </ul>}
        {/* <ColumnNew /> */}

        <div className="spacer-single"></div>

        <Pagination onPageChange={onPageChange} _currentPage={currentPage} recordsPerPage={5} pageNumberLimit={5} totalRecords={totalRecords} />

      </section>

      <Footer />
    </div>
  )
};
export default Createpage;
