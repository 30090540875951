import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { navigate } from "@reach/router";
// import { useNavigate, useParams,Link } from 'react-router-dom';
class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: 300,
      // dots: true,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    };
    // let navigate = useNavigate();
    // const gotoRankingProfiles= ()=>{
    //   navigate(`/ranking/profiles`);
    // }
    return (
      <div className='nft'>
        <Slider {...settings}>
          {[...Array(5)].map((key, index) => {
            return <CustomSlide className='itm' key={index+1} index={index+1}>
              <div className="nft_coll">
                <div className="nft_wrap">
                  <span><img src="./img/collections/coll-1.jpg" className="lazy img-fluid" alt="" /></span>
                </div>
                <div className="nft_coll_pp">
                  <span onClick={() => window.open("/home", "_self")}><img className="lazy" src="./img/author/author-1.jpg" alt="" /></span>
                  <i className="fa fa-check"></i>
                </div>
                <div className="nft_coll_info">
                  <span onClick={() => window.open("/home", "_self")}><h4>Abstraction {index+1}</h4></span>
                  <div className="spacer-single"></div>
                  {/* <span>ERC-192</span> */}
                </div>
              </div>
            </CustomSlide>

          })}
        </Slider>
                <div className="spacer-single"></div>
        <div className='col-lg-12 xparent'>
                {/* <span onClick={gotoRankingProfiles} className="btn-main2 lead m-auto">See All</span> */}
            </div>
      </div>
    );
  }
}
