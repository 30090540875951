import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select'
import ColumnNew from '../components/ColumnNew';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
// import { navigate, useParams } from "@reach/router";
// import { Link } from "@reach/router";
import { useNavigate, useParams, Link } from 'react-router-dom';
import Moment from 'react-moment';
import { ethers } from "ethers";
import { checkNFTOwner, getExemplarInfo, getExemplarNFTs, getLucentMintingPrice, getSig, userTX } from '../../api/api';
import useDepositorContract from '../../hooks/useDepositorContract';
import Pagination from "../components/Pagination";
import Swal from 'sweetalert2'
import '@sweetalert2/theme-dark/dark.scss';
// import '@sweetalert2/themes/dark/dark.scss';
import {
  ThreeBounce
} from 'better-react-spinkit'
import { AuthContext } from "../../context/AuthContext";
import useMarketplaceContract from '../../hooks/useMarketplaceContract';
import useSignerContract from '../../hooks/useSignerContract';
import { useTimer } from 'use-timer';
import {
  TelegramShareButton,
  TwitterShareButton,
  TwitterIcon,
  TelegramIcon
} from "react-share";
const GlobalStyles = createGlobalStyle`
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: block !important;
}
.navbar .mainside a{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
.item-dropdown{
  .dropdown{
    a{
      &:hover{
        background: #8364e2;
      }
    }
  }
}
.btn-main{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
p.lead{
  color: #a2a2a2;
}
.navbar .navbar-item .lines{
  border-bottom: 2px solid #8364e2;
}
.jumbotron.no-bg{
  height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
#tsparticles{
  top: 0;
}
.text-uppercase.color{
  color: #8364e2;
}
.de_count h3 {
  font-size: 36px;
  margin-bottom: 0px;
}
.de_count h5{
  font-size: 14px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
}
.box-url{
  text-align: center;
  h4{
    font-size: 16px;
  }
}
.de_countdown{
  border: solid 2px #8364e2;
}
.author_list_pp, .author_list_pp i, 
.nft_coll_pp i, .feature-box.style-3 i, 
footer.footer-light #form_subscribe #btn-subscribe i, 
#scroll-to-top div{
  background: #8364e2;
}
footer.footer-light .subfooter .social-icons span i{
  background: #403f83;
}
.author_list_pp:hover img{
  box-shadow: 0px 0px 0px 2px #8364e2;
}
.nft__item_action span{
  color: #8364e2;
}
.feature-box.style-3 i.wm{
  color: rgba(131,100,226, .1);
}
@media only screen and (max-width: 1199px) {
  .navbar{
    
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
  }
}
.profileBtn{
  width:150px;
  text-align: center;
  margin-top:5px;
}
.fsize32{
  font-size: 42px;
}
.text-left{
  text-align: left;
}
.exemplar_avatar {
  display: flex;
}
`;
const customStyles = {
  option: (base, state) => ({
    ...base,
    background: "#212428",
    color: "#fff",
    borderRadius: state.isFocused ? "0" : 0,
    "&:hover": {
      background: "#16181b",
    }
  }),
  menu: base => ({
    ...base,
    background: "#212428 !important",
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    padding: 0
  }),
  control: (base, state) => ({
    ...base,
    padding: 2
  })
};


const options = [
  { value: 'All categories', label: 'All categories' },
  { value: 'Art', label: 'Art' },
  { value: 'Music', label: 'Music' },
  { value: 'Domain Names', label: 'Domain Names' }
]
const options1 = [
  { value: '0', label: 'All Lucents' },
  { value: '1', label: 'On Sale' },
]
const options2 = [
  { value: '0', label: 'Sort By Mint Date' },
  { value: '1', label: 'Sort By On Sale Date' },
  { value: '2', label: 'Sort By Autographs' },
  // { value: '0', label: 'Recently Minted' },
  // { value: '1', label: 'Recently Listed' },
  // { value: '2', label: 'Price High To Low' },
  // { value: '3', label: 'Price Low To High' },
  // { value: '4', label: 'Sort By Autographs' },

]

////////////////////////////////////////////////////////////////////////
const Exemplar = () => {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const [S1, setS1] = useState("0");
  const [S2, setS2] = useState("0");
  // let { ethereum } = window;
  const [depositorContract] = useDepositorContract()
  const [marketplaceContract] = useMarketplaceContract()
  const [signerContract] = useSignerContract()

  const [dx, setDX] = useState();
  const [userNFTs, setUserNFTs] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(100);


  const [ShowIssuerTxt, setShowIssuerTxt] = useState(false);
  const [ShowOwnerTxt, setShowOwnerTxt] = useState(false);
  const [ShowNotOwnerTxt, setShowNotOwnerTxt] = useState(false);
  const [CheckingOwner, setCheckingOwner] = useState(false);
  const [ShowOwnerUpdateTxt, setShowOwnerUpdateTxt] = useState(false);

  const [ShowClaimA, setShowClaimA] = useState(false);
  const [ShowClaimB, setShowClaimB] = useState(false);
  const [ShowClaimC, setShowClaimC] = useState(false);

  const [ClaimA_m, setClaimA_m] = useState("0.0");
  const [ClaimB_m, setClaimB_m] = useState("0.0");
  const [ClaimC_m, setClaimC_m] = useState("0.0");

  const [ClaimingA, setClaimingA] = useState(false);
  const [ClaimingB, setClaimingB] = useState(false);
  const [ClaimingC, setClaimingC] = useState(false);


  const [loading, setLoading] = useState(false);
  const [showTXMSG, setShowTXMSG] = useState(false);

  const [LucentMintingPrice, setLucentMintingPrice] = useState('0');
  const [LucentMintingPriceFormatted, setLucentMintingPriceFormatted] = useState('');

  const [ClaimsFinishedPrep, setClaimsFinishedPrep] = useState(false);
  const [ClaimsFinished, setClaimsFinished] = useState(false);

  const getLucentMintingPriceX = async () => {
    const r = await getLucentMintingPrice();
    setLucentMintingPrice(r.data.price)
    setLucentMintingPriceFormatted(ethers.utils.formatEther(r.data.price))
  }


  const onPageChange = async (page) => {

    console.log("onPageChange: ", page)
    setCurrentPage(page)


  }
  let navigate = useNavigate();
  const params = useParams();

  const _getExemplarInfo = async () => {
    let res = await getExemplarInfo(params.contract, params.token)
    if (res.data) {
      setDX(res.data);
    }
  }
  const handleS1Change = (e) => {
    console.log("handleS1Change: ", e.value)
    setS1(e.value)
    setCurrentPage(1)
  };
  const handleS2Change = (e) => {
    console.log("handleS2Change: ", e.value)
    setS2(e.value)
    setCurrentPage(1)
  };
  const getNFTs = async () => {
    // let nfts = await getUserNFTs(localStorage.getItem('currentPage'));
    // console.log("currentPage: ",localStorage.getItem('currentPage'))
    setLoading(true)
    let nfts = await getExemplarNFTs(params.contract, params.token, currentPage, S1, S2);
    setUserNFTs(nfts.data)
    setTotalRecords(nfts.totalRecords)
    setLoading(false)
  }
  useEffect(() => {
    getNFTs();
  }, [currentPage, S1, S2])
  useEffect(() => {
    console.log('params.contract: ', params.contract)
    console.log('params.tokenID: ', params.token)
    _getExemplarInfo();
    getLucentMintingPriceX();
    // getNFTs();
  }, [])

  useEffect(() => {
    if (dx) {



      setShowIssuerTxt(dx.isIssuer)
      setShowOwnerTxt(dx.isOwner)
      setShowOwnerUpdateTxt(dx.ownerIsUpdating)
      setClaimingA(dx.claimingA)
      setClaimingB(dx.claimingB)
      setClaimingC(dx.claimingC)
      if (!(dx.claimA && dx.claimB && dx.claimC)) {
        if (!dx.claimA && dx.claimA_m !== '0') {
          if (dx.isOwner) setShowClaimA(true)
          setTimeout(() => {
            if (dx?.claimA_m) setClaimA_m(ethers.utils.formatEther(dx.claimA_m));
          }, 1000);
        }
        if (!dx.claimB && dx.claimB_m !== '0') {
          if (dx.isOwner) setShowClaimB(true)
          setTimeout(() => {
            if (dx?.claimB_m) setClaimB_m(ethers.utils.formatEther(dx.claimB_m));
          }, 1000);
        }
        if (!dx.claimC && dx.claimC_m !== '0') {
          if (dx.isOwner) setShowClaimC(true)
          setTimeout(() => {
            if (dx?.claimC_m) setClaimC_m(ethers.utils.formatEther(dx.claimC_m));
          }, 1000);
        }

      }
    }
    if(dx && (dx.claimingA || dx.claimingB || dx.claimingC)){
      setClaimsFinishedPrep(true)
    }
    if(ClaimsFinishedPrep && ( dx.isOwner && !((!dx.claimA && ClaimA_m !== '0.0') || (!dx.claimB && ClaimB_m !== '0.0') || (!dx.claimC && ClaimC_m !== '0.0')))){
      setClaimsFinished(true)
    }
  }, [dx])
  useEffect(() => {
   if(ClaimsFinished){
    Swal.fire({
      // title: 'Please Connect A Wallet!',
      text: 'All claimable amounts has been transfered to your withdrawal cluster!',
      // icon: 'error',
      icon: 'success',
      confirmButtonText: 'OK'
    })
     
   }
  }, [ClaimsFinished])
  
  const testTx = async () => {
    console.log("testTx");
    if (!(userInfo && userInfo.isAuth)) {
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Please Connect A Wallet!',
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
      return;
    }
    const ToastPender = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      // timer: 3000,
      timerProgressBar: false,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })

    ToastPender.fire({
      icon: 'info',
      title: 'Connecting To Wallet...'
    })
    setShowTXMSG(false)
    // console.log("minDeposit: ", await depositorContract.minDeposit())
    const options = { value: await depositorContract.minDeposit() }
    console.log("contract: ", params.contract)
    console.log("token: ", params.token)

    const chain = dx.chainID === '1' ? "Ethereum" : "Polygon";
    const contract = dx.eContract;
    const token = dx.eTokenID
    let chainID = 1;
    if (chain === 'Polygon') {
      chainID = 2;
    }

    let xr = await getSig(chain, contract, token);
    let sig = xr.data.sig;
    try {
      const tx = await depositorContract.deposit(chainID, contract, token, sig, options);
      ToastPender.close();
      // console.log("tx: ", tx)
      console.log("tx: ", tx.hash)
      const resp = await userTX({
        txHash: tx.hash,
        type: "DEPOSIT_FOR_MINT",
        title: dx.title,
        img: dx.img
      })
      console.log("resp: ", resp)

      const Toast = Swal.mixin({
        toast: true,
        position: 'center-center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          // toast.addEventListener('mouseenter', Swal.stopTimer)
          // toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Transaction Submitted!'
      })
      setShowTXMSG(true)
    } catch (error) {
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }


  }
  const shortener = (txt) => {
    return txt.toString().substring(0, 8) + "..." + txt.toString().substring(txt.toString().length - 6)
  }
  const shortenerC = (txt, s) => {

    return txt.length < s ? txt : txt.toString().substring(0, s) + "..."
  }


  const checkNFTOwnerX = async () => {

    if (!(userInfo && userInfo.isAuth)) {
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Please Connect A Wallet!',
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
      return;
    }


    setCheckingOwner(true)
    const res = await checkNFTOwner({
      id: dx.id
    });
    setCheckingOwner(false)
    const { isOwner, isCurrentOwner } = res.data
    if (!isOwner) {
      setShowNotOwnerTxt(true)
    } else
      if (isOwner && !isCurrentOwner) {
        setShowOwnerUpdateTxt(true)
        _getExemplarInfo();
      }
  }

  const goClaim1 = async () => {
    const ToastPender = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      // timer: 3000,
      timerProgressBar: false,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })

    ToastPender.fire({
      icon: 'info',
      title: 'Connecting To Wallet...'
    })

    try {
      const tx = await depositorContract.claimKeeper(dx.chainID, dx.eContract, dx.eTokenID)
      setClaimingA(true)
      ToastPender.close();
      const resp = await userTX({
        txHash: tx.hash,
        type: "CLAIMING_A",
        chainID: dx.chainID,
        eContract: dx.eContract,
        eTokenID: dx.eTokenID
      })
      const Toast = Swal.mixin({
        toast: true,
        position: 'center-center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          // toast.addEventListener('mouseenter', Swal.stopTimer)
          // toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Transaction Submitted!'
      })
    } catch (error) {
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }
    // setClaimingA(false)
  }
  const goClaim2 = async () => {
    const ToastPender = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      // timer: 3000,
      timerProgressBar: false,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })

    ToastPender.fire({
      icon: 'info',
      title: 'Connecting To Wallet...'
    })

    try {
      const tx = await marketplaceContract.claimKeeper(dx.chainID, dx.eContract, dx.eTokenID)
      setClaimingB(true)
      ToastPender.close();
      const resp = await userTX({
        txHash: tx.hash,
        type: "CLAIMING_B",
        chainID: dx.chainID,
        eContract: dx.eContract,
        eTokenID: dx.eTokenID
      })
      const Toast = Swal.mixin({
        toast: true,
        position: 'center-center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          // toast.addEventListener('mouseenter', Swal.stopTimer)
          // toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Transaction Submitted!'
      })
    } catch (error) {
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }
    // setClaimingB(false)
  }
  const goClaim3 = async () => {
    const ToastPender = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      // timer: 3000,
      timerProgressBar: false,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })

    ToastPender.fire({
      icon: 'info',
      title: 'Connecting To Wallet...'
    })
    try {
      const tx = await signerContract.claimKeeper(dx.chainID, dx.eContract, dx.eTokenID)
      setClaimingC(true)
      ToastPender.close();
      const resp = await userTX({
        txHash: tx.hash,
        type: "CLAIMING_C",
        chainID: dx.chainID,
        eContract: dx.eContract,
        eTokenID: dx.eTokenID
      })
      const Toast = Swal.mixin({
        toast: true,
        position: 'center-center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          // toast.addEventListener('mouseenter', Swal.stopTimer)
          // toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Transaction Submitted!'
      })
    } catch (error) {
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }
    // setClaimingC(false)
  }

  const { time, start, pause, reset, status } = useTimer();
  useEffect(() => {
    start()
    return () => {
      reset()
    }
  }, [])
  useEffect(() => {
    if (time % 5 === 4) {
      if (dx && dx.ownerIsUpdating) {
        _getExemplarInfo();
      }else
      if (dx && (ClaimingA || ClaimingB || ClaimingC)) {
        _getExemplarInfo();
      }
    }

  }, [time])
  const gotoProfile = (wp) => {
    navigate("/profile/" + wp)
  }
  const getExplorerLink = (chainID, eContract) => {
    // https://etherscan.io/address/0xed5af388653567af2f388e6224dc7c4b3241c544
    // https://polygonscan.com/address/0x2953399124f0cbb46d2cbacd8a89cf0599974963
    switch (chainID) {
      case "1":
        return `https://etherscan.io/address/${eContract}`

      case "2":
        return `https://polygonscan.io/address/${eContract}`

    }

  }
  const tokenShortener = (txt) => {
    return txt;
    // return txt.toString().substring(0, 10)+(txt.length>10?"...":"")
  }
  const xcopy = (txt) => {
    console.log("xcopy")
    navigator.clipboard.writeText(txt)
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        // toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: 'TokenID Copied!'
    })
    // Swal.fire({
    //   position: 'center',
    //   icon: 'success',
    //   title: 'Address Copied!',
    //   showConfirmButton: false,
    //   timer: 1500
    // })
  }
  const share_url = `https://link.lucentnfts.com/share/reference/${params.chain}/${params.contract}/${params.token}`
  const share_title = `Check out this NFT reference on LucentNFTs!`;
  return (
    <div>
      <GlobalStyles />
      <section className=' breadcumb no-bg'>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row '>

              <div className="col-md-6 text-center ">
                {dx && dx.img && <div className="nft__item "><img src={dx.img} className="img-fluid img-rounded mb-sm-30" alt="" /></div>}
                <div className="nft__item ">
                  <div className="">
                    <div className="">
                      {dx && dx.chainID && <span className="fontsize12 "> <span >Chain: <span className="bolder">{dx.chainID === "1" ? "Ethereum" : "Polygon"}</span> </span></span>}
                      <br />
                      <span className="fontsize12 "> <span >Contract: <span className="bolder">
                        {(dx && dx.eContract) && <a className=' color' href={getExplorerLink(dx.chainID, dx.eContract)} target="_blank"><span >{shortener(dx.eContract)}</span> </a>
                        } </span>  </span>  </span><br />
                      {dx && dx.eTokenID && <span className="fontsize12 "> <span >Token ID: <span >{shortenerC(dx.eTokenID, 15)}</span>  </span>
                        {dx.eTokenID.length >= 15 && <button id="btn_copy" title="Copy" className="margin-left color-transparent" onClick={() => xcopy(dx.eTokenID)}><i className="fa fa-copy "></i></button>}
                      </span>}

                    </div>
                  </div>
                </div>
                {dx && dx.marketplaceURL && <div className="nft__itemz ">
                  <div className="">
                    <div className="">
                      <span className="fontsize12 ">

                        {!dx.marketplace && <span>Marketplace:</span>}
                        {dx.marketplace && <img src="../../img/marketplaces/opensea.png" width="32" height="32" />}
                        <span className="bolder">
                          &nbsp; <a className='color' href={dx.marketplaceURL} target="_blank">{shortenerC(dx.marketplaceURL, 29)}</a>
                        </span>

                      </span>
                      <br />

                    </div>
                  </div>
                </div>}
              </div>
              <div className="col-md-6">
                <div className="item_info">
                  {/* Auctions ends in 
    <div className="de_countdown">
      <Clock deadline="December, 30, 2021" />
    </div> */}
                  {dx && <h2>{dx.title}</h2>}
                 
                  <ul id="share_menu_def">
                      <li>
                        <TwitterShareButton url={share_url} title={share_title} via="LucentNFTs" hashtags={["NFTCommunity", "LucentNFTs"]}><TwitterIcon size={32} round={true} bgStyle={{ fill: '#685dc3' }} /></TwitterShareButton>
                      </li>
                      <li>
                        <TelegramShareButton url={share_url} title="" ><TelegramIcon size={32} round={true} bgStyle={{ fill: '#685dc3' }} /></TelegramShareButton>
                      </li>
                    </ul>
                    <div className="spacer-10"></div>
                  {/* <div className=""><span className="profile_username ">100 Lucents</span></div> */}
                  {/* <div className="spacer-single"></div> */}
                  {/* <div className="item_info_counts">

                    <div className="item_info_type"><i className="fa fa-image"></i>Art</div>
                    <div className="item_info_views"><i className="fa fa-eye"></i>250</div>
                    <div className="item_info_like"><i className="fa fa-heart"></i>18</div>
                  </div> */}

                  {/* {dx && dx.description && <><div>{dx.description}</div>
                  <div className="spacer-single"></div>
                  </>} */}


                  {dx && (dx.eIssuerInfo || dx.eOwnerInfo) && <div className='row '>

                    {dx && dx.eIssuerInfo && <div className="col-md-6">
                      <h6 >Created By</h6>
                      <div className="item_author">
                        <div className="author_list_pp">
                          <span onClick={() => gotoProfile(dx.eIssuerInfo.wp)}>
                            <img className="lazy" src={dx.eIssuerInfo.avatar} alt="" />
                            {dx.eIssuerInfo.isOfficial && <i className="fa fa-check"></i>}
                          </span>
                        </div>
                        <div className="author_list_info xpointer">

                          <span onClick={() => gotoProfile(dx.eIssuerInfo.wp)}>{dx.eIssuerInfo.fullName}</span>
                        </div>
                      </div>
                    </div>}
                    {/*  */}
                    {dx && dx.eOwnerInfo && <div className="col-md-6">
                      <h6 >Owned By</h6>
                      <div className="item_author">
                        <div className="author_list_pp">
                          <span onClick={() => gotoProfile(dx.eOwnerInfo.wp)}>
                            <img className="lazy" src={dx.eOwnerInfo.avatar} alt="" />
                            {dx.eOwnerInfo.isOfficial && <i className="fa fa-check"></i>}
                          </span>
                        </div>
                        <div className="author_list_info xpointer">

                          <span onClick={() => gotoProfile(dx.eOwnerInfo.wp)}>{dx.eOwnerInfo.fullName}</span>

                        </div>
                      </div>
                     
                    </div>}
                    <div className="spacer-single"></div>
                  </div>}

                </div>


                <div className="spacer-10"></div>
                <div className="nft__item ">
                  <div className="">
                    <div className="row">
                      <div className="col-md-6"><span className="fontsize12 "> <span >Creator Royalty : <span className="bolder">0 GLMR</span> </span></span></div>
                      {/* <div className="col-md-6">
                        {!ShowIssuerTxt && <span onClick={() => { setShowIssuerTxt(true) }} className="btn-main2-small inline lead fontsize12">I'm a Creator</span>}
                        {ShowIssuerTxt && <span className=" fontsize12">Dear creator of the NFT, please contact us by social media or email.</span>}
                      </div> */}
                      <div className="spacer-10"></div>
                      <div className="col-md-6"><span className="fontsize12 "> <span >Owner Royalty : <span className="bolder">0 GLMR</span> </span></span></div>
                      <div className="col-md-6">
                        {CheckingOwner && <><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt fontsize12">Please wait...</span></>}
                        {!ShowNotOwnerTxt && !ShowOwnerTxt && !CheckingOwner && !ShowOwnerUpdateTxt && <span onClick={checkNFTOwnerX} className="btn-main2-small inline lead fontsize12">I'm an Owner</span>}
                        {/* {ShowOwnerTxt && <span className=" fontsize12">You are an owner of the NFT.</span>} */}
                        {ShowNotOwnerTxt && <span className=" fontsize12">You are not an owner of the NFT.</span>}
                        {ShowOwnerUpdateTxt && <span className=" fontsize12"><><ThreeBounce size={14} color='#6f42c1' /><span className=" loadertxt fontsize12">You are an owner of the NFT, we're updating the status...</span></></span>}

                      </div>


                    </div>
                  </div>
                </div>
                {dx && (!dx.hasOwner || dx.isOwner && ((!dx.claimA && ClaimA_m !== '0.0') || (!dx.claimB && ClaimB_m !== '0.0') || (!dx.claimC && ClaimC_m !== '0.0'))) && <div className="nft__item ">
                  <div className="">
                    <div className="row">

                      <div className="col-md-12 fontsize12">
                        (ROYALTY CLUTSER A) CURRENT CLAIMABLE AMOUNT FOR THE NFT OWNER:
                      </div>
                      <div className="col-md-12">
                        {dx && !dx.claimA &&<span className="bolder fontsize12 marginright5">{ClaimA_m} GLMR</span>}
                        {ShowClaimA && !ClaimingA && !dx.claimA && <span onClick={goClaim1} className="btn-main2-small inline lead fontsize12">Claim</span>}
                        {ClaimingA && <><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt fontsize12">Please wait...</span></>}
                        {dx && dx.claimA && <div className="statusLabel-green">CLAIMED</div>}
                      </div>
                      <div className="spacer-10"></div>
                      <div className="col-md-12 fontsize12">
                        (ROYALTY CLUTSER B) CURRENT CLAIMABLE AMOUNT FOR THE NFT OWNER:
                      </div>
                      <div className="col-md-12">
                        <span className="bolder fontsize12 marginright5">{ClaimB_m} GLMR</span>
                        {ShowClaimB && !ClaimingB && !dx.claimB &&<span onClick={goClaim2} className="btn-main2-small inline lead fontsize12">Claim</span>}
                        {ClaimingB && <><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt fontsize12">Please wait...</span></>}
                        {dx && dx.claimB && <div className="statusLabel-green">CLAIMED</div>}
                      </div>
                      <div className="spacer-10"></div>
                      <div className="col-md-12 fontsize12">
                        (ROYALTY CLUTSER C) CURRENT CLAIMABLE AMOUNT FOR THE NFT OWNER:
                      </div>
                      <div className="col-md-12">
                        {<span className="bolder fontsize12 marginright5">{ClaimC_m} GLMR</span>}
                        {ShowClaimC && !ClaimingC && !dx.claimC &&<span onClick={goClaim3} className="btn-main2-small inline lead fontsize12">Claim</span>}
                        {ClaimingC && <><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt fontsize12">Please wait...</span></>}
                        {dx && dx.claimC && <div className="statusLabel-green">CLAIMED</div>}
                      </div>

                    </div>
                  </div>
                </div>}
                {/* <div className="nft__item ">
                  <div className="">
                    <div className="">
                      <span onClick={testTx} className="btn-main inline lead fontsize12">New Lucent</span>
                      <span className="fontsize12 "> <span >FEE <span className="bolder">3 GLMR</span> </span></span>
                      {showTXMSG && <div className="fontsize12 ">
                        <div className="spacer-single"></div>
                        <span className="fa fa-check"></span><span className="marginlefter5 bolder">TRANSACTION HAS BEEN SUBMITTED SUCCESSFULLY!</span>
                      </div>}
                    </div>
                  </div>
                </div> */}


                <div className="nft__item ">
                  <div className="">
                    {dx && <div className="">
                      <span className="fontsize12 "> <span >Lucents: <span className="bolder">{dx.lucentsCount}</span> </span></span><br />
                      <span className="fontsize12 "> <span >Lucents Owners: <span className="bolder">{dx.lucentsOwners}</span> </span></span><br />
                      <span className="fontsize12 "> <span >Lucents Volume: <span className="bolder">{dx.lucentsVolume} GLMR</span> </span></span><br />

                    </div>}
                  </div>
                </div>
                <div className="nft__item ">
                  <div className="">
                    <div className="">
                      <span onClick={testTx} className="btn-main inline lead fontsize12">New Lucent</span>
                      <span className="fontsize12 "> <span >FEE <span className="bolder">{LucentMintingPriceFormatted} GLMR</span> </span></span>
                      {showTXMSG && <div className="fontsize12">
                        <div className="spacer-10"></div>
                        <span className="fa fa-check"></span><span className="marginlefter5 bolder">Transaction has been submitted successfully! Your Lucent will be minted within 1 minute. The minting progress can be monitored in the <span className='fa fa-star-o col-white'></span>&nbsp;<span className='col-white'>NFTs</span> section.</span>
                        <div className="spacer-10"></div>
                      </div>}
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </section>

      <section className='container no-top'>
        <div className='row '>
          <div className="spacer-single"></div>
          <div className='col-lg-6  items_filter'>
            <div className='dropdownSelect two'><Select className='select1 text-left' styles={customStyles} defaultValue={options1[0]} options={options1} onChange={handleS1Change} /></div>
            <div className='dropdownSelect three'><Select className='select1 text-left' styles={customStyles} defaultValue={options2[0]} options={options2} onChange={handleS2Change} /></div>
            <div className="items_filter">
              {/* <form className="row form-dark" id="form_quick_search" name="form_quick_search">
                      <div className="col">
                        <input className="form-control" id="name_1" name="name_1" placeholder="Search..." type="text" /> <button id="btn-submit"><i className="fa fa-search bg-color-secondary"></i></button>
                        <div className="clearfix"></div>
                      </div>
                    </form> */}
              {/* <div className='dropdownSelect one '><Select className='select1 text-left' styles={customStyles} menuContainerStyle={{ 'zIndex': 999 }} defaultValue={options[0]} options={options} /></div> */}
              {/* <div className='dropdownSelect two'><Select className='select1 text-left' styles={customStyles} defaultValue={options1[0]} options={options1} /></div>
                    <div className='dropdownSelect three'><Select className='select1 text-left' styles={customStyles} defaultValue={options2[0]} options={options2} /></div> */}
            </div>
          </div>
        </div>
        <div className="spacer-single"></div>
        {!loading && <ul className="activity-list">
          {userNFTs && userNFTs.map(e => {
            return (<li className="act_sale" key={e.id}>
              <img className="lazy" src={e.img} alt="" />
              <div className="act_list_text">
                <div className="fontsize12 bolder col-white">{e.title}</div>
                <span className="fontsize12 "><span className=" bolder">LUCENT NFT <Link className=' color axnoline' to={"/lucent/" + e.lucentNFT}>#{e.lucentNFT}</Link></span>&nbsp;
                Owned By <Link className='color axnoline' to={"/profile/" + e.nftOwnerWP}>{e.nftOwnerFullName}</Link>
                </span>
                <span className="act_list_date fontsize12">
                  
                  Referenced At&nbsp;
                  {e.createdAt && <span className="bolder fontsize12"><Moment format="HH:mm MMM DD, YYYY">
                    {e.createdAt}
                  </Moment></span>}
                </span>
                <span className="fontsize12">
                Autographs: <span className='col-white bolder'>{e.autographs}</span>
                </span>
                <div className="spacer-10"></div>
              </div>
            </li>)
          })

          }

        </ul>}
        {loading && <center><ThreeBounce size={14} color='#6f42c1' /><span className="col-purple loadertxt"></span></center>}
        {
          !loading && userNFTs && userNFTs.length === 0 && <div className="text-center">
            No results found.
          </div>
        }
        {/* <ColumnNew /> */}
        <div className="spacer-single"></div>
        <Pagination onPageChange={onPageChange} _currentPage={currentPage} recordsPerPage={5} pageNumberLimit={5} totalRecords={totalRecords} />
      </section>


      <Footer />
    </div>

  )
};
export default Exemplar;