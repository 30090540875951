import { useState, useEffect } from "react";
import { depositorABI, depositorAddress } from "../contracts/depositorABI";
import { ethers } from "ethers";
const useDepositorContract = () => {
  let { ethereum } = window;
  const [depositorContract, setDepositorContract] = useState(null);
 
  useEffect(() => {
    if(!ethereum)return;
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    setDepositorContract(new ethers.Contract(depositorAddress, depositorABI, signer));
    console.log(">>x")
}, [ethereum]);

  return [depositorContract];
};

export default useDepositorContract;
