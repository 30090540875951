import React, { useState } from 'react';
// import { Link } from '@reach/router';
import { useNavigate, useParams,Link } from 'react-router-dom';

import { xemail } from '../../api/api';

const Footer = () => {
    const [Email, setEmail] = useState('');
    const [EmailSubmited, setEmailSubmited] = useState(false);
    const handleMailChange = (e) => {
        setEmail(e.target.value)
    }
    const submitEmail = async () => {
        console.log("Email: ", Email)
        await xemail({
            email:Email
        });
        setEmailSubmited(true)
    }
    return (
        <footer className="footer-light">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Explore</h5>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/profiles">Profiles</Link></li>
                                <li><Link to="/explore">Lucent NFTs</Link></li>
                                {/* <li><Link to="">Art</Link></li>
                                <li><Link to="">Music</Link></li>
                                <li><Link to="">Domain Names</Link></li>
                                <li><Link to="">Virtual World</Link></li>
                                <li><Link to="">Collectibles</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Ranking</h5>
                            <ul>
                                <li><Link to="/ranking/nfts">Top NFTs</Link></li>
                                <li><Link to="/ranking/profiles">Top Profiles</Link></li>
                                <li><Link to="/ranking/collectors">Top Collectors</Link></li>
                                {/* <li><Link to="">Documentation</Link></li>
                                <li><Link to="">Brand Assets</Link></li>
                                <li><Link to="">Blog</Link></li>
                                <li><Link to="">Forum</Link></li>
                                <li><Link to="">Mailing List</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Resources</h5>
                            <ul>
                                <li><a href="mailto:info@lucentnfts.com">Support Center</a></li>
                                <li><a href="https://lucentnfts.com/terms_and_conditions.html" target="_blank">Terms &amp; Conditions</a></li>
                                <li><a href="https://lucentnfts.com/privacy_policy.html" target="_blank">Privacy Policy</a></li>
                                {/* <li><Link to="/news">Newsletter</Link></li> */}
                                {/* <li><Link to="">info@lucentnfts.com</Link></li> */}
                                {/* <li><Link to="">Suggestions</Link></li>
                                <li><Link to="">Discord</Link></li>
                                <li><Link to="">Docs</Link></li>
                                 */}
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6 col-xs-1">
                         <div className="widget">
                            <h5>Newsletter</h5>
                            {/* <p>Signup for our newsletter to get the latest news in your inbox.</p> */}
                            {!EmailSubmited && <div className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                                <div className="col text-center">
                                    <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" onChange={(e) => handleMailChange(e)} />
                                    <div id="btn-subscribe">
                                        <i className="arrow_right bg-color-secondary xpointer" onClick={submitEmail}></i>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>}
                            {EmailSubmited && <span className="fontsize14 bolder">Thank you!</span>}
                            <div className="spacer-10"></div>
                            <small>Your email is safe with us. We don't spam.</small>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex">
                                <div className="de-flex-col">
                                    <span >
                                        <span className="copy">&copy; Copyright 2022 - Lucent NFTs</span>
                                    </span>
                                </div>
                                <div className="de-flex-col">
                                    <div className="social-icons">
                                        {/* <span onClick={()=> window.open("", "_self")}><i className="fa fa-facebook fa-lg"></i></span> */}
                                        <a href="https://twitter.com/lucentnfts" target="_blank"><i className="fa fa-twitter fa-lg"></i></a>&nbsp;&nbsp;
                                        {/* <span onClick={()=> window.open("", "_self")}><i className="fa fa-linkedin fa-lg"></i></span> */}
                                        {/* <span onClick={()=> window.open("", "_self")}><i className="fa fa-pinterest fa-lg"></i></span> */}
                                        {/* <span onClick={()=> window.open("", "_self")}><i className="fa fa-rss fa-lg"></i></span> */}
                                        <a href = "https://www.instagram.com/lucentnfts" target="_blank"><i className="fa fa-instagram fa-lg"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
};
export default Footer;