import React, { useState, useEffect, useContext } from "react";
// import Clock from "../components/Clock";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
// import CarouselCollection from '../components/CarouselCollection';
// import { navigate, useParams } from "@reach/router";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getApproveTx, getAutographs, getLucentNFT, getTXCheckZoneMarketplace, userTX } from "../../api/api";
import { ethers } from "ethers";
// import { Link } from "@reach/router";
import { ThreeBounce } from 'better-react-spinkit'
import Pagination from "../components/Pagination";
import { UserContext } from "../../context/UserContext";
import useMinterContract from "../../hooks/useMinterContract";
import useMarketplaceContract from "../../hooks/useMarketplaceContract";
import { AuthContext } from "../../context/AuthContext";
import Swal from 'sweetalert2'
import '@sweetalert2/theme-dark/dark.scss';
// import '@sweetalert2/themes/dark/dark.scss';
import { marketplaceAddress } from "../../contracts/marketplaceABI";
import { minterAddress } from "../../contracts/minterABI";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {
  TelegramShareButton,
  TwitterShareButton,
  TwitterIcon,
  TelegramIcon
} from "react-share";

const GlobalStyles = createGlobalStyle`
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: block !important;
}
.navbar .mainside a{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
.item-dropdown{
  .dropdown{
    a{
      &:hover{
        background: #8364e2;
      }
    }
  }
}
.btn-main{
  background: #8364e2;
  &:hover{
    box-shadow: 2px 2px 20px 0px #8364e2;
  }
}
p.lead{
  color: #a2a2a2;
}
.navbar .navbar-item .lines{
  border-bottom: 2px solid #8364e2;
}
.jumbotron.no-bg{
  height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
#tsparticles{
  top: 0;
}
.text-uppercase.color{
  color: #8364e2;
}
.de_count h3 {
  font-size: 36px;
  margin-bottom: 0px;
}
.de_count h5{
  font-size: 14px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
}
.box-url{
  text-align: center;
  h4{
    font-size: 16px;
  }
}
.de_countdown{
  border: solid 2px #8364e2;
}
.author_list_pp, .author_list_pp i, 
.nft_coll_pp i, .feature-box.style-3 i, 
footer.footer-light #form_subscribe #btn-subscribe i, 
#scroll-to-top div{
  background: #8364e2;
}
footer.footer-light .subfooter .social-icons span i{
  background: #403f83;
}
.author_list_pp:hover img{
  box-shadow: 0px 0px 0px 2px #8364e2;
}
.nft__item_action span{
  color: #8364e2;
}
.feature-box.style-3 i.wm{
  color: rgba(131,100,226, .1);
}
@media only screen and (max-width: 1199px) {
  .navbar{
    
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
  }
}
.profileBtn{
  width:150px;
  text-align: center;
  margin-top:5px;
}
.fsize32{
  font-size: 42px;
}
.text-left{
  text-align: left;
}
.exemplar_avatar {
  display: flex;
}
`;



const contractAddress = marketplaceAddress;



const Colection = function () {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const [dx, setDX] = useState({});
  const [autographs, setAutographs] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);


  const [buyPriceETH, setBuyPriceETH] = useState("");
  const [pendingTx, setPendingTx] = useState("");

  const [MinSellPrice, setMinSellPrice] = useState("");
  const [MinSellPriceFormatted, setMinSellPriceFormatted] = useState("0.0");

  const [BuyFinishedPrep, setBuyFinishedPrep] = useState(false);
  const [BuyFinished, setBuyFinished] = useState(false);

  // const provider = new ethers.providers.Web3Provider(window.ethereum)
  // const signer = provider.getSigner()

  // const marketplaceContract = new ethers.Contract(contractAddress, abiCentral, signer);
  // const minterContract = new ethers.Contract(contractAddressMinter, abiMinter, signer);
  const [minterContract] = useMinterContract()
  const [marketplaceContract] = useMarketplaceContract()

  const { userName, setUserName } = useContext(UserContext);

  const [price, setPrice] = useState('0');

  const [approveMode, setApproveMode] = useState(false);
  const [ApprovalPendingMode, setApprovePendingMode] = useState(false);

  const [sellMode, setSellMode] = useState(false);
  const [priceChangeMode, setPriceChangeMode] = useState(false);

  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    document.getElementById("Mainbtn").classList.add("active");
    document.getElementById("Mainbtn1").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
    setOpenMenu(false);
    document.getElementById("Mainbtn1").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
  };

  const handleDefinePriceChange = (e) => {
    // this.setState({email: e.target.value});
    console.log("handleDefinePriceChange: ", e.target.value)
    setPrice(e.target.value)
  }
  const trySell = async () => {
    console.log("trySell")
    let isApproved = await checkApproval();

    if (isApproved) {
      setSellMode(true);
    } else {
      let status = await _getApproveTx();
      if (status) {
        setApprovePendingMode(true)
        approvalCheckID = setInterval(() => {
          checkApprovalFrec()
        }, 5000);
      } else {
        setApproveMode(true);
      }
    }
  }

  const sellConfirm = async () => {
    console.log("sellConfirm: ", price)

    if (!price) {
      setPrice("0")
      return;
    }
    const ToastPender = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      // timer: 3000,
      timerProgressBar: false,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })

    ToastPender.fire({
      icon: 'info',
      title: 'Connecting To Wallet...'
    })
    // const options = { value: ethers.utils.parseEther("0.01") }
    // const tx = await contract.deposit(options);
    // console.log("tx: ", tx)

    console.log("dx.tokenID: ", dx.tokenID)
    console.log("price eth: ", ethers.utils.parseEther(price).toString())

    let minPrice = await marketplaceContract.minPrice();
    console.log("minPrice: ", minPrice.toString())


    const x1 = ethers.BigNumber.from(minPrice)
    const x2 = ethers.utils.parseEther(price);
    if (x2.lt(x1)) {
      ToastPender.close();
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: `Minimum price to set for the sale ${ethers.utils.formatEther(MinSellPrice)} GLMR`,
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
      return;
    }
    ////--TX Check Zone
    let res = await getTXCheckZoneMarketplace(dx.id, dx.lastMarketplaceTX || "");
    if (res && res.data && res.status === 1) {
      if (res.data.statusChanged) {
        ToastPender.close();
        Swal.fire({
          // title: 'Please Connect A Wallet!',
          text: `Status of the Lucent has changed in the marketplace. Please check  it again.`,
          // icon: 'error',
          icon: 'info',
          confirmButtonText: 'OK'
        })
        setSellMode(false)
        setPriceChangeMode(false)
        await _getLucentNFT();
        return;
      }
      if (res.data.statusIsPending) {
        ToastPender.close();
        Swal.fire({
          // title: 'Please Connect A Wallet!',
          text: `Status of the Lucent is updating. Please try again later.`,
          // icon: 'error',
          icon: 'info',
          confirmButtonText: 'OK'
        })
        setSellMode(false)
        setPriceChangeMode(false)
        await _getLucentNFT();
        return;
      }
    }
    ////--/TX Check Zone

    // let tx = await marketplaceContract.setMinPrice(ethers.utils.parseEther(price));
    try {
      let tx = await marketplaceContract.forSale(parseInt(dx.tokenID), ethers.utils.parseEther(price));
      console.log("tx: ", tx)
      ToastPender.close();
      await userTX({
        type: "SALE",
        txHash: tx.hash,
        nftID: dx.id
      })
      const Toast = Swal.mixin({
        toast: true,
        position: 'center-center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          // toast.addEventListener('mouseenter', Swal.stopTimer)
          // toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Transaction Submitted!'
      })
      await _getLucentNFT();
      setSellMode(false);
    } catch (error) {
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }

  };
  const sellCancel = () => {
    console.log("sellCancel")
    setSellMode(false)
  };
  const tryChangePrice = async () => {
    console.log("tryChangePrice")
    setPriceChangeMode(true)
  }
  const changePriceConfirm = async () => {
    console.log("changePriceConfirm: ", price)
    if (!price) {
      setPrice("0")
      return;
    }
    const ToastPender = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      // timer: 3000,
      timerProgressBar: false,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })

    ToastPender.fire({
      icon: 'info',
      title: 'Connecting To Wallet...'
    })
    // const options = { value: ethers.utils.parseEther("0.01") }
    // const tx = await contract.deposit(options);
    // console.log("tx: ", tx)

    console.log("dx.orderID: ", dx.orderID)
    console.log("price eth: ", ethers.utils.parseEther(price).toString())

    let minPrice = await marketplaceContract.minPrice();
    console.log("minPrice: ", minPrice.toString())


    const x1 = ethers.BigNumber.from(minPrice)
    const x2 = ethers.utils.parseEther(price);
    if (x2.lt(x1)) {
      ToastPender.close();
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: `Minimum price to set for the sale ${ethers.utils.formatEther(MinSellPrice)} GLMR`,
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
      return;
    }

    ////--TX Check Zone
    let res = await getTXCheckZoneMarketplace(dx.id, dx.lastMarketplaceTX || "");
    if (res && res.data && res.status === 1) {
      if (res.data.statusChanged) {
        ToastPender.close();
        Swal.fire({
          // title: 'Please Connect A Wallet!',
          text: `Status of the Lucent has changed in the marketplace. Please check  it again.`,
          // icon: 'error',
          icon: 'info',
          confirmButtonText: 'OK'
        })
        setSellMode(false)
        setPriceChangeMode(false)
        await _getLucentNFT();
        return;
      }
      if (res.data.statusIsPending) {
        ToastPender.close();
        Swal.fire({
          // title: 'Please Connect A Wallet!',
          text: `Status of the Lucent is updating. Please try again later.`,
          // icon: 'error',
          icon: 'info',
          confirmButtonText: 'OK'
        })
        setSellMode(false)
        setPriceChangeMode(false)
        await _getLucentNFT();
        return;
      }
    }
    ////--/TX Check Zone

    // let tx = await marketplaceContract.setMinPrice(ethers.utils.parseEther(price));
    // parseInt(dx.orderID)

    try {

      let tx = await marketplaceContract.updatePrice(dx.orderID, ethers.utils.parseEther(price));
      console.log("tx: ", tx)
      ToastPender.close();
      await userTX({
        type: "CHANGE_PRICE",
        txHash: tx.hash,
        nftID: dx.id
      })
      const Toast = Swal.mixin({
        toast: true,
        position: 'center-center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          // toast.addEventListener('mouseenter', Swal.stopTimer)
          // toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Transaction Submitted!'
      })
      await _getLucentNFT();
      setPriceChangeMode(false);
    } catch (error) {
      console.log("v error: ", error);
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }

  };

  const changePriceCancel = async () => {
    console.log("tryChangePrice")
    setPriceChangeMode(false)
  }
  const WithdrawSaleConfirm = async () => {
    console.log("WithdrawSaleConfirm: ", price)
    // const options = { value: ethers.utils.parseEther("0.01") }
    // const tx = await contract.deposit(options);
    // console.log("tx: ", tx)
    const ToastPender = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      // timer: 3000,
      timerProgressBar: false,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })

    ToastPender.fire({
      icon: 'info',
      title: 'Connecting To Wallet...'
    })
    console.log("dx.orderID: ", dx.orderID)

    ////--TX Check Zone
    let res = await getTXCheckZoneMarketplace(dx.id, dx.lastMarketplaceTX || "");
    if (res && res.data && res.status === 1) {
      if (res.data.statusChanged) {
        ToastPender.close();
        Swal.fire({
          // title: 'Please Connect A Wallet!',
          text: `Status of the Lucent has changed in the marketplace. Please check  it again.`,
          // icon: 'error',
          icon: 'info',
          confirmButtonText: 'OK'
        })

        await _getLucentNFT();
        return;
      }
      if (res.data.statusIsPending) {
        ToastPender.close();
        Swal.fire({
          // title: 'Please Connect A Wallet!',
          text: `Status of the Lucent is updating. Please try again later.`,
          // icon: 'error',
          icon: 'info',
          confirmButtonText: 'OK'
        })

        await _getLucentNFT();
        return;
      }
    }
    ////--/TX Check Zone
    try {
      let tx = await marketplaceContract.withdrawSale(parseInt(dx.orderID));
      console.log("tx: ", tx)
      ToastPender.close();
      await userTX({
        type: "CANCEL_SALE",
        txHash: tx.hash,
        nftID: dx.id
      })
      const Toast = Swal.mixin({
        toast: true,
        position: 'center-center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          //  toast.addEventListener('mouseenter', Swal.stopTimer)
          //  toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Transaction Submitted!'
      })
      await _getLucentNFT();
    } catch (error) {
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }

  };
  const buy = async () => {
    console.log("buy dx.definedPrice: ", dx.definedPrice)
    const ToastPender = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      // timer: 3000,
      timerProgressBar: false,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })

    ToastPender.fire({
      icon: 'info',
      title: 'Connecting To Wallet...'
    })

    ////--TX Check Zone
    let res = await getTXCheckZoneMarketplace(dx.id, dx.lastMarketplaceTX || "");
    if (res && res.data && res.status === 1) {
      if (res.data.statusChanged) {
        ToastPender.close();
        Swal.fire({
          // title: 'Please Connect A Wallet!',
          text: `Status of the Lucent has changed in the marketplace. Please check  it again.`,
          // icon: 'error',
          icon: 'info',
          confirmButtonText: 'OK'
        })
        await _getLucentNFT();
        return;
      }
      if (res.data.statusIsPending) {
        ToastPender.close();
        Swal.fire({
          // title: 'Please Connect A Wallet!',
          text: `Status of the Lucent is updating. Please try again later.`,
          // icon: 'error',
          icon: 'info',
          confirmButtonText: 'OK'
        })
        await _getLucentNFT();
        return;
      }
    }
    ////--/TX Check Zone
    try {
      let wei = ethers.BigNumber.from(dx.definedPrice);
      const options = { value: wei }
      const tx = await marketplaceContract.buy(parseInt(dx.orderID), options);
      console.log("tx: ", tx)
      ToastPender.close();
      await userTX({
        type: "BUY",
        txHash: tx.hash,
        nftID: dx.id
      })
      const Toast = Swal.mixin({
        toast: true,
        position: 'center-center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          //  toast.addEventListener('mouseenter', Swal.stopTimer)
          //  toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Transaction Submitted!'
      })
      await _getLucentNFT();

    } catch (error) {
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }
  };



  const changeC = () => {
    setUserName("ok")
  }

  let navigate = useNavigate();
  let nftRefresherID;
  const params = useParams();
  const _getLucentNFT = async () => {
    // console.log("params.id: ", params.id)
    let res = await getLucentNFT(params.id)
    console.log("_getLucentNFT : ")
    if (res.data.length > 0) {
      setDX(res.data[0]);
    }
  }
  const _getAutographs = async () => {
    // console.log("params.id: ", params.id)
    let res = await getAutographs(params.id, currentPage)
    console.log("_getAutographs : ")
    setAutographs(res.data);
    setTotalRecords(res.totalRecords)
  }
  const alignBtns = async () => {

    console.log("alignBtns")
    if (dx) {

      console.log("dx: ", dx)
      console.log("dx.statusDP: ", dx.statusDP)
      console.log("dx.statusCS: ", dx.statusCP)
      console.log("dx.statusCS: ", dx.statusCS)
      console.log("dx.statusBuy: ", dx.statusBuy)

      if (dx && dx.statusDP === 1 ||
        dx.statusCP === 1 ||
        dx.statusCS === 1 ||
        dx.statusBuy === 1
      ) {
        console.log("setTimeout")
        setPendingTx(true)
        if (dx.statusMyBuy) {
          setBuyFinishedPrep(true);
        }
        nftRefresherID = setTimeout(() => {
          _getLucentNFT()
        }, 5000);
      } else {
        console.log("clearTimeout")
        setPendingTx(false)
        if (BuyFinishedPrep) {
          setBuyFinished(true)
        }
        if (nftRefresherID) clearTimeout(nftRefresherID)
      }
    }
  }
  useEffect(() => {
    if (BuyFinished) {
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Your Purchase is complete!',
        // icon: 'error',
        icon: 'success',
        confirmButtonText: 'OK'
      })

    }
  }, [BuyFinished])
  const _getApproveTx = async () => {
    // console.log("params.id: ", params.id)
    let res = await getApproveTx(dx.id)
    if (res.data.isPending === "ok") {
      return true;
    }
    return false;
  }

  const checkApproval = async () => {
    //owner,operator
    const tx = await minterContract.isApprovedForAll(localStorage.getItem("wp"), contractAddress);
    console.log("isApprovedForAll: ", tx)
    return tx;
  }

  let approvalCheckID;
  const onApproveBtn = async () => {
    const ToastPender = Swal.mixin({
      toast: true,
      position: 'center-center',
      showConfirmButton: false,
      // timer: 3000,
      timerProgressBar: false,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })

    ToastPender.fire({
      icon: 'info',
      title: 'Connecting To Wallet...'
    })
    try {
      //operator
      const tx = await minterContract.setApprovalForAll(contractAddress, true);
      console.log("onApproveBtn: ", tx)
      ToastPender.close();
      await userTX({
        type: "MARKETPLACE_APPROVE",
        txHash: tx.hash
      })
      const Toast = Swal.mixin({
        toast: true,
        position: 'center-center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          // toast.addEventListener('mouseenter', Swal.stopTimer)
          // toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Transaction Submitted!'
      })
      if (tx) {
        setApproveMode(false);
        setApprovePendingMode(true);
        approvalCheckID = setInterval(() => {
          checkApprovalFrec()
        }, 5000);
      }

    } catch (error) {
      ToastPender.close();
      if (error.code === -32603) {
        if (error.data.message.trim() === 'evm error: OutOfFund') {
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Insufficient funds!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }

      }
    }
  }
  const checkApprovalFrec = async () => {
    console.log("checkApprovalFrec")
    let isApproved = await checkApproval();
    if (isApproved) {
      setApprovePendingMode(false);
      setSellMode(true);
      clearInterval(approvalCheckID)
    }
  }



  const checkMinSellPrice = async () => {
    let minPrice = await marketplaceContract.minPrice();
    setMinSellPrice(minPrice);
    setMinSellPriceFormatted(ethers.utils.formatEther(minPrice))
  }
  useEffect(() => {
    if (marketplaceContract) {
      checkMinSellPrice();
    }

  }, [marketplaceContract])

  useEffect(() => {
    return () => {
      if (approvalCheckID) clearInterval(approvalCheckID)
      if (nftRefresherID) clearTimeout(nftRefresherID)
    }
  }, [])

  useEffect(() => {
    alignBtns();
    if (dx && dx.definedPrice) {
      console.log("dx.definedPrice: ", dx.definedPrice)
      let wei = ethers.BigNumber.from(dx.definedPrice);
      console.log("wei: ", wei.toString())
      let _buyPrice = ethers.utils.formatEther(wei)
      setBuyPriceETH(_buyPrice);
    }
    return () => {

    }
  }, [dx])

  //1- sell
  //isMyNFT && statusDP === 0 && definedPrice===0
  //2- update price, cancel sale
  //isMyNFT &&
  //definedPrice > 0 && 
  //statusDP == 0 &&
  //statusCP == 0 &&
  //statusCS == 0
  //3- buy
  //!isMyNFT &&
  //definedPrice>0 && 
  //statusDP ===0 &&
  //hasMyBuyTx ===0
  //----------------------------------------------------------------
  //1 + inicial buttons
  //2 + tx pendings label
  //3 + tx pendings checkinterval
  //4 - on btns click check statuses
  //5 + submit tx

  //// 
  useEffect(() => {
    _getLucentNFT();
    console.log("userInfo: ", userInfo)
  }, [userInfo])
  let btnSet = <></>
  if (userInfo && userInfo.isAuth) {


    if (approveMode) {
      btnSet = <>
        <div className="spacer-10"></div>
        <span onClick={onApproveBtn} className="btn-main inline lead fontsize12">APPROVE</span>
        <div className="spacer-10"></div>
      </>
    }

    if (dx && dx.isMy && !approveMode && !ApprovalPendingMode && dx.statusDP === 0 && dx.definedPrice === "0") {
      btnSet = <>
        <div className="spacer-10"></div>
        <span onClick={trySell} className="btn-main inline lead fontsize12">SELL</span>
        <div className="spacer-10"></div>
      </>
    }
    if (sellMode) {
      btnSet = <>
        <span onClick={sellCancel} className="btn-main2 inline lead fontsize12">CANCEL</span>
        <span onClick={sellConfirm} className="btn-main inline lead fontsize12">CONFIRM</span>
        <div className="spacer-10"></div>
      </>
    }
    if (priceChangeMode) {
      btnSet = <>
        <span onClick={changePriceCancel} className="btn-main2 inline lead fontsize12">CANCEL</span>
        <span onClick={changePriceConfirm} className="btn-main inline lead fontsize12">CONFIRM</span>
        <div className="spacer-10"></div>
      </>
    }

    if (dx && dx.isMy &&
      !priceChangeMode &&
      dx.definedPrice !== "0" &&
      dx.statusDP === 0 &&
      dx.statusCP === 0 &&
      dx.statusCS === 0
    ) {
      btnSet = <>
        <div className="spacer-10"></div>
        <span onClick={tryChangePrice} className="btn-main2 inline lead fontsize12">CHANGE PRICE</span>
        <span onClick={WithdrawSaleConfirm} className="btn-main2 inline lead fontsize12">CANCEL SALE</span>
        <div className="spacer-10"></div>
      </>
    }
    if (dx && !dx.isMy &&
      dx.definedPrice !== "0" &&
      dx.statusDP === 0 && !dx.hasMyBuyTxPending) {
      btnSet = <>
        <div className="spacer-10"></div>
        <span onClick={buy} className="btn-main inline lead ">BUY</span>
        <div className="spacer-10"></div>
      </>
    }
  }
  useEffect(() => {
    _getAutographs();

  }, [currentPage])
  const onPageChange = async (page) => {

    // console.log("onPageChange: ", page)
    setCurrentPage(page)


  }


  const gotoProfile = (wp) => {
    navigate("/profile/" + wp)
  }
  const shortener = (txt) => {
    return txt.toString().substring(0, 8) + "..." + txt.toString().substring(txt.toString().length - 6)
  }
  const share_url = `https://link.lucentnfts.com/share/lucent/${params.id}`
  const share_title = `Check out this Lucent on LucentNFTs!`;
  return (
    <div>
      <GlobalStyles />

      <section className=' breadcumb no-bg'>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row '>

              <div className="col-md-6 text-center ">
                <div className="nft__item ">
                  <img
                    src={dx && dx.img}
                    // src="https://image.cnbcfm.com/api/v1/image/105940475-1559232349684190164-car-ferrari-sf90-stradale.jpg?v=1559232362&w=800" 
                    className="img-fluid img-rounded " alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="item_info">
                  {/* Auctions ends in 
<div className="de_countdown">
<Clock deadline="December, 30, 2021" />
</div> */}
                  <h2>{dx && dx.title} </h2>
                  <span className="fontsize14 bolder"> LUCENT NFT <span className="bolder color-darkgray">#{dx && dx.lucentNFT}</span></span>
                  <div className="spacer-10"></div>
                  <div className="item_info_counts">
                    <ul id="share_menu_def">
                      <li>
                        <TwitterShareButton url={share_url} title={share_title} via="LucentNFTs" hashtags={["NFTCommunity", "LucentNFTs"]}><TwitterIcon size={32} round={true} bgStyle={{ fill: '#685dc3' }} /></TwitterShareButton>
                      </li>
                      <li>
                        <TelegramShareButton url={share_url} title="" ><TelegramIcon size={32} round={true} bgStyle={{ fill: '#685dc3' }} /></TelegramShareButton>
                      </li>
                    </ul>
                    {/* <div className="item_info_type"><i className="fa fa-image"></i>Art</div> */}
                    {/* <div className="item_info_like"><i className="fa fa-eye"></i>250</div>
                    <div className="item_info_like"><i className="fa fa-heart"></i>18</div>
                    <div className="item_info_like"><i className="fa fa-bookmark"></i>18</div> */}
                  </div>
                  {/* <div className=""><span className="profile_username ">100 Lucents</span></div> */}
                  {/* <div className="spacer-single"></div> */}





                  <div className="nft__item ">
                    {/* <div className="spacer-10"></div> */}

                    <div className="">
                      <span className="fontsize12 "> <span >Chain: <span className="bolder">Moonbeam</span> </span></span><br />
                      <span className="fontsize12 "> <span >Contract: <span className="bolder">
                        {(dx) && <a className=' color' href={('https://moonbeam.moonscan.io/address/') + (minterAddress)} target="_blank"><span >{shortener(minterAddress)}</span> </a>
                        } </span>  </span>  </span><br />
                      <span className="fontsize12 "> <span >Token ID: <span className="bolder"> {dx && dx.tokenID && <a className=' color' href={('https://lucentnfts.mypinata.cloud/ipfs/') + (dx.ipfs)} target="_blank">{(dx.tokenID)}</a>}</span>
                      </span></span>

                    </div>


                    {/* <div className="spacer-10"></div> */}
                  </div>
                  {dx && dx.eContract && <Link className="color axnoline" to={`/reference/${dx.chainF}/${dx.eContract}/${dx.eTokenID}`}>
                    <span className="bolder color fontsize14">Original NFT Details <span className="fa fa-arrow-right"></span></span>
                  </Link>}
                  <div className="spacer-single"></div>
                  <h6 >Referenced By</h6>
                  <div className="item_author">
                    <div className="author_list_pp">
                      <span>
                        {dx && dx.nftOwnerAvatar && <span className="color"><Link className=' color axnoline fontsize12 bolder' to={"/profile/" + dx.nftOwnerWP}><img className="lazy" src={dx.nftOwnerAvatar} alt="" /></Link></span>}
                        {dx && dx.nftOwnerIsOfficial && <i className="fa fa-check"></i>}
                      </span>
                    </div>
                    <div className="author_list_info">

                      {dx && dx.nftOwnerFullName && <span><Link className=' color axnoline fontsize12 bolder' to={"/profile/" + dx.nftOwnerWP}>{dx.nftOwnerFullName}</Link></span>}
                    </div>
                  </div>




                </div>


                <div className="spacer-single"></div>
                <div className="paddingleft10   ">
                  <div className="nft__item ">

                    {/* <div className="nft__item_wrap">
              <span>
              </span>
            </div> */}
                    <div className="paddingleft10">
                      {/* <div className="spacer-single"></div> */}
                      <div className="">

                        {dx && buyPriceETH && buyPriceETH !== "0.0" && <div className="  "> <span className="fontsize12 "> <span >CURRENT PRICE <span className="bolder">{buyPriceETH && buyPriceETH} GLMR</span> </span></span></div>}
                        {dx && buyPriceETH && buyPriceETH === "0.0" && <div className="  "> <span className="fontsize12 "> <span >The Lucent is not listed for sale.  </span></span></div>}

                        {(sellMode || priceChangeMode) && <div className="row ">
                          <div className="col-md-8  ">
                            <div className="fontsize12">Price in GLMR, minimum price to set for the sale {MinSellPriceFormatted} GLMR</div>
                            <input type="number" name="price" id="price" className="form-control" placeholder="" onChange={(e) => handleDefinePriceChange(e)} />
                          </div>
                          {/* <div className="col-md-6  ">
                        </div> */}
                        </div>}

                        <div className="">{
                          btnSet
                        }</div>


                        {/* {dx && dx.isMy && <span onClick={sell} className="btn-main inline lead ">SELL</span>} */}
                        {/* {dx && dx.isMy &&<div className="fontsize12 paddingleft10 marginTop10">YOU ARE OWNER OF THIS LUCENT NFT</div>} */}
                        {/* <div className="spacer-10"></div> */}
                        {dx && (pendingTx || ApprovalPendingMode) && <div className="paddingleft10"> <span className="fontsize14 "><ThreeBounce className="marginright5" size={10} color='#6f42c1' /> <span >NFT status is updating...</span></span>
                          {/* <div className="spacer-10"></div> */}
                        </div>}

                      </div>

                      {/* <div className="spacer-single"></div> */}

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className='container no-top no-bottom'>
        <div className='row'>
          <div className="spacer-single"></div>
          <div className='col-lg-12 mb-2'>
            <h3 className='text-center'>AUTOGRAPHS</h3>
          </div>
        </div>
        {/* <div className="spacer-single"></div> */}
        {/* <div className='col-lg-12'> */}
        {/* <CarouselCollection /> */}
        {/* </div> */}

      </section>
      <div className="container ">
        <div className=" row">

          {autographs && autographs.map((e) => {
            return (<div className='col-lg-3' key={e.id}>
              <div className="nft_coll">
                <div className="nft_wrap">
                  <span><img src={e.cover} className="lazy img-fluid" alt="" /></span>
                </div>
                <div className="nft_coll_pp">
                  <span onClick={() => gotoProfile(e.wp)}><img className="lazy" src={e.avatar} alt="" /></span>
                  {e.isOfficial && <i className="fa fa-check"></i>}
                </div>
                <div className="nft_coll_info">
                  <span onClick={() => gotoProfile(e.wp)}><h4>{e.fullName} </h4></span>
                  <div className="spacer-10"></div>
                  {/* <span>ERC-192</span> */}

                  {e.autograph && 
                  <table width="100%">
                  <tr className="valign">
                    <td align="right">
                      <div className="marginleft10 zimg "><Zoom overlayBgColorEnd="rgba(0, 0, 0, 0.55)"><img className="" src={e.autograph} height="32" align="middle" /></Zoom>
                      </div>
                      </td>
                      <td align="left">
                        <div>&nbsp;<a className='bolder color fontsize12' href={('https://lucentnfts.mypinata.cloud/ipfs/') + (e.autographMetaIPFS)} target="_blank">IPFS <span className="fa fa-link "></span></a></div>
                     
                    </td>
                  </tr>
                  </table>
                  }







                </div>
              </div>
            </div>)
          })}


          <div className="spacer-double"></div>

          {/* <ul className="pagination justify-content-center">
            <li className="active"><span>1 - 20</span></li>
            <li><span>21 - 40</span></li>
            <li><span>41 - 60</span></li>
          </ul> */}
          <Pagination onPageChange={onPageChange} _currentPage={currentPage} recordsPerPage={20} pageNumberLimit={5} totalRecords={totalRecords} />

        </div> </div>

      <div className="spacer-double"></div>
      <Footer />
    </div>
  );
}
export default Colection;