import React, { useContext, useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { header } from 'react-bootstrap';
// import { Link , navigate} from '@reach/router';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  Outlet,
} from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import { AuthContext } from "../../context/AuthContext";
import Swal from 'sweetalert2'
import '@sweetalert2/theme-dark/dark.scss';
import useRoyalty from "../../hooks/useRoyalty";
import { ethers } from "ethers";
import useNoty from "../../hooks/useNoty";
import { checkNewNotyX } from "../../api/api";
import detectEthereumProvider from '@metamask/detect-provider'
import useMoonbeam from "../../hooks/useMoonbeam";
setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link
    {...props}
    getprops={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);
// const contractAddress = '0x9FC936ba2069De6269AFD41022CF2b46ebbA35c8'
// const abi = [
//   {
//     "inputs": [],
//     "stateMutability": "nonpayable",
//     "type": "constructor"
//   },
//   {
//     "inputs": [],
//     "name": "deposit",
//     "outputs": [],
//     "stateMutability": "payable",
//     "type": "function"
//   },
//   {
//     "inputs": [
//       {
//         "internalType": "uint256",
//         "name": "v",
//         "type": "uint256"
//       }
//     ],
//     "name": "setX",
//     "outputs": [],
//     "stateMutability": "nonpayable",
//     "type": "function"
//   },
//   {
//     "inputs": [],
//     "name": "x",
//     "outputs": [
//       {
//         "internalType": "uint256",
//         "name": "",
//         "type": "uint256"
//       }
//     ],
//     "stateMutability": "view",
//     "type": "function"
//   }
// ];

const Header = () => {

  let navigate = useNavigate();
  const {xprovider, configureMoonbeam} = useMoonbeam();

  const { userInfo, setUserInfo } = useContext(AuthContext);
  const [init, setInit] = useState(true);
  const [checkMetamask, setCheckMetamask] = useState(false);
  const [signRequest, setSignRequest] = useState(false);
  const [cTxt, setCTxt] = useState("CONNECT WALLET");

  const [isWalletConnected, setWalletConnected] = useState(false);
  const [xselectedAddress, setSelectedAddress] = useState("");

  const { NewRoyalty, N1NewRoyalty, N2NewRoyalty, N3NewRoyalty } = useRoyalty(10);
  const [NewRoyaltyFormatted, setNewRoyaltyFormatted] = useState("0.0");

  const { NotyCount, checkNotyH } = useNoty(10);

  useEffect(() => {
    const nrf = ethers.utils.formatEther(NewRoyalty)
    console.log("NewRoyalty: ", nrf)
    setNewRoyaltyFormatted(nrf)
  }, [NewRoyalty])

  const setWalletConnectedx = () => {
    if (!isProvider()) {
      //show connect btn
      console.log("no isProvider")
      return;
    }
    if (!isMetamask()) {
      //show connect btn
      console.log("no isMetamask")
      return;
    }
    if (!isAccount()) {
      //show connect btn
      console.log("no isAccount")
      return;
    }
    if (!isNetwork()) {
      //show connect btn
      console.log("no isNetwork")
      return;
    }
    if (!isAuth()) {
      //show connect btn
      console.log("no isAuth")
      return;
    }

    setWalletConnected(true);
  }
  const chainChanged = (networkId) => {
    console.log('chainChanged', networkId);
    if (isNetwork()) {
      setWalletConnectedx();
      setUserInfo({ ...userInfo, isAuth: true });
    } else {
      setWalletConnected(false);
      setCTxt("SWITCH NETWORK")
      setUserInfo({ ...userInfo, isAuth: false });

    }
  }
  const accountsChanged = async (accounts) => {
    let { ethereum } = window
    console.log('accountsChanged', accounts);
    console.log("selectedAddress: ", ethereum.selectedAddress)
    setWalletConnected(false);
    localStorage.removeItem("token")
    localStorage.removeItem("wp")
    setUserInfo({
    });

    if (ethereum.selectedAddress) {
      setSelectedAddress(ethereum.selectedAddress);
      setCTxt("AUTHORIZE " + ethereum.selectedAddress.toString().substring(0, 6) + "..." + ethereum.selectedAddress.toString().substring(ethereum.selectedAddress.toString().length - 4))
      console.log("on accountsChanged")
      setWalletConnected(false);
      localStorage.removeItem('token')
      localStorage.removeItem('wp')
      setUserInfo({
      });
      // setTimeout(async() => {
      // let ok =  await auth(ethereum.selectedAddress,"auth in accountsChanged");
      // if(ok){

      //   setSelectedAddress(ethereum.selectedAddress);
      //   // setWalletConnectedx();
      //   setWalletConnected(false);
      // }
      // }, 1000);
      // console.log("cTxt: ",cTxt)

      if (isNetwork()) {
        setCTxt("AUTHORIZE " + ethereum.selectedAddress.toString().substring(0, 6) + "..." + ethereum.selectedAddress.toString().substring(ethereum.selectedAddress.toString().length - 4))
      } else {
        setCTxt("SWITCH NETWORK")
      }

    } else {
      setCTxt("CONNECT WALLET")

      navigate("/")
    }
  }

  function isMetaMaskInstalled() {
    let { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  async function isMetaMaskConnected() {
    if (!isMetaMaskInstalled()) {
      return false;
    }
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    return accounts && accounts.length > 0;
  }

  const auth = async (wp, ini) => {
    const req = await fetch(`https://lucentapi.lucentnfts.com/auth/challenge/request?public_key=${wp}`);
    const newData = await req.json();

    console.log("ini: ", ini)
    return await personalSign(newData)
  };


  const personalSign = async (xmsg) => {
    const { ethereum } = window;

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    const from = ethereum.selectedAddress;
    const exampleMessage = xmsg.data;
    const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
    try {
      console.log("signRequest: ", signRequest)
      setCheckMetamask(true)
      if (signRequest) return false

      setSignRequest(true)
      const sign = await ethereum.request({
        method: 'personal_sign',
        params: [msg, from, "ok"],
      });
      setSignRequest(false)

      console.log("sign: ", sign)
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          signedData: sign,
          sessionID: xmsg.sessionID
        })
      };
      const response = await fetch('https://lucentapi.lucentnfts.com/auth/challenge/signedRegister', requestOptions)
      const jsonData = await response.json();

      // console.log("jsonData: ", jsonData)
      console.log("response s: ", jsonData.status)
      localStorage.setItem("token", jsonData.jwt)
      localStorage.setItem("wp", jsonData.wp)
      setUserInfo({
        token: jsonData.jwt,
        wp: jsonData.wp,
        isAuth: true
      });

    } catch (error) {
      console.log("s error: ", error)
      setCheckMetamask(false)
      setSignRequest(false)
      return false
    }
    setCheckMetamask(false)
    return true

  };

  const initialise = async () => {

    //----------
    setInit(false)
    const { ethereum } = window;
    console.log("ethereum: ", ethereum)
    if (!isProvider()) {
      //show connect btn
      console.log("no isProvider")
      return;
    }
    if (!isMetamask()) {
      //show connect btn
      console.log("no isMetamask")
      return;
    }
    // ethereum.removeListener("chainChanged", chainChanged)
    // ethereum.on('chainChanged', chainChanged);
    // ethereum.removeListener("accountsChanged", accountsChanged)
    // ethereum.on('accountsChanged', accountsChanged);
    if (!isAccount()) {
      //show connect btn
      console.log("no isAccount")
      return;
    }
    if (!isNetwork()) {
      //show connect btn
      console.log("no isNetwork")
      return;
    }
    if (!isAuth()) {
      //show connect btn
      console.log("no isAuth")
      return;
    }

    //show 

    console.log("ethereum.selectedAddress: ", ethereum.selectedAddress)
    setSelectedAddress(ethereum.selectedAddress);
    // setWalletConnectedx();
    setWalletConnected(true);

    setUserInfo({
      token: localStorage.getItem('token'),
      wp: localStorage.getItem('wp'),
      isAuth: true
    });

  }

  useEffect(() => {
    setTimeout(() => {
      initialise();
    }, 1000);
  }, [])
  const isProvider = () => {
    let { ethereum } = window
    return Boolean(ethereum);
  }
  const isMetamask = () => {
    let { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask);
  }
  const isAccount = () => {
    let { ethereum } = window
    return Boolean(ethereum && ethereum.selectedAddress)
  }
  const isNetwork = () => {
    let { ethereum } = window
    return Boolean(ethereum && (ethereum.chainId === '0x504'))
  }
  const isAuth = () => {
    let token = localStorage.getItem('token');
    return Boolean(token)
  }


  const configureMoonbeamz = async () => {
    navigate("/wallet");
    return;
    const provider = await detectEthereumProvider({ mustBeMetaMask: true })
    if (provider) {
      console.log('Ethereum successfully detected!')
      try {
        await provider.request({ method: "eth_requestAccounts" })
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            // {
            //   chainId: '0x89',
            //   chainName: 'Polygon Mainnet',
            //   rpcUrls: ['https://polygon-rpc.com/'] ,
            //   nativeCurrency: {
            //       // name: 'Matic',
            //       symbol: 'MATIC',
            //       decimals: 18,
            //   },
            //   blockExplorerUrls: ['https://polygonscan.com'],
            // },
            // {
            //   chainId: '0x504',
            //   chainName: 'Moonbeam',
            //   rpcUrls: ['https://rpc.api.moonbeam.network'],
            //   nativeCurrency: {
            //     // name: 'Matic',
            //     symbol: 'GLMR',
            //     decimals: 18,
            //   },
            //   blockExplorerUrls: ['https://moonbeam.moonscan.io'],
            // },
            {
              chainId: '0x507',
              chainName: 'Moonbase Alpha',
              rpcUrls: ['https://rpc.api.moonbase.moonbeam.network'],
              nativeCurrency: {
                // name: 'Matic',
                symbol: 'DEV',
                decimals: 18,
              },
              blockExplorerUrls: ['https://moonbase.moonscan.io/'],
            },
          ],
        });
      } catch (error) {
        if (error.code === -32002) {
          // alert("Please check Metamask Wallet!")
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Please check Metamask Wallet!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }
      }
      // await ethereum.request({
      //   method: 'wallet_switchEthereumChain',
      //   params: [{ chainId: '0x507' }],//Moonbase Alpha
      // });
      provider.on('accountsChanged', (accounts) => {
        if (accounts.length === 0) {
          // MetaMask is locked or the user doesn't have any
          // connected accounts
          console.log('Please connect to MetaMask.')
          return;
        }
        console.log("provider.selectedAddress: ", provider.selectedAddress)

      })
      provider.on("chainChanged", () => {
        // MetaMask recommends reloading the page unless you 
        // have good reason not to
        // window.location.reload()
        console.log("chainChanged")
      })
    } else {
      console.log('Please install MetaMask!')
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Please install MetaMask!',
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    }
  }

  
  const connectWallet = async () => {
    console.log("connectWallet")
    if (checkMetamask) {
      // alert("Please check Metamask Wallet!")
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Please check Metamask Wallet!',
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
      return
    }

    const { ethereum } = window;
    console.log("ethereum: ", ethereum)
    if (!isProvider()) {
      //show connect btn
      // alert('Wallet is not installed!')
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Wallet is not installed!',
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
      return;
    }
    if (!isMetamask()) {
      //show connect btn
      // alert('Metamask is not installed!')
      Swal.fire({
        // title: 'Please Connect A Wallet!',
        text: 'Metamask is not installed!',
        // icon: 'error',
        icon: 'info',
        confirmButtonText: 'OK'
      })
      return;
    }
    ethereum.removeListener("chainChanged", chainChanged)
    ethereum.on('chainChanged', chainChanged);
    ethereum.removeListener("accountsChanged", accountsChanged)
    ethereum.on('accountsChanged', accountsChanged);
    if (!isAccount()) {
      //show connect btn
      console.log("no isAccount")
      let ok = false
      try {
        setCheckMetamask(true)
        const accounts0 = await ethereum.request({ method: "eth_requestAccounts" })
        ok = true
      } catch (error) {
        console.log("isAccount: ", error)
        if (error.code === -32002) {
          // alert("Please check Metamask Wallet!")
          Swal.fire({
            // title: 'Please Connect A Wallet!',
            text: 'Please check Metamask Wallet!',
            // icon: 'error',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }
      }
      setCheckMetamask(false)
      if (!ok) return;

    }
    if (!isNetwork()) {
      //show connect btn
      console.log("no isNetwork")
      let ok = false
      try {
        setCheckMetamask(true)
        let zx = await ethereum.request({
          method: 'wallet_switchEthereumChain',
          // params: [{ chainId: '0x89' }],//Polygon
          params: [{ chainId: '0x504' }],//Moonbeam
          // params: [{ chainId: '0x507' }],//Moonbase Alpha

        });
        setCheckMetamask(false)
        ok = true
      } catch (switchError) {
        ok = false
        if (switchError.code === 4902) {
          // console.log("No matic")
          try {
            setCheckMetamask(true)
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                // {
                //   chainId: '0x89',
                //   chainName: 'Polygon Mainnet',
                //   rpcUrls: ['https://polygon-rpc.com/'] ,
                //   nativeCurrency: {
                //       // name: 'Matic',
                //       symbol: 'MATIC',
                //       decimals: 18,
                //   },
                //   blockExplorerUrls: ['https://polygonscan.com'],
                // },
                {
                  chainId: '0x504',
                  chainName: 'Moonbeam',
                  rpcUrls: ['https://rpc.api.moonbeam.network'],
                  nativeCurrency: {
                    symbol: 'GLMR',
                    decimals: 18,
                  },
                  blockExplorerUrls: ['https://moonbeam.moonscan.io'],
                },
                // {
                //   chainId: '0x507',
                //   chainName: 'Moonbase Alpha',
                //   rpcUrls: ['https://rpc.api.moonbase.moonbeam.network'],
                //   nativeCurrency: {
                //     // name: 'Matic',
                //     symbol: 'DEV',
                //     decimals: 18,
                //   },
                //   blockExplorerUrls: ['https://moonbase.moonscan.io/'],
                // },
              ],
            });

            ok = true
          } catch (addError) {
            // handle "add" error
            console.log("addError: ", addError)
          }
          setCheckMetamask(true)
        }

      }
      if (!ok) return;
    }
    if (!isAuth()) {
      //show connect btn
      console.log("no isAuth")
      let ok = false
      try {
        setCTxt("AUTHORIZE " + ethereum.selectedAddress.toString().substring(0, 6) + "..." + ethereum.selectedAddress.toString().substring(ethereum.selectedAddress.toString().length - 4))
        ok = await auth(ethereum.selectedAddress, "auth in connect");
      } catch (error) {
        console.log("isAuth: ", error)
      }
      if (!ok) return;
    }

    setSelectedAddress(ethereum.selectedAddress);
    setWalletConnectedx();

    //----------------------------------------------------------------
    // let { ethereum } = window
    // if (ethereum) {
    //   ethereum.on('chainChanged', function (networkId) {
    //     console.log('chainChanged', networkId);
    //   });
    //   ethereum.on('accountsChanged', function (accounts) {
    //     console.log('accountsChanged', accounts);
    //     console.log("selectedAddress: ", ethereum.selectedAddress)
    //     if (ethereum.selectedAddress) {
    //       // setSelectedAddress(ethereum.selectedAddress);
    //       // console.log("zx3")
    //       // auth(ethereum.selectedAddress);
    //     } else {
    //       // setWalletConnected(false);
    //     }
    //   });

    //   try {
    //     // console.log('zx--')
    //     let zx = await ethereum.request({
    //       method: 'wallet_switchEthereumChain',
    //       // params: [{ chainId: '0x89' }],//Polygon
    //       // params: [{ chainId: '0x504' }],//Moonbeam
    //       params: [{ chainId: '0x507' }],//Moonbase Alpha

    //     });
    //     // console.log('zx--: ', zx)
    //   } catch (switchError) {
    //     // This error code indicates that the chain has not been added to MetaMask.
    //     if (switchError.code === 4902) {
    //       // console.log("No matic")
    //       try {
    //         await ethereum.request({
    //           method: 'wallet_addEthereumChain',
    //           params: [
    //             // {
    //             //   chainId: '0x89',
    //             //   chainName: 'Polygon Mainnet',
    //             //   rpcUrls: ['https://polygon-rpc.com/'] ,
    //             //   nativeCurrency: {
    //             //       // name: 'Matic',
    //             //       symbol: 'MATIC',
    //             //       decimals: 18,
    //             //   },
    //             //   blockExplorerUrls: ['https://polygonscan.com'],
    //             // },
    //             // {
    //             //   chainId: '0x504',
    //             //   chainName: 'Moonbeam',
    //             //   rpcUrls: ['https://rpc.api.moonbeam.network'],
    //             //   nativeCurrency: {
    //             //     // name: 'Matic',
    //             //     symbol: 'GLMR',
    //             //     decimals: 18,
    //             //   },
    //             //   blockExplorerUrls: ['https://moonbeam.moonscan.io'],
    //             // },
    //             {
    //               chainId: '0x507',
    //               chainName: 'Moonbase Alpha',
    //               rpcUrls: ['https://rpc.api.moonbase.moonbeam.network'],
    //               nativeCurrency: {
    //                 // name: 'Matic',
    //                 symbol: 'DEV',
    //                 decimals: 18,
    //               },
    //               blockExplorerUrls: ['https://moonbase.moonscan.io/'],
    //             },
    //           ],
    //         });
    //       } catch (addError) {
    //         // handle "add" error
    //       }
    //     }
    //     // handle other "switch" errors
    //   }

    //   try {
    //     console.log('accounts0--')
    //     // const accounts0 = await ethereum.request({ method: "eth_requestAccounts" })
    //     // console.log('accounts0: ', accounts0)

    //     console.log("sMetaMask: ", ethereum.isMetaMask)
    //     console.log("selectedAddress: ", ethereum.selectedAddress)
    //     console.log("chainId: ", ethereum.chainId)

    //     console.log("currentProvider: ", ethereum)

    //     // const accounts = await ethereum.request({ method: 'eth_accounts' });
    //     // console.log('accounts: ', accounts)

    //     setWalletConnectedx();
    //     // setSelectedAddress(ethereum.selectedAddress);
    //     console.log("zx4")
    //     await auth(ethereum.selectedAddress);

    //   } catch (error) {
    //     console.log('e error: ', error)
    //     console.log('e error.code: ', error.code)
    //     //-32002 todo already processing

    //   }

    // }
    // else {
    //   console.log("Non-Ethereum browser detected. You should consider installing MetaMask.");
    // }


    //----------------------------------------------------------------
  }
  const testTx = async () => {
    console.log("test");
    // const provider = new ethers.providers.Web3Provider(window.ethereum)
    // const signer = provider.getSigner()
    // const contract = new ethers.Contract(contractAddress, abi, signer);

    //state update
    // let tx= await contract.setX(5);
    // console.log("tx: ",tx)

    //state read
    // const myBigNumber = await contract.x(); 
    // console.log("x: ",myBigNumber.toString())


    // const vv = ethers.utils.parseEther("0.01");
    //ethers.utils.formatEther(balance)
    // console.log("vv: ",vv.toString())

    // const options = { value: ethers.utils.parseEther("0.01") }
    // const tx = await contract.deposit(options);
    // console.log("tx: ", tx)
  }
  // const personalSign = async(event) => {
  //   event.preventDefault();
  //   const accounts = await ethereum.request({ method: 'eth_accounts' });
  //     console.log('accounts: ', accounts)
  //   const from = accounts[0];
  //   const exampleMessage = "Moon";
  //   const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
  //   const sign = await ethereum.request({
  //     method: 'personal_sign',
  //     params: [msg, from, 'Example password'],
  //   });
  //   console.log("sign: ",sign)
  // };

  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
  };
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2);
  };
  const handleBtnClick3 = () => {
    setOpenMenu3(!openMenu3);
  };
  const closeMenu = () => {
    setOpenMenu(false);
  };
  const closeMenu1 = () => {
    setOpenMenu1(false);
  };
  const closeMenu2 = () => {
    setOpenMenu2(false);
  };
  const closeMenu3 = () => {
    setOpenMenu3(false);
  };
  const ref = useOnclickOutside(() => {
    closeMenu();
  });
  const ref1 = useOnclickOutside(() => {
    closeMenu1();
  });
  const ref2 = useOnclickOutside(() => {
    closeMenu2();
  });
  const ref3 = useOnclickOutside(() => {
    closeMenu3();
  });

  const [showmenu, btn_icon] = useState(false);
  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        // totop.classList.add("show");

      } else {
        header.classList.remove("sticky");
        // totop.classList.remove("show");
      } if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);




  const goCheckNotyX = async () => {
    await checkNewNotyX()
    await checkNotyH()
  }


  return (
    <header id="myHeader" className='navbar white'>
      <div className='container'>
        <div className='row w-100-nav'>
          <div className='logo px-0'>
            <div className='navbar-title navbar-item'>
              <NavLink to="/">
                {/* <img
                  src="./img/logo-3.png"
                  className="img-fluid d-block"
                  alt="#"
                /> */}
                <img
                  src="./img/lucent_logo.png"
                  className=""
                  height="80"
                />

                {/* <h4 className=""><span className="fa fa-star"></span> LUCENT NFTS</h4> */}
              </NavLink>
            </div>
          </div>



          <BreakpointProvider>
            <Breakpoint l down>
              {showmenu &&
                <div className='menu'>


                  <div className='navbar-item'>
                    <div ref={ref3}>
                      <div className="dropdown-custom dropdown-toggle btn"
                        onClick={handleBtnClick3}
                      >
                        Explore
                      </div>
                      {openMenu3 && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu3}>
                            <NavLink to="/profiles" onClick={() => btn_icon(!showmenu)}>Profiles</NavLink>
                            <NavLink to="/explore" onClick={() => btn_icon(!showmenu)}>Lucent NFTs</NavLink>

                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='navbar-item'>
                    <div ref={ref2}>
                      <div className="dropdown-custom dropdown-toggle btn"
                        onClick={handleBtnClick2}
                      >
                        Ranking
                      </div>
                      {openMenu2 && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu2}>
                            <NavLink to="/ranking/nfts" onClick={() => btn_icon(!showmenu)}>Top NFTs</NavLink>
                            <NavLink to="/ranking/profiles" onClick={() => btn_icon(!showmenu)}>Top Profiles</NavLink>
                            <NavLink to="/ranking/collectors" onClick={() => btn_icon(!showmenu)}>Top Collectors</NavLink>

                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {userInfo && userInfo.isAuth && <><div className='navbar-item' onClick={() => btn_icon(!showmenu)}>
                    <NavLink to="/create" >
                      <span className='fa fa-star-o'></span>&nbsp;
                      <span className=''>NFTs</span>
                      <span className='lines'></span>
                    </NavLink>
                  </div>
                    <div className='navbar-item' onClick={() => { btn_icon(!showmenu); goCheckNotyX() }}>
                      <NavLink to="/notifications" >
                        {NotyCount === 0 && <><span className='fa fa-bell-o bolder'></span><span className='bolder'> 0 </span></>}
                        {NotyCount > 0 && <><span className='fa fa-bell bolder bell'></span><span className='bolder'> {NotyCount} </span></>}
                      </NavLink>
                    </div>
                    <div className='navbar-item' onClick={() => { btn_icon(!showmenu) }}>
                      <NavLink to="/royalty" >
                        <span className='fa fa-credit-card color bolder'></span>&nbsp;
                        <span className='color'>{NewRoyaltyFormatted !== "0.0" && "+"}{NewRoyaltyFormatted} GLMR </span>&nbsp;
                        <span className='lines'></span>
                      </NavLink>
                    </div></>}

                  {userInfo && userInfo.isAuth && <div className='navbar-item'>
                    <div ref={ref1}>
                      <div className="dropdown-custom dropdown-toggle btn"
                        onClick={handleBtnClick1}
                      >
                        Profile
                      </div>
                      {openMenu1 && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu1}>
                            <NavLink to={"/profile/" + localStorage.getItem('wp')} onClick={() => btn_icon(!showmenu)}>My Profile</NavLink>
                            <NavLink to="/autograph/requests" onClick={() => btn_icon(!showmenu)}>Autograph Requests</NavLink>
                            <NavLink to="/autograph/statuses" onClick={() => btn_icon(!showmenu)}>Autograph Statuses</NavLink>
                            <NavLink to="/transactions" onClick={() => btn_icon(!showmenu)}>Transactions</NavLink>
                            <NavLink to="/nftswhitelist" onClick={() => btn_icon(!showmenu)}>NFTs Whitelist</NavLink>

                            <NavLink to="/settings/profile/avatar" onClick={() => btn_icon(!showmenu)}>Profile Avatar</NavLink>
                            <NavLink to="/settings/profile/autograph" onClick={() => btn_icon(!showmenu)}>Profile Autograph</NavLink>
                            <NavLink to="/settings/profile/name" onClick={() => btn_icon(!showmenu)}>Profile Name</NavLink>
                            <NavLink to="/settings/profile/description" onClick={() => btn_icon(!showmenu)}>Profile Description</NavLink>
                            <NavLink to="/settings/profile/social" onClick={() => btn_icon(!showmenu)}>Social Links</NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>}

                  <div className='navbar-item'>
                    <div className=' col-white marginright15 bolder'>
                      {/* {init && <div className='cvb'>&nbsp;</div>} */}
                      {/* {!init && !isWalletConnected && <button className="btn-main" onClick={configureMoonbeam}>{cTxt}</button>}
                      {!init && isWalletConnected && xselectedAddress && <span>{xselectedAddress.toString().substring(0, 6) + "..." + xselectedAddress.toString().substring(xselectedAddress.toString().length - 4)}</span>} */}
                      {!init && (!userInfo || (userInfo && !userInfo.isAuth)) && <button className="btn-main " onClick={configureMoonbeamz}>CONNECT WALLET</button>}
                      {!init && userInfo && userInfo.wp && <span>{xprovider.selectedAddress.toString().substring(0, 6) + "..." + xprovider.selectedAddress.toString().substring(xprovider.selectedAddress.toString().length - 4)}</span>}

                    </div>
                  </div>


                </div>
              }
            </Breakpoint>

            <Breakpoint xl >
              <div className='menu'>

                <div className='navbar-item'>
                  <div ref={ref3}>
                    <div className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick3} onMouseLeave={closeMenu3}>
                      Explore
                      <span className='lines'></span>
                      {openMenu3 && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu3}>
                            <NavLink to="/profiles">Profiles</NavLink>
                            <NavLink to="/explore">Lucent NFTs</NavLink>

                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='navbar-item'>
                  <div ref={ref2}>
                    <div className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick2} onMouseLeave={closeMenu2}>
                      Ranking
                      <span className='lines'></span>
                      {openMenu2 && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu2}>
                            <NavLink to="/ranking/nfts">Top NFTs</NavLink>
                            <NavLink to="/ranking/profiles">Top Profiles</NavLink>
                            <NavLink to="/ranking/collectors">Top Collectors</NavLink>

                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {userInfo && userInfo.isAuth && <div className='navbar-item'>
                  <div ref={ref1}>
                    <div className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick1} onMouseLeave={closeMenu1}>
                      Profile
                      <span className='lines'></span>
                      {openMenu1 && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu1}>
                            <NavLink to={"/profile/" + localStorage.getItem('wp')}>My Profile</NavLink>
                            <NavLink to="/autograph/requests" >Autograph Requests</NavLink>
                            <NavLink to="/autograph/statuses" >Autograph Statuses</NavLink>
                            <NavLink to="/transactions" >Transactions</NavLink>
                            <NavLink to="/nftswhitelist" >NFTs Whitelist</NavLink>

                            <NavLink to="/settings/profile/avatar">Profile Avatar</NavLink>
                            <NavLink to="/settings/profile/cover">Profile Cover</NavLink>
                            <NavLink to="/settings/profile/autograph">Profile Autograph</NavLink>
                            <NavLink to="/settings/profile/name">Profile Name</NavLink>
                            <NavLink to="/settings/profile/description">Profile Description</NavLink>
                            <NavLink to="/settings/profile/social">Social Links</NavLink>
                          </div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>}
                {userInfo && userInfo.isAuth && <div className='navbar-item'>
                  <NavLink to="/create">
                    <span className='fa fa-star-o'></span>&nbsp;
                    <span className=''>NFTs</span>
                    <span className='lines'></span>
                  </NavLink>
                </div>}
                {userInfo && userInfo.isAuth && <div className='navbar-item' onClick={goCheckNotyX}>
                  <NavLink to="/notifications">
                    {NotyCount === 0 && <><span className='fa fa-bell-o bolder'></span><span className='bolder'> 0 </span></>}
                    {NotyCount > 0 && <><span className='fa fa-bell bolder bell'></span><span className='bolder'> {NotyCount} </span></>}
                  </NavLink>
                </div>}

                {userInfo && userInfo.isAuth && <div className='navbar-item marginlefter15'>
                  <NavLink to="/royalty">
                    <span className='fa fa-credit-card color bolder'></span>&nbsp;
                    <span className='color'>{NewRoyaltyFormatted !== "0.0" && "+"}{NewRoyaltyFormatted} GLMR </span>&nbsp;
                    <span className='lines'></span>
                  </NavLink>
                </div>}

                <div className='navbar-item'>
                  <div className=' col-white marginright15 bolder'>
                    {init && <div className='cvb'>&nbsp;</div>}
                    {!init && (!userInfo || (userInfo && !userInfo.isAuth)) && <button className="btn-main" onClick={configureMoonbeamz}>CONNECT WALLET</button>}
                    {!init && userInfo && userInfo.wp && <span>{xprovider.selectedAddress.toString().substring(0, 6) + "..." + xprovider.selectedAddress.toString().substring(xprovider.selectedAddress.toString().length - 4)}</span>}
                  </div>
                </div>

              </div>
            </Breakpoint>
          </BreakpointProvider>



        </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>

      </div>

    </header>
  );
}
export default Header;